export default class RadioButtonData {
    id: string
    label: string
    checked: boolean
    name: string
    value: string

    constructor(id: string, label: string, checked: boolean, name: string, value: string) {
        this.id = id
        this.label = label
        this.checked = checked
        this.name = name
        this.value = value
    }
}
