import React, { Fragment, ReactElement } from "react"

import OrganismeFinanceur from "../../../model/OrganismeFinanceur"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeOrganismeFinanceurListComponent extends BackOfficeAbstractReferentielListComponent<OrganismeFinanceur> {
    getLine(item: OrganismeFinanceur, index: number): React.ReactElement {
        return (
            <tr key={"row-organisme-financeur-" + item.id}>
                <td></td>
                <td>{this.convertTypeName(item.type.nom!)}</td>
                <td>{this.convertName(item.nom!, item.type.nom!)}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.getDeleteButton(item, index)}
                </td>
            </tr>
        )
    }

    protected getUsePager(): boolean {
        return true
    }

    protected getItemByPage(): number {
        return 15
    }

    convertTypeName(name: string): ReactElement {
        if (name == "ARS") {
            return (
                <span>
                    Agence Régionale
                    <br />
                    de Santé
                </span>
            )
        } else if (name == "CD") {
            return <span>Conseil Départemental</span>
        } else {
            return <span>{name}</span>
        }
    }

    convertName(name: string, typeName: string): ReactElement {
        if (typeName === "CD") {
            return <span>{name.replace("CD ", "")}</span>
        } else {
            return <span>{name.replace(typeName, "")}</span>
        }
    }

    getDeleteButton(item: OrganismeFinanceur, index: number) {
        if (!item.system) {
            return (
                <Fragment>
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"organisme-financeur-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"organisme-financeur-" + item.id}
                        title={"Suppression de l'organisme financeur : " + item.nom}
                        message={"Vous confirmez la suppression de l'organisme financeur ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </Fragment>
            )
        }
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "4%" }}></th>
                    <th scope="col" style={{ width: "40%" }}>
                        Type
                    </th>
                    <th scope="col" style={{ width: "40%" }}>
                        Nom
                    </th>
                    <th scope="col" style={{ width: "16%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useOrganismeFinanceurService()
    }

    getAddLabel(): string {
        return "Ajouter un organisme"
    }
}
