import { ReactElement } from "react"

import TypologieAccord from "../../../model/TypologieAccord"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeTypologieAccordFormComponent extends BackOfficeAbstractReferentielFormComponent<TypologieAccord, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useTypologieAccordService()
    }

    formToObject(id: number): TypologieAccord {
        const typologieDemande = new TypologieAccord(this.props.objectId)
        typologieDemande.code = (document.querySelector("#typologie-demande-code") as HTMLInputElement).value
        typologieDemande.libelle = (document.querySelector("#typologie-demande-libelle") as HTMLInputElement).value
        typologieDemande.active = (document.querySelector("input[name='typologie-demande-actif']") as HTMLInputElement).checked
        typologieDemande.procedureSimplifiee = (document.querySelector("input[name='typologie-demande-procedure-simplifiee']") as HTMLInputElement).checked
        return typologieDemande
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("typologie-demande-actif", "Actif", condition!)
    }

    getProcedureSimplifieeCheckbox(): ReactElement {
        const condition = this.state && this.state.item && (this.state.item.procedureSimplifiee as boolean)
        return this.getCheckbox("typologie-demande-procedure-simplifiee", "Procédure simplifiée", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Code"
                                id="typologie-demande-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="typologie-demande-libelle"
                                value={this.getProperty("libelle")}
                                error={this.state?.errors?.has("libelle")}
                                errorMessage={this.state?.errors?.get("libelle")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getProcedureSimplifieeCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
