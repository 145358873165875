import { DnumProps } from "../../../generic/component/DnumComponent"
import TextAreaComponent from "../../../generic/component/text/TextAreaComponent"

import RoleBackOffice from "../../constant/role/RoleBackOffice"
import BackOfficeService from "../../service/BackOfficeService"
import BackOfficeComponent from "../BackOfficeComponent"

interface BackOfficeModalEditCommentaireAcceptationComponentProps extends DnumProps {
    demandeId: number
    commentaireAcceptation: string
    saveCommentaireAcceptation: (commentaire: string) => void
}

export default class BackOfficeModalEditCommentaireAcceptationComponent extends BackOfficeComponent<BackOfficeModalEditCommentaireAcceptationComponentProps, any> {
    wrapSaveCommentaireAcceptation() {
        const input = document.querySelector("#demande-commentaire-acceptation-input-" + this.props.demandeId) as HTMLInputElement
        this.props.saveCommentaireAcceptation(input.value)
    }

    getSaveButton() {
        const account = BackOfficeService.getInstance().getBackAccount
        if (account?.type != RoleBackOffice.MEMBRE_CNA) {
            return (
                <button
                    onClick={() => this.wrapSaveCommentaireAcceptation()}
                    className="fr-btn fr-btn--close fr-fi-checkbox-line fr-btn--primary"
                    aria-controls={"demande-commentaire-acceptation-" + this.props.demandeId}>
                    Enregistrer
                </button>
            )
        }
    }

    render() {
        return (
            <dialog aria-labelledby={this.props.id + "-title"} id={"demande-commentaire-acceptation-" + this.props.demandeId} className="fr-modal" role="dialog">
                <div className="fr-container fr-container--fluid fr-container-md">
                    <div className="fr-grid-row fr-grid-row--center">
                        <div className="fr-col-12 fr-col-md-12 fr-col-lg-12">
                            <div className="fr-modal__body">
                                <div className="fr-modal__header">&nbsp;</div>
                                <div className="fr-modal__content">
                                    <TextAreaComponent
                                        label={"Commentaire communiquée sur l'acceptation"}
                                        text={this.props.commentaireAcceptation}
                                        maxLength={600}
                                        id={"demande-commentaire-acceptation-input-" + this.props.demandeId}
                                        rows={7}
                                    />
                                </div>
                                <div className="fr-modal__footer">
                                    <ul className="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
                                        <li>
                                            {this.getSaveButton()}
                                            <button className="fr-btn fr-btn--close fr-fi-checkbox-line fr-btn--secondary" aria-controls={"demande-commentaire-acceptation-" + this.props.demandeId}>
                                                Fermer
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        )
    }
}
