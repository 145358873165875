import { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface TableHeaderProps extends DnumProps {
    colSpan?: any
    rowSpan?: any
    children: ReactElement | string
}
export default class TableHeaderComponent extends DnumComponent<TableHeaderProps, any> {
    constructor(props: TableHeaderProps) {
        super(props)
    }

    render() {
        if (this.props.colSpan && this.props.rowSpan) {
            return (
                <th rowSpan={this.props.rowSpan} colSpan={this.props.colSpan} style={this.props.style}>
                    {this.props.children}
                </th>
            )
        } else if (this.props.colSpan) {
            return (
                <th colSpan={this.props.colSpan} style={this.props.style}>
                    {this.props.children}
                </th>
            )
        } else if (this.props.rowSpan) {
            return (
                <th rowSpan={this.props.rowSpan} style={this.props.style}>
                    {this.props.children}
                </th>
            )
        } else {
            return <th style={this.props.style}>{this.props.children}</th>
        }
    }
}
