import React from "react"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import CustomHighlightComponent from "../../shared/component/highlight/CustomHighlightComponent"
import FrontPage from "../FrontPage"

export default class FrontContactPage extends FrontPage<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        const text = (
            <>
                Pour toute question relative au site <b>accords-agrements.social.gouv.fr</b> relatif à la procédure d'agrément dématérialisée des accords et conventions applicables aux salariés des
                établissements sociaux et médico-sociaux privés à but non lucratif conformément à l'article L 314-6 du code de l'action sociale et des familles.
                <br />
                <br />
                Vous pouvez contacter le bureau de l'emploi et de la politique salariale de la <b>Direction générale de la cohésion sociale</b> chargé de l'instruction de votre demande d'agrément en
                lui adressant un message électronique à l'adresse suivante : DGCS-ACCORDS@social.gouv.fr.
                <br />
                Assurez-vous auparavant que votre question n'ait pas déjà été traitée dans la rubrique Foire aux questions.
            </>
        )

        return (
            <PrimaryContentBoxComponent title={"Contacts"}>
                <CustomHighlightComponent>{text}</CustomHighlightComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
