import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface BadgeProps extends DnumProps {
    label: string | null
    small?: any
    noIcon?: true | null
    oneLine?: any
}

export default abstract class AbstractBadgeComponent extends DnumComponent<BadgeProps, any> {
    private readonly className: string = "fr-badge"

    constructor(props: BadgeProps) {
        super(props)

        this.className = "fr-badge"

        if (this.getType() != null) {
            this.className += " fr-badge--" + this.getType()
        }

        if (this.props.small) {
            this.className += " fr-badge--sm"
        }

        if (this.props.noIcon) {
            this.className += " fr-badge--no-icon"
        }
    }

    abstract getType(): string

    render() {
        if (this.props.label == null) {
            return <>Test</>
        } else if (this.props.oneLine) {
            return (
                <p className={this.className}>
                    <span className="fr-ellipsis">{this.props.label}</span>
                </p>
            )
        } else {
            return <p className={this.className}>{this.props.label}</p>
        }
    }
}
