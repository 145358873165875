import PersistentObject from "../../model/PersistentObject"

import Pager from "../../shared/dto/Pager"
import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpReferentielService extends HttpService {
    TYPE_ACCORD_URI = "/back/admin/referentiel/local/type_accord"
    MOTIF_REFUS_URI = "/back/admin/referentiel/local/motif_refus"
    PIECE_EXIGIBLE_URI = "/back/admin/referentiel/local/piece_exigible"
    TYPOLOGIE_ACCORD_URI = "/back/admin/referentiel/local/typologie_accord"
    MEMBRE_CNA_URI = "/back/admin/referentiel/local/membre_cna"
    HTML_TEMPLATE_URI = "/back/admin/referentiel/local/html_template"
    TYPE_FINANCEUR_URI = "/back/admin/referentiel/local/type_financeur"
    ORGANISME_FINANCEUR_URI = "/back/admin/referentiel/local/organisme_financeur"
    CONTACT_FINANCEUR_URI = "/back/admin/referentiel/local/contact_financeur"
    ESMS_URI = "/back/admin/referentiel/local/esms"
    IDCC_URI = "/back/admin/referentiel/local/idcc"
    SIGNATURE_URI = "/back/admin/referentiel/local/signature"
    REGIONS_URI = "/back/admin/referentiel/local/region"

    protected uri: string | null = null

    useTypeAccordService(): HttpReferentielService {
        this.uri = this.TYPE_ACCORD_URI
        return this
    }

    useTypeFinanceurService(): HttpReferentielService {
        this.uri = this.TYPE_FINANCEUR_URI
        return this
    }

    useOrganismeFinanceurService(): HttpReferentielService {
        this.uri = this.ORGANISME_FINANCEUR_URI
        return this
    }

    useContactFinanceurService(): HttpReferentielService {
        this.uri = this.CONTACT_FINANCEUR_URI
        return this
    }

    useMotifRefusService(): HttpReferentielService {
        this.uri = this.MOTIF_REFUS_URI
        return this
    }

    usePieceExigibleService(): HttpReferentielService {
        this.uri = this.PIECE_EXIGIBLE_URI
        return this
    }

    useTypologieAccordService(): HttpReferentielService {
        this.uri = this.TYPOLOGIE_ACCORD_URI
        return this
    }

    useMembreCnaService(): HttpReferentielService {
        this.uri = this.MEMBRE_CNA_URI
        return this
    }

    useHtmlTemplateService(): HttpReferentielService {
        this.uri = this.HTML_TEMPLATE_URI
        return this
    }

    useEsmsTemplateService(): HttpReferentielService {
        this.uri = this.ESMS_URI
        return this
    }

    useIdentifiantConventionCollectiveService(): HttpReferentielService {
        this.uri = this.IDCC_URI
        return this
    }

    useSignatureService(): HttpReferentielService {
        this.uri = this.SIGNATURE_URI
        return this
    }

    useRegionService(): HttpReferentielService {
        this.uri = this.REGIONS_URI
        return this
    }

    getAll<TYPE>(pager?: Pager | null, filter?: Map<string, string> | null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (pager && filter && filter.size > 0) {
            //console.log("cl A")
            const params = pager.toQueryParams()
            filter?.forEach((value, key) => params.set(key, value))
            return this.request<Response<TYPE[]>>(this.uri! + "?" + new URLSearchParams(params))
        } else if (pager) {
            //console.log("cl B")
            return this.request<Response<TYPE[]>>(this.uri! + "?" + new URLSearchParams(pager.toQueryParams()))
        } else {
            //console.log("cl C")
            return this.request<Response<TYPE[]>>(this.uri!, { method: "GET" })
        }
    }

    save<TYPE extends PersistentObject>(object: TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<any>>(this.uri!, {
            method: object.id == null ? "POST" : "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object)
        })
    }

    delete(id: number) {
        return this.request<Response<any>>(this.uri + "/" + id, { method: "DELETE" })
    }

    getById<TYPE>(id: number) {
        return this.request<Response<TYPE>>(this.uri + "/" + id, { method: "GET" })
    }
}
