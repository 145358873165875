import React, { ChangeEvent, Fragment, ReactElement } from "react"

import IdentifiantConventionCollective from "../../../model/IdentifiantConventionCollective"
import TypeFinanceur from "../../../model/TypeFinanceur"
import TypologieAccord from "../../../model/TypologieAccord"

import AlertWarningComponent from "../../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../../generic/component/grid/container/ContainerComponent"
import { PageProps } from "../../../../generic/component/grid/page/PageComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import PagerComponent from "../../../../generic/component/pager/PagerComponent"
import SelectComponent from "../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../generic/component/select/SelectOptionData"
import TableCellComponent from "../../../../generic/component/table/TableCellComponent"
import TableContainerComponent from "../../../../generic/component/table/TableContainerComponent"
import TableHeaderComponent from "../../../../generic/component/table/TableHeaderComponent"

import { Constant } from "../../../constant/Constant"
import HttpBackDemande from "../../../service/http/HttpBackDemande"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import Response from "../../../service/http/response/Response"
import UiService from "../../../service/ui/UiService"
import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../../shared/component/space/VerticalSpacing"
import PagerResponse from "../../../shared/dto/PagerResponse"
import BackOfficePage from "../../BackOfficePage"
import BackOfficeDemandeAgrementForm from "../../common/BackOfficeDemandeAgrementForm"
import LazyWrapperComponent from "../../../shared/component/LazyWrapperComponent"
import BackOfficeModalShowDemandeComponent from "../../common/BackOfficeModalShowDemandeComponent"

interface InstructionListDemandeAgrementPageState {
    demandes: BackOfficeDemandeAgrementForm[] | undefined
    count: number
    currentPage: number
    typologies: TypologieAccord[] | undefined
    typologieId: number | undefined | null
    conventionCollectives: IdentifiantConventionCollective[] | undefined
    conventionCollectiveCode: string | undefined | null
    etat: string | undefined | null
    type: string | undefined | null
    entiteJuridiqueFilter: string | undefined | null
    codePostal: string | undefined
    typesFinanceur: TypeFinanceur[] | undefined
    typeFinanceurId: string | undefined | null
}

interface InstructionListDemandeAgrementPageProps extends PageProps {
    etat?: string
}

export default class BackOfficeInstructionListDemandeAgrementPage extends BackOfficePage<InstructionListDemandeAgrementPageProps, InstructionListDemandeAgrementPageState> {
    static ITEM_BY_PAGE = 50

    static INIT = false

    constructor(props: InstructionListDemandeAgrementPageProps) {
        super(props)
        this.state = {
            demandes: undefined,
            count: 0,
            currentPage: 0,
            typologies: undefined,
            typologieId: undefined,
            type: undefined,
            conventionCollectives: undefined,
            conventionCollectiveCode: undefined,
            etat: this.props.etat ?? undefined,
            entiteJuridiqueFilter: undefined,
            codePostal: undefined,
            typesFinanceur: undefined,
            typeFinanceurId: undefined
        }
    }

    componentDidMount() {
        this.choosePage(0)
        new HttpReferentielService()
            .useTypologieAccordService()
            .getAll<TypologieAccord>()
            .then((response) => {
                this.setState({ typologies: response.content })
            })
        new HttpReferentielService()
            .useIdentifiantConventionCollectiveService()
            .getAll<IdentifiantConventionCollective>()
            .then((response) => {
                this.setState({ conventionCollectives: response.content })
            })
        new HttpReferentielService()
            .useTypeFinanceurService()
            .getAll<TypeFinanceur>()
            .then((response) => {
                this.setState({ typesFinanceur: response.content })
            })
    }

    componentDidUpdate(prevProps: Readonly<InstructionListDemandeAgrementPageProps>, prevState: Readonly<InstructionListDemandeAgrementPageState>, snapshot?: any) {
        if (this.state.count < 0) {
            this.choosePage(this.state.currentPage)
        }
    }

    forceRefresh() {
        this.setState({ count: -1 })
    }

    getHeader(): ReactElement {
        return (
            <tr key="header-line-0">
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
                <TableHeaderComponent>Type</TableHeaderComponent>
                <TableHeaderComponent>Entité juridique</TableHeaderComponent>
                <TableHeaderComponent>Code postal</TableHeaderComponent>
                <TableHeaderComponent>IDCC</TableHeaderComponent>
                <TableHeaderComponent>Thématique</TableHeaderComponent>
                <TableHeaderComponent>
                    <Fragment>Date SVA</Fragment>
                </TableHeaderComponent>
                <TableHeaderComponent>
                    <Fragment>Dernière transmission</Fragment>
                </TableHeaderComponent>
                <TableHeaderComponent>Etat</TableHeaderComponent>
                <TableHeaderComponent>Coût</TableHeaderComponent>
            </tr>
        )
    }

    getFiltre(): ReactElement {
        return (
            <Fragment>
                <ContainerComponent fluid>
                    <RowComponent key={"line-filter-0"} style={{ width: "100%" }}>
                        <ColumnComponent size={2}>{this.getSelectType()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={3}>{this.getSelectTypeFinanceur()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={5}>{this.getSelectConventionCollective()}</ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                    <RowComponent key={"line-filter-1"} style={{ width: "100%" }}>
                        <ColumnComponent size={6}>{this.getSelectTypologie()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={5}>{this.getSelectEtat()}</ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                    <RowComponent key={"line-filter-2"} style={{ width: "100%" }}>
                        <ColumnComponent size={2}>
                            <TextInputComponent label="Code postal EJ" placeholder="2 car. mini." id="code-postal-ej" onKeyUpHandler={() => this.getCodePostalEventHandler()} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={3}>
                            <TextInputComponent
                                placeholder="3 car. mini."
                                style={{ width: "100%" }}
                                id={"entite-juridique-filter"}
                                onKeyUpHandler={() => this.getEntiteJuridiqueEventHandler()}
                                label="Raison sociale EJ"
                            />
                        </ColumnComponent>
                    </RowComponent>
                </ContainerComponent>
            </Fragment>
        )
    }

    getCodePostalEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 1) {
            this.choosePage(0)
        }
    }

    getEntiteJuridiqueEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 2) {
            this.choosePage(0)
        }
    }

    getSelectType() {
        const types: SelectOptionData[] = []
        types.push(new SelectOptionData("", "Type", false))
        types.push(new SelectOptionData("ACCORD", "Accord", false))
        types.push(new SelectOptionData("DUE", "DUE", false))
        return <SelectComponent label={"Type"} data={types} style={{ width: "100%" }} id={"select-type-filter"} onChange={(event) => this.onChangeType(event)} />
    }

    getSelectTypeFinanceur() {
        const types: SelectOptionData[] = []
        if (this.state && this.state.typesFinanceur) {
            types.push(new SelectOptionData("-1", "Type financeur", false))
            this.state.typesFinanceur.forEach((type) => {
                types.push(new SelectOptionData(type.id!.toString(), type.nom!, false))
            })
        }
        return <SelectComponent label={"Financeur"} data={types} style={{ width: "100%" }} id={"select-type-financeur-filter"} onChange={(event) => this.onChangeTypeFinanceur(event)} />
    }

    onChangeType(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeTypeFinanceur(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeTypologie(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getSelectTypologie() {
        if (this.state && this.state.typologies) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "Thématique", false))
            this.state.typologies.forEach((typologie) => {
                const option = new SelectOptionData(typologie.id!.toString(), typologie.libelle!, false)
                list.push(option)
            })
            return <SelectComponent id={"select-typologie-filter"} onChange={(event) => this.onChangeTypologie(event)} style={{ width: "100%" }} label={"Thématique"} data={list} />
        }
        return <span></span>
    }

    getSelectConventionCollective() {
        if (this.state && this.state.conventionCollectives) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "IDCC", false))
            this.state.conventionCollectives.forEach((idcc) => {
                if (idcc.active) {
                    const option = new SelectOptionData(idcc.code!.toString(), idcc.label!, false)
                    list.push(option)
                }
            })
            return <SelectComponent label={"IDCC"} data={list} onChange={(event) => this.onChangeConventionCollective(event)} style={{ width: "100%" }} id={"select-idcc-filter"} />
        }
        return <span></span>
    }

    onChangeConventionCollective(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeEtat(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getSelectEtat() {
        const option1 = new SelectOptionData("", "Etat", false)
        const option2 = new SelectOptionData(Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER, "A traiter", Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER == this.props.etat)
        const option3 = new SelectOptionData(
            Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR,
            "En attente avis financeur(s)",
            Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR === this.props.etat
        )
        const option4 = new SelectOptionData(Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA, "En attente CNA", Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA === this.props.etat)
        const option5 = new SelectOptionData(Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA, "Prochaine CNA", Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA == this.props.etat)
        const option6 = new SelectOptionData(
            Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR,
            "En attente retour employeur",
            Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR == this.props.etat
        )
        const option7 = new SelectOptionData(Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE, "Demande agréée", Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE == this.props.etat)
        const option8 = new SelectOptionData(Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE, "Demande refusée", Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE == this.props.etat)
        return (
            <SelectComponent
                id={"select-etat-filter"}
                style={{ width: "100%" }}
                onChange={(event) => this.onChangeEtat(event)}
                label={"Etat"}
                value={this.props.etat}
                data={[option1, option2, option3, option4, option5, option6, option7, option8]}
            />
        )
    }

    getEtablissements(demande: BackOfficeDemandeAgrementForm) {
        if ((demande.etablissements?.length ?? 0) > 1) {
            return <small>{demande.etablissements?.length} établissements concernés</small>
        } else {
            return <small>{demande.etablissements?.length} établissement concerné</small>
        }
    }

    getEtatLabel(demande: BackOfficeDemandeAgrementForm) {
        switch (demande.etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return "Transmise"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
                return "A traiter"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
                return "Attente employeur"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
                return "Prochaine CNA"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
                return "Attente CNA"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
                return "Attente financeur " + (demande.demandeAvisDone ?? 0) + "/" + demande.demandeAvisTotal
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return "Agréée"
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return "Refusée"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
                return "Supprimée"
            default:
                return "-"
        }
    }

    getDateTransmission(demande: BackOfficeDemandeAgrementForm) {
        if (demande.dateUpdateFromFront != null) {
            return UiService.formatServerDateToHumanNumericDate(demande.dateUpdateFromFront)
        }
    }

    getDateSva(demande: BackOfficeDemandeAgrementForm) {
        if (demande.dateSva != null) {
            return UiService.formatServerDateToHumanNumericDate(demande.dateSva)
        }
    }

    getButton(demande: BackOfficeDemandeAgrementForm, index: number) {
        return (
            <Button key={"clickItem" + index} modal={"instruction-demande-" + demande.id}>
                {this.state.currentPage * BackOfficeInstructionListDemandeAgrementPage.ITEM_BY_PAGE + (index + 1)}
            </Button>
        )
    }

    checkImpact(demande: BackOfficeDemandeAgrementForm) {
        if (demande.sansImpactFinancier || demande.financementTotal == 0 || demande.financementTotal == null) {
            return true
        }
        return false
    }

    getContent() {
        const tbody: ReactElement[] = []
        if (this.state && this.state.demandes && this.state.demandes.length > 0) {
            let h = 0
            this.state.demandes.forEach((demande, index) => {
                h = 0
                const color = this.checkImpact(demande) ? "blue" : ""

                tbody.push(
                    <tr key={"demande-" + demande.id + "-" + index}>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            <LazyWrapperComponent source={this.getButton(demande, index)}>
                                <BackOfficeModalShowDemandeComponent
                                    demandeId={demande.id!}
                                    typologies={this.state.typologies}
                                    showActionOrStatusRow={true}
                                    prefixId={"instruction"}
                                    parentMethod={() => this.forceRefresh()}
                                />
                            </LazyWrapperComponent>
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ textAlign: "center", color: color }}>
                            {demande.typeDemande == "ACCORD" ? "Accord" : demande.typeDemande}
                            <br />
                            {demande.id}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {demande.entiteJuridique}
                            <br />
                            {this.getEtablissements(demande)}
                        </TableCellComponent>
                        <TableCellComponent style={{ color: color }}>{demande.entiteJuridiqueCodePostal}</TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {demande.idccCode}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {demande.typologieAccord?.libelle}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {this.getDateSva(demande)}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {this.getDateTransmission(demande)}
                        </TableCellComponent>
                        <TableCellComponent style={{ color: color }}>{this.getEtatLabel(demande)}</TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>{demande.financementTotal ? demande.financementTotal + " €" : "-"}</TableCellComponent>
                    </tr>
                )
            })
        }

        return tbody
    }

    choosePage(pageNumber: number) {
        let typologieId: number | null = null
        let conventionCollectiveCode: string | null = null

        // typologie / thematique
        if (this.state && this.state.typologies) {
            const selectTypologieComponent = document.querySelector("#select-typologie-filter") as HTMLInputElement
            const typologieDefined = selectTypologieComponent.value !== "" && !isNaN(Number(selectTypologieComponent.value))
            typologieId = typologieDefined ? Number.parseInt(selectTypologieComponent.value) : null
        }

        // id convention collective
        if (this.state && this.state.conventionCollectives) {
            const selectConventionCollectiveComponent = document.querySelector("#select-idcc-filter") as HTMLInputElement
            const conventionCollectiveDefined = selectConventionCollectiveComponent.value !== "" && !isNaN(Number(selectConventionCollectiveComponent.value))
            conventionCollectiveCode = conventionCollectiveDefined ? selectConventionCollectiveComponent.value : null
        }

        // etat
        const selectEtatComponent = document.querySelector("#select-etat-filter") as HTMLInputElement
        //console.log(selectEtatComponent.value)
        const etatValue = selectEtatComponent.value !== "" ? selectEtatComponent.value : null
        let etatArray: string[] | null = null
        //console.log("Etat value : "+etatValue)
        if (etatValue != null) {
            etatArray = [etatValue]
        }

        // type
        const selectTypeComponent = document.querySelector("#select-type-filter") as HTMLInputElement
        const typeValue = selectTypeComponent.value !== "" ? selectTypeComponent.value : null

        // entite juridique
        const inputEntiteJuridique = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        const entiteJuridiqueFilter = inputEntiteJuridique.value

        const codePostalEntiteJuridique = document.querySelector("#code-postal-ej") as HTMLInputElement
        const codePostal = codePostalEntiteJuridique.value

        const selectTypeFinanceur = document.querySelector("#select-type-financeur-filter") as HTMLInputElement
        const typeFinanceurValue = selectTypeFinanceur.value !== "" ? selectTypeFinanceur.value : null

        //console.log("Choose page : "+pageNumber)
        new HttpBackDemande()
            .getDemandes(
                null,
                entiteJuridiqueFilter,
                etatArray,
                typeValue,
                conventionCollectiveCode,
                typologieId,
                codePostal,
                typeFinanceurValue,
                pageNumber,
                BackOfficeInstructionListDemandeAgrementPage.ITEM_BY_PAGE
            )
            .then((response) => {
                const cast = new Response<PagerResponse<BackOfficeDemandeAgrementForm>>(response)
                if (cast.success) {
                    this.setState({
                        demandes: cast.content!.list!,
                        count: cast.content!.count!,
                        currentPage: pageNumber,
                        conventionCollectiveCode: conventionCollectiveCode,
                        etat: BackOfficeInstructionListDemandeAgrementPage.INIT ? this.props.etat ?? etatValue : etatValue,
                        type: typeValue,
                        typologieId: typologieId,
                        entiteJuridiqueFilter: entiteJuridiqueFilter,
                        codePostal: codePostal,
                        typeFinanceurId: typeFinanceurValue
                    })
                }
            })
    }

    getTableOrMessage() {
        const content = this.getContent()
        //console.log("CL :: "+content.length)
        if (content.length > 0) {
            return <TableContainerComponent headers={[this.getHeader()]} cells={this.getContent()} />
        } else {
            return <AlertWarningComponent small description={"Aucune demande correspondante"} style={{ margin: "40px" }} />
        }
    }
    openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    render() {
        //console.log("render")
        return (
            <>
                <ContainerComponent id={Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES}>
                    <PrimaryContentBoxComponent
                        title={"Demandes d'agrément"}
                        rightComponent={
                            <RowComponent>
                                <ColumnComponent size={7} />
                                <ColumnComponent size={5}>
                                    <Button
                                        style={{ marginTop: "4px" }}
                                        onClick={() => this.openInNewTab(process.env.REACT_APP_CONTEXT_PATH + "/back/csv/demandes")}
                                        iconLeft={Constant.ICON.DOWNLOAD_FILL}>
                                        {"Télécharger"}
                                    </Button>
                                </ColumnComponent>
                            </RowComponent>
                        }>
                        <div style={{ padding: "20px" }}>{this.getFiltre()}</div>
                        <br />
                        {this.getTableOrMessage()}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <PagerComponent
                                itemByPage={BackOfficeInstructionListDemandeAgrementPage.ITEM_BY_PAGE}
                                itemCount={this.state.count}
                                currentPage={this.state.currentPage}
                                choosePage={(page) => this.choosePage(page)}
                            />
                        </div>
                    </PrimaryContentBoxComponent>
                </ContainerComponent>
            </>
        )
    }
}
