import { Component } from "react"
import ReactDOM from "react-dom"

export default class AccoladeComponent extends Component {
    constructor(props) {
        super(props)

        // variables partagées par tous les composants héritants de cette classe
        this.blueGreyColor = "#5D7394"
        //this.blueGreyColor = "rgb(106, 106, 244)"
        this.lightBlue = "#D9EAF4"
        this.veryLightBlue = "#F0F4F7"
        this.mainContentId = "mainContentId"
    }

    switchPage(page) {
        ReactDOM.render(page, document.getElementById(this.mainContentId))
    }
}
