import React from "react"

import BackAccount from "../../model/BackAccount"
import Dashboard from "../../model/Dashboard"

import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import RoleBackOffice from "../../constant/role/RoleBackOffice"
import BackOfficeService from "../../service/BackOfficeService"
import HttpBackAccountService from "../../service/http/HttpBackAccountService"
import HttpBackDemande from "../../service/http/HttpBackDemande"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import BackOfficePage from "../BackOfficePage"
import BackOfficeGetDemandeCountByStatuts from "./BackOfficeGetDemandeCountByStatuts"

interface BackOfficeAdminHomePageState {
    dashboard: Dashboard | null
}
export default class BackOfficeAdminHomePage extends BackOfficePage<any, BackOfficeAdminHomePageState> {
    goToPage(domKey: string) {
        BackOfficeService.getInstance().switchPage(domKey)
    }

    componentDidMount() {
        new HttpBackAccountService().isConnected().then((response) => {
            const cast = new Response(response!)
            if (!cast.success) {
                BackOfficeService.getInstance().logout()
            } else {
                new HttpBackDemande().getDashboard().then((response) => {
                    const cast = new Response(response)
                    this.setState({ dashboard: cast.content as Dashboard })
                })
            }
        })
    }

    render() {
        const connected: BackAccount = BackOfficeService.getInstance().getBackAccount!
        const title = "Bienvenue " + connected.prenomInscription + " " + connected.nomInscription

        if (connected.type == RoleBackOffice.MEMBRE_CNA) {
            return (
                <>
                    <PrimaryContentBoxComponent title={title}>
                        <VerticalSpacing height={"20px"} />
                        <RowComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <BackOfficeGetDemandeCountByStatuts title={"Dernières 24h"} count={this.state?.dashboard?.last24h?.length ?? 0} />
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <BackOfficeGetDemandeCountByStatuts title={"A traiter DGCS"} count={this.state?.dashboard?.aTraiter ?? 0} />
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <BackOfficeGetDemandeCountByStatuts title={"En attente avis financeur"} count={this.state?.dashboard?.enAttenteAvisFinanceur ?? 0} />
                            </ColumnComponent>
                        </RowComponent>
                        <VerticalSpacing height={"5px"} />
                        <RowComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <BackOfficeGetDemandeCountByStatuts title={"En attente CNA"} count={this.state?.dashboard?.enAttenteCna ?? 0} />
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <BackOfficeGetDemandeCountByStatuts title={"Inscrit à la prochaine CNA"} count={this.state?.dashboard?.prochaineCna ?? 0} />
                            </ColumnComponent>
                            <ColumnComponent size={4} />
                        </RowComponent>
                        <VerticalSpacing height={"20px"} />
                    </PrimaryContentBoxComponent>
                </>
            )
        } else {
            // ADMIN || GESTIONNAIRE
            return (
                <>
                    <PrimaryContentBoxComponent title={title}>
                        <VerticalSpacing height={"20px"} />
                        <RowComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <a href="#" onClick={() => this.goToPage(Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES)}>
                                    <BackOfficeGetDemandeCountByStatuts title={"Dernières 24h"} count={this.state?.dashboard?.last24h?.length ?? 0} />
                                </a>
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <a href="#" onClick={() => this.goToPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_A_TRAITER)}>
                                    <BackOfficeGetDemandeCountByStatuts title={"A traiter DGCS"} count={this.state?.dashboard?.aTraiter ?? 0} />
                                </a>
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <a href="#" onClick={() => this.goToPage(Constant.DOM.BACK_OFFICE.AGREMENT__EN_ATTENTE_AVIS_FINANCEUR)}>
                                    <BackOfficeGetDemandeCountByStatuts title={"En attente avis financeur"} count={this.state?.dashboard?.enAttenteAvisFinanceur ?? 0} />
                                </a>
                            </ColumnComponent>
                        </RowComponent>
                        <VerticalSpacing height={"5px"} />
                        <RowComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <a href="#" onClick={() => this.goToPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_EN_ATTENTE_CNA)}>
                                    <BackOfficeGetDemandeCountByStatuts title={"En attente CNA"} count={this.state?.dashboard?.enAttenteCna ?? 0} />
                                </a>
                            </ColumnComponent>
                            <ColumnComponent size={4} style={{ padding: "40px" }}>
                                <a href="#" onClick={() => this.goToPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_PROCHAINE_CNA)}>
                                    <BackOfficeGetDemandeCountByStatuts title={"Inscrit à la prochaine CNA"} count={this.state?.dashboard?.prochaineCna ?? 0} />
                                </a>
                            </ColumnComponent>
                            <ColumnComponent size={4} />
                        </RowComponent>
                        <VerticalSpacing height={"20px"} />
                    </PrimaryContentBoxComponent>
                </>
            )
        }
    }
}
