import { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

export interface TitleProps extends DnumProps {
    label: string | ReactElement
    xlarge?: null
    large?: null
    medium?: null
    small?: null
    xsmall?: null
}

export abstract class AbstractTitleComponent<T extends TitleProps> extends DnumComponent<T, any> {
    private className = "fr-display"

    constructor(props: T) {
        super(props)
    }

    getClassName() {
        this.className = "fr-display"
        if (this.props.xlarge) {
            this.className += " fr-display-xl"
        } else if (this.props.large) {
            this.className += " fr-display-lg"
        } else if (this.props.medium) {
            this.className += " fr-display-md"
        } else if (this.props.small) {
            this.className += " fr-display-sm"
        } else if (this.props.xsmall) {
            this.className += " fr-display-xs"
        }
        return this.className
    }
}
