import { CSSProperties, ReactElement } from "react"

import { AbstractTitleComponent, TitleProps } from "../title/AbstractTitleComponent"

// TODO refactor Style
export interface HeadBandProps extends TitleProps {
    style?: CSSProperties
    color?: string
    center?: any
    rightComponent?: ReactElement
}

export default abstract class AbstractHeadBandComponent extends AbstractTitleComponent<HeadBandProps> {
    // TODO voir comment améliorer le code du Style
    getStyle(): CSSProperties {
        if (this.props.style) {
            return {
                width: "100%",
                background: this.props.style.background ? this.props.style.background : "white",
                padding: "10px",
                color: this.props.style.color ? this.props.style.color : "black",
                margin: "0px",
                textAlign: this.props.center ? ("center" as const) : ("left" as const)
            }
        }
        return {
            width: "100%",
            background: "white",
            padding: "10px",
            color: "black",
            margin: "0px",
            textAlign: "left" as const
        }
    }
}
