import React from "react"

import TypologieAccord from "../../../model/TypologieAccord"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class ListTypeAccordComponent extends BackOfficeAbstractReferentielListComponent<TypologieAccord> {
    getLine(item: TypologieAccord, index: number): React.ReactElement {
        return (
            <tr key={"row-typologie-demande-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.code}</td>
                <td>{item.libelle}</td>
                <td>{item.procedureSimplifiee ? "Oui" : "Non"}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"typologie-demande-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"typologie-demande-" + item.id}
                        title={"Suppression de la typologie de demande : " + item.code}
                        message={"Vous confirmez la suppression de cette typologie de demande ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </td>
            </tr>
        )
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useTypologieAccordService()
    }

    getAddLabel(): string {
        return "Ajouter une typologie"
    }

    getThead(): React.ReactElement {
        return (
            <thead>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "20%" }}>
                        Code
                    </th>
                    <th scope="col" style={{ width: "40%" }}>
                        Libellé
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                        Procédure simplifiée
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }
}
