import React from "react"

import FooterComponent from "../../../generic/component/footer/FooterComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import PageComponent from "../../../generic/component/grid/page/PageComponent"
import HeaderComponent from "../../../generic/component/header/HeaderComponent"

import { Constant } from "../../constant/Constant"
import EspaceFinanceurService from "../../service/EspaceFinanceurService"
import IPageCoordinator from "../../service/contract/IPageCoordinator"
import EspaceFinanceurPage from "../EspaceFinanceurPage"
import EspaceFinanceurRouter from "../EspaceFinanceurRouter"

interface MainPageState {
    pageId: string
    connected: boolean
    pageData: Map<string, any> | undefined
}

export default class EspaceFinanceurMainPage extends EspaceFinanceurPage<any, MainPageState> implements IPageCoordinator {
    router = new EspaceFinanceurRouter(EspaceFinanceurMainPage.applicationService)

    constructor(props: any) {
        super(props)
        EspaceFinanceurService.getInstance().setPageCoordinator(this)
    }

    componentDidMount() {
        if (this.state == null || this.state.pageId == null) {
            this.setState({
                pageId: Constant.DOM.ESPACE_FINANCEUR.HOME_PAGE,
                connected: EspaceFinanceurPage.applicationService.isFinanceurConnected()
            })
        }
    }

    isUserConnected(): boolean {
        return EspaceFinanceurMainPage.applicationService!.isFinanceurConnected()
    }

    logout() {
        EspaceFinanceurMainPage.applicationService.logout("main page")
        EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.HOME_PAGE)
        // moche
        document.getElementById("logoutButton")!.style.display = "none"
    }

    showMyAccount() {
        EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.MY_ACCOUNT)
    }

    getPageSwitcher() {
        return (pageId: any, pageData: Map<string, any>) => {
            this.setState({ pageId: pageId, pageData: pageData })
        }
    }

    getToolLinks(): any[] {
        const toolLinks = []

        if (this.isUserConnected()) {
            toolLinks.push(
                <a href="#compte" className="fr-link fr-fi-account-fill" rel="noopener" title="Mon compte" id="mon-compte" onClick={() => this.showMyAccount()}>
                    Mon compte
                </a>
            )
        }
        toolLinks.push(
            <a
                href="#contact"
                className="fr-link fr-fi-question-fill"
                rel="noopener"
                title="Nous contacter - nouvelle fenêtre"
                onClick={() => EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.CONTACT)}>
                Nous contacter
            </a>
        )

        if (this.isUserConnected()) {
            toolLinks.push(
                <a href="#logout" className="fr-link fr-fi-logout-box-r-fill" rel="noopener" title="Déconnexion" id="logoutButton" onClick={() => this.logout()}>
                    Déconnexion
                </a>
            )
        }
        //toolLinks.push(<button className="fr-link fr-fi-theme-fill fr-link--icon-left" aria-controls="fr-theme-modal" data-fr-opened="false">Param&egrave;tres d affichage</button>)
        return toolLinks
    }

    getBottomLinks(): any[] {
        const bottomLinks = []
        bottomLinks.push(
            <a className="fr-footer__bottom-link" onClick={() => EspaceFinanceurService.getInstance().switchPage(Constant.DOM.FRONT.LEGAL_NOTICE_PAGE)} href="#legal-notice">
                Mentions légales
            </a>
        )
        /*
        bottomLinks.push(<a className="fr-footer__bottom-link"
                            onClick={() => EspaceFinanceurService.getInstance().switchPage(Constant.DOM.FRONT.FAQ_PAGE)}
                            href="#faq">FAQ</a>)*/
        bottomLinks.push(
            <a className="fr-footer__bottom-link" onClick={() => EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.CONTACT)} href="#contact">
                Contact
            </a>
        )
        return bottomLinks
    }

    getContent() {
        console.info("AA")
        return this.router.search(this.state?.pageId ?? Constant.DOM.ESPACE_FINANCEUR.LOGIN_PAGE, this.state?.pageData)
    }

    render() {
        return (
            <PageComponent fluid>
                <HeaderComponent marianneTextFirstPart={"République"} marianneTextSecondPart={"française"} siteName={"Demande d'agrément des accords"} toolLinks={this.getToolLinks()} />

                <ContainerComponent>{this.getContent()}</ContainerComponent>

                <FooterComponent marianneTextFirstPart={"République"} marianneTextSecondPart={"française"} bottomLinks={this.getBottomLinks()} />
            </PageComponent>
        )
    }
}
