import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeEsmsFormComponent from "./BackOfficeEsmsFormComponent"
import BackOfficeEsmsListComponent from "./BackOfficeEsmsListComponent"

export default class BackOfficeEsmsPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Etablissements et services médico-sociaux"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeEsmsFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <BackOfficeEsmsListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__ESMS
    }
}
