/**
 * A migrer vers le pager Spring comme dans la liste des demandes pour l'admin
 */
export default class Pager {
    pageNumber: number | null = null
    itemByPage: number | null = null

    constructor(itemByPage: number) {
        this.pageNumber = 0
        this.itemByPage = itemByPage
    }

    setCurrentPage(newPageNumber: number) {
        this.pageNumber = newPageNumber
    }

    toQueryParams(): URLSearchParams {
        const params = new URLSearchParams()
        params.set("pageNumber", this.pageNumber!.toString())
        params.set("itemByPage", this.itemByPage!.toString())
        return params
    }
}
