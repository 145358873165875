import DnumComponent, { DnumProps } from "../../DnumComponent"
import HorizontalMenuItemData from "./HorizontalMenuItemData"

interface HorizontalMenuProps extends DnumProps {
    currentId: string
    data: HorizontalMenuItemData[]
    onClickHandler: (id: string) => void
}

interface HorizontalMenuState extends DnumProps {
    currentId: string | undefined
}

export default class HorizontalMenuComponent<P extends HorizontalMenuProps, S extends HorizontalMenuState> extends DnumComponent<P, S> {
    private onClickHandler: (id: string) => void

    constructor(props: P) {
        super(props)
        this.onClickHandler = this.props.onClickHandler
    }

    componentDidMount() {
        this.setState({ currentId: this.props.currentId })
    }

    getItems(): any[] {
        const items: any[] = []
        this.props.data.forEach((menuItem, index) => {
            if (menuItem.items === null || menuItem.items === undefined) {
                items.push(
                    <li className="fr-nav__item" key={"key-li-" + index}>
                        <a className="fr-nav__link" href="#" onClick={() => this.clickHandlerWrapper(menuItem.id!)} target="_self">
                            {menuItem.label}
                        </a>
                    </li>
                )
            } else {
                const id = this.generateId("menu-")
                items.push(
                    <li className="fr-nav__item" key={"key-li-" + id + "-" + index}>
                        {this.getParentItem(menuItem, id)}
                        <div className="fr-collapse fr-menu" id={id}>
                            <ul className="fr-menu__list">{this.getSubItems(menuItem)}</ul>
                        </div>
                    </li>
                )
            }
        })
        return items
    }

    getParentItem(menuItem: HorizontalMenuItemData, id: string) {
        let found = false
        menuItem.items?.forEach((item) => {
            if (this.state && item.id === this.state.currentId) {
                found = true
            }
        })
        if (found) {
            return (
                <button className="fr-nav__btn" aria-expanded="false" aria-controls={id} aria-current="true">
                    {menuItem.label}
                </button>
            )
        } else {
            return (
                <button className="fr-nav__btn" aria-expanded="false" aria-controls={id}>
                    {menuItem.label}
                </button>
            )
        }
    }

    getSubItems(item: HorizontalMenuItemData) {
        const items: any[] = []
        item.items?.forEach((menuItem, index) => {
            if (this.state && menuItem.id === this.state.currentId) {
                items.push(
                    <li key={menuItem.id + "-" + index}>
                        <a className="fr-nav__link" href="#" onClick={() => this.clickHandlerWrapper(menuItem.id!)} aria-current="page" target="_self">
                            {menuItem.label}
                        </a>
                    </li>
                )
            } else {
                items.push(
                    <li key={menuItem.id + "-" + index}>
                        <a className="fr-nav__link" href="#" onClick={() => this.clickHandlerWrapper(menuItem.id!)} target="_self">
                            {menuItem.label}
                        </a>
                    </li>
                )
            }
        })
        return items
    }

    clickHandlerWrapper(id: string) {
        this.onClickHandler(id)
        this.setState({ currentId: id })
    }

    render() {
        const mainId = this.generateId("navigation-")
        return (
            <nav className="fr-nav" id={mainId} role="navigation" aria-label="Menu principal">
                <ul className="fr-nav__list">{this.getItems()}</ul>
            </nav>
        )
    }
}
