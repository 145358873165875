import React, { Fragment, ReactElement } from "react"

import Cout from "../../../model/Cout"
import File from "../../../model/File"
import FileType from "../../../model/FileType"
import FinessEtablissement from "../../../model/FinessEtablissement"
import FullAvisFinanceur from "../../../model/FullAvisFinanceur"
import OrganismeFinanceur from "../../../model/OrganismeFinanceur"
import TypologieAccord from "../../../model/TypologieAccord"

import { DnumProps } from "../../../../generic/component/DnumComponent"
import FieldsetComponent from "../../../../generic/component/FieldsetComponent"
import BadgeGreenMintComponent from "../../../../generic/component/badge/BadgeGreenMintComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../generic/component/select/SelectOptionData"

import { Constant } from "../../../constant/Constant"
import Role from "../../../constant/role/Role"
import HttpBackFileService from "../../../service/http/HttpBackFileService"
import HttpFrontReferentielService from "../../../service/http/HttpFrontReferentielService"
import HttpService from "../../../service/http/HttpService"
import UiService from "../../../service/ui/UiService"
import VerticalSpacing from "../../../shared/component/space/VerticalSpacing"
import BackOfficeComponent from "../../BackOfficeComponent"
import BackOfficeDemandeAgrementForm from "../../common/BackOfficeDemandeAgrementForm"
import BackOfficeInstructionNumeroDossierDaccord from "./BackOfficeInstructionNumeroDossierDaccord";

interface BackInstructionComponentProps extends DnumProps {
    demande: BackOfficeDemandeAgrementForm
    avisList: FullAvisFinanceur<null>[] | null
    key?: string
    typologies: TypologieAccord[] | undefined
}

interface BackInstructionComponentState {
    files: File[] | undefined
    typologies: TypologieAccord[] | undefined
}

export default class BackOfficeInstructionComponent extends BackOfficeComponent<BackInstructionComponentProps, BackInstructionComponentState> {
    currencyFormatter: Intl.NumberFormat | null = null

    allowedModStatus = [
        Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA,
        Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER,
        Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR,
        Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA
    ]

    constructor(props: BackInstructionComponentProps) {
        super(props)
        this.currencyFormatter = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })
    }

    componentDidMount() {
        new HttpBackFileService().getDemandesFiles(this.props.demande.id!).then((response) => {
            this.setState({ files: response.content })
        })

        if (this.props.typologies && this.props.typologies.length > 0) {
            this.setState({ typologies: this.props.typologies })
        } else {
            new HttpFrontReferentielService().getAll<TypologieAccord>().then((response) => {
                this.setState({ typologies: response.content })
            })
        }
    }

    getTitleComponent(title: string) {
        return <BadgeGreenMintComponent label={title} />
    }

    getTitleData() {
        return this.getTitleComponent("1 - Données de l'accord")
    }

    getTitleCouts() {
        return this.getTitleComponent("2 - Coût de la mesure")
    }

    getTitlePiecesJointes() {
        return this.getTitleComponent("3 - Pièces jointes")
    }

    getDueDateSignature() {
        if (this.props.demande?.dateSignature != null) {
            return <div>DUE signée le : {UiService.formatServerDateToHumanDate(this.props.demande.dateSignature)}</div>
        }
    }

    getAccordDateSignature() {
        if (this.props.demande?.dateSignature != null) {
            return <div>Accord signé le : {UiService.formatServerDateToHumanDate(this.props.demande.dateSignature)}</div>
        }
    }

    getNumeroDossier() {
        if (this.props.demande?.numeroDossier != null) {
            return <Fragment>
                <div>Numéro de dossier : {this.props.demande.numeroDossier}</div>
                <BackOfficeInstructionNumeroDossierDaccord idDossier={this.props.demande.id!.toString()} typeDossier={this.props.demande.typeDemande!} />
            </Fragment>
        }
    }

    getAccordDateDepot() {
        if (this.props.demande?.dateDepot != null) {
            return <div>Date de dépôt : {UiService.formatServerDateToHumanDate(this.props.demande.dateDepot)}</div>
        }
    }

    getNomAssociation() {
        if (this.props.demande?.entiteJuridique != null) {
            return <div>Nom de l'association : {this.props.demande.entiteJuridique}</div>
        }
    }

    getEtablissementsConcernes() {
        const concernes: ReactElement[] = []

        this.props.demande?.etablissements?.forEach((etablissement: FinessEtablissement) => {
            concernes.push(
                <li key={"concerne-" + etablissement.id} style={{ paddingTop: "10px" }}>
                    {etablissement.raisonSociale}
                </li>
            )
        })

        return concernes
    }

    getTitleEtablissementsConcernes() {
        const count: number = this.props.demande?.etablissements?.length ?? 0
        if (count > 1) {
            return <span>Les {count} établissements concernés</span>
        } else if (count == 1) {
            return <span>Un établissement concerné</span>
        }
    }

    getConventionCollective() {
        return "Convention collective appliquée : " + this.props.demande?.idccLabel
    }

    getEffectifsEtSalaires() {
        return (
            <>
                Courriel de contact : {this.props.demande?.emailContact}
                <br />
                Masse salariale chargée des ESMS concernés :{" "}
                {this.props.demande?.etablissements
                    ?.reduce((accumulator, obj) => {
                        return accumulator + obj.masseSalarialeChargee!
                    }, 0)
                    .toFixed(2)}
                <br />
                Nombre d'ETP au total :{" "}
                {this.props.demande?.etablissements
                    ?.reduce((accumulator, obj) => {
                        return accumulator + obj.etp!
                    }, 0)
                    .toFixed(2)}
                <br />
                ETP concernés par la mesure : {this.props.demande?.etpConcernes}
            </>
        )
    }

    getThematique() {
        if (this.props.demande?.typologieAccord != null) {
            return this.props.demande?.typologieAccord?.libelle
        } else {
            return "Non renseignée"
        }
    }

    hasDataForAnnee(annee: number): boolean {
        let check = false
        this.props.demande?.financement?.forEach((financement) => {
            if (financement.a != null && financement.a == annee) {
                if ((financement.picf ?? 0 > 0) || (financement.picv ?? 0 > 0) || (financement.mn ?? 0 > 0) || (financement.rp ?? 0 > 0) || (financement.ds ?? 0 > 0)) {
                    check = true
                }
            }
        })
        return check
    }

    getAnneeN(annee: number): ReactElement {
        const ligneFinancement: ReactElement[] = []
        ligneFinancement.push(
            <RowComponent key={"row-get-" + annee + "-header"}>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Autorité de tarification
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par des ressources propres
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par les dotations actuelles
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par des crédits nouveaux
                </ColumnComponent>
            </RowComponent>
        )
        ligneFinancement.push(<VerticalSpacing height="20px" key={"row-get-" + annee + "-separator1"} />)
        ligneFinancement.push(<hr key={"row-get-" + annee + "-holi1"} />)
        const financeurs = new Map<number, OrganismeFinanceur>()
        this.props.demande?.etablissements?.forEach((etablissement) => {
            etablissement.financeurs?.forEach((financeur) => financeurs.set(financeur.id!, financeur))
        })
        let totalAnneeN = 0
        // Financeurs
        this.props.demande?.financement?.forEach((financement, index) => {
            if (financement.id != null && financement.a == annee) {
                const nom: string | null | undefined = financeurs.get(financement.id)?.nom
                ligneFinancement.push(this.getCleanSomme(index, nom!, financement))
                totalAnneeN += (financement.ds ?? 0) + (financement.rp ?? 0) + (financement.mn ?? 0)
            }
        })
        ligneFinancement.push(<hr key={"separator-tableau-0"} style={{ marginTop: "20px", marginRight: "12px" }} />)
        // CAF
        this.props.demande?.financement?.forEach((financement) => {
            if (financement.picf != null && financement.a == annee) {
                ligneFinancement.push(
                    <RowComponent key={"row-get-" + annee + "-caf"}>
                        <ColumnComponent size={2}>CAF</ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {this.currencyFormatter?.format(financement.picf ?? 0)}
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                )
                totalAnneeN += financement.picf ?? 0
            }
        })
        // CNAV
        this.props.demande?.financement?.forEach((financement) => {
            if (financement.picf != null && financement.a == annee) {
                ligneFinancement.push(
                    <RowComponent key={"row-get-" + annee + "-cnav"}>
                        <ColumnComponent size={2}>CNAV</ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {this.currencyFormatter?.format(financement.picv ?? 0)}
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                )
                totalAnneeN += financement.picv ?? 0
            }
        })
        ligneFinancement.push(<hr key={"separator-tableau-1"} style={{ marginTop: "20px", marginRight: "12px" }} />)
        return (
            <Fragment key={"financement-annee-" + annee}>
                {ligneFinancement}
                <RowComponent key={"row-total-" + annee}>
                    <ColumnComponent size={2}>Coût total année </ColumnComponent>
                    <ColumnComponent size={2}>
                        <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{this.currencyFormatter?.format(totalAnneeN)}</div>
                    </ColumnComponent>
                </RowComponent>
            </Fragment>
        )
    }

    getCleanSomme(index: number, nom: string, cout: Cout): ReactElement {
        const a = this.currencyFormatter?.format(cout.rp ?? 0)
        const b = this.currencyFormatter?.format(cout.ds ?? 0)
        const c = this.currencyFormatter?.format(cout.mn ?? 0)
        return (
            <RowComponent key={"row-get-" + index + "-cs"}>
                <ColumnComponent size={3}>{nom}</ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{a}</div>
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{b}</div>
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{c}</div>
                </ColumnComponent>
            </RowComponent>
        )
    }

    getPiecesJointes(styleFieldset: object) {
        const elements: ReactElement[] = []
        let indexPJ = 1
        let documentAccord = true
        this.state?.files?.forEach((file, index) => {
            if (documentAccord && file.type == FileType.ACCORD_DUE) {
                documentAccord = false
                if (this.props.demande?.typeDemande == "DUE") {
                    elements.push(
                        <fieldset style={styleFieldset} key={"pj-" + index}>
                            <legend style={{ fontWeight: "bold" }}>DUE</legend>
                            <RowComponent>
                                <ColumnComponent size={12}>{this.applicationService.buildLink(file)}</ColumnComponent>
                            </RowComponent>
                        </fieldset>
                    )
                    elements.push(<br key={"separator-" + index} />)
                } else {
                    elements.push(
                        <fieldset style={styleFieldset} key={"pj-" + index}>
                            <legend style={{ fontWeight: "bold" }}>Accord</legend>
                            <RowComponent>
                                <ColumnComponent size={6}>{this.applicationService.buildLink(file)}</ColumnComponent>
                                <ColumnComponent size={2}></ColumnComponent>
                                <ColumnComponent size={4}>
                                    <small>{UiService.formatServerDateToHumanNumericDate(file.dateCreation)}</small>
                                </ColumnComponent>
                            </RowComponent>
                        </fieldset>
                    )
                    elements.push(<br key={"separator-" + index} />)
                }
            } else if (file.type?.indexOf("PIECE") != -1) {
                elements.push(
                    <fieldset style={styleFieldset} key={"pj-" + index}>
                        <legend style={{ fontWeight: "bold" }}>Pièce complémentaire {indexPJ++}</legend>
                        <RowComponent>
                            <ColumnComponent size={6}>{this.applicationService.buildLink(file)}</ColumnComponent>
                            <ColumnComponent size={2}></ColumnComponent>
                            <ColumnComponent size={4}>
                                <small>{UiService.formatServerDateToHumanNumericDate(file.dateCreation)}</small>
                            </ColumnComponent>
                        </RowComponent>
                    </fieldset>
                )
                elements.push(<br key={"separator-" + index} />)
            }
        })
        return elements
    }

    getLabelAnnee(annee: number) {
        if (annee == 0) {
            return "N"
        } else {
            return "N+" + annee
        }
    }

    getTotal() {
        let total = 0
        this.props.demande?.financement?.forEach((cout) => {
            total += cout.mn ?? 0
            total += cout.ds ?? 0
            total += cout.rp ?? 0
            total += cout.picv ?? 0
            total += cout.picf ?? 0
        })
        return (
            <Fragment>
                Coût total : <b>{this.currencyFormatter?.format(total)}</b>
            </Fragment>
        )
    }

    getStats(coutAt: Map<number, number>) {
        const lignesAT = [] as ReactElement[]
        const lignesCout = [] as ReactElement[]
        const financeurs = new Map<number, OrganismeFinanceur>()
        const listEtabCpom = [] as FinessEtablissement[]
        const totalCout = new Map<string, number>()
        let total = 0,
            totalRP = 0,
            totalDS = 0,
            totalMN = 0
        let masseSalarialeChargee = 0,
            masseSalarialeChargeeHorsCpom = 0,
            etp = 0,
            etpHorsCpom = 0
        this.props.demande?.etablissements?.forEach((etablissement) => {
            if (etablissement.cpomValue == "NON") {
                masseSalarialeChargeeHorsCpom += etablissement.masseSalarialeChargee ?? 0
                etpHorsCpom += etablissement.etp ?? 0
                listEtabCpom.push(etablissement)
            }
            masseSalarialeChargee += etablissement.masseSalarialeChargee ?? 0
            etp += etablissement.etp ?? 0
            etablissement.financeurs?.forEach((financeur) => financeurs.set(financeur.id!, financeur))
        })

        masseSalarialeChargee = Math.round(masseSalarialeChargee) * 1000
        masseSalarialeChargeeHorsCpom = Math.round(masseSalarialeChargeeHorsCpom) * 1000

        this.props.demande?.financement?.forEach((cout) => {
            totalCout.set("RP-" + financeurs.get(cout.id!)?.id, 0)
            totalCout.set("DS-" + financeurs.get(cout.id!)?.id, 0)
            totalCout.set("MN-" + financeurs.get(cout.id!)?.id, 0)
        })

        this.props.demande?.financement?.forEach((cout) => {
            total += cout.mn ?? 0
            total += cout.ds ?? 0
            total += cout.rp ?? 0
            total += cout.picv ?? 0
            total += cout.picf ?? 0
            totalRP += cout.rp ?? 0
            totalDS += cout.ds ?? 0
            totalMN += cout.mn ?? 0
            totalCout.set("RP-" + financeurs.get(cout.id!)?.id, totalCout.get("RP-" + financeurs.get(cout.id!)?.id)! + cout.rp!)
            totalCout.set("DS-" + financeurs.get(cout.id!)?.id, totalCout.get("DS-" + financeurs.get(cout.id!)?.id)! + cout.ds!)
            totalCout.set("MN-" + financeurs.get(cout.id!)?.id, totalCout.get("MN-" + financeurs.get(cout.id!)?.id)! + cout.mn!)
        })

        // console.log("totalCout", totalCout)

        lignesCout.push(
            <RowComponent key={"row-get-" + lignesCout.indexOf + "-cout"}>
                <ColumnComponent size={2}>Global </ColumnComponent>
                <ColumnComponent size={2}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{Math.round((totalRP / total) * 100) + " %"}</div>
                </ColumnComponent>
                <ColumnComponent size={2}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{Math.round((totalDS / total) * 100) + " %"}</div>
                </ColumnComponent>
                <ColumnComponent size={2}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{Math.round((totalMN / total) * 100) + " %"}</div>
                </ColumnComponent>
                <ColumnComponent size={2}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{this.currencyFormatter?.format(total)}</div>
                </ColumnComponent>
            </RowComponent>
        )

        this.props.demande?.financement?.forEach((cout) => {
            if (cout.id != null && cout.a == 0) {
                const nom: string | null | undefined = financeurs.get(cout.id)?.nom
                const id: number | null | undefined = financeurs.get(cout.id)?.id
                const totalCoutAT: number = totalCout.get("RP-" + id)! + totalCout.get("DS-" + id)! + totalCout.get("MN-" + id)!
                // {console.log(totalCoutAT)}

                {
                    coutAt.set(id!, Math.round((totalCoutAT / total) * 100))
                }

                lignesCout.push(
                    <RowComponent key={"row-get-" + id + "-cout"}>
                        <ColumnComponent size={2}>{nom}</ColumnComponent>
                        <ColumnComponent size={2}>
                            {/* {console.log("get " +totalCout.get("RP-"+id)!)}
                {console.log("totalRP " + totalRP!)}
                {console.log("total " + totalCout.get("RP-"+id)! / totalRP)} */}
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {totalRP != 0 ? Math.round((totalCout.get("RP-" + id)! / totalRP) * 100) + " %" : "0 %"}
                            </div>
                        </ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {totalDS != 0 ? Math.round((totalCout.get("DS-" + id)! / totalDS) * 100) + " %" : "0 %"}
                            </div>
                        </ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {totalMN != 0 ? Math.round((totalCout.get("MN-" + id)! / totalMN) * 100) + " %" : "0 %"}
                            </div>
                        </ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{Math.round((totalCoutAT / total) * 100) + " %"}</div>
                        </ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{this.currencyFormatter?.format(totalCoutAT)}</div>
                        </ColumnComponent>
                    </RowComponent>
                )
            }
        })

        return (
            <Fragment>
                <RowComponent key={"row-get-" + this.props.demande.id + "-stats-header"}>
                    <ColumnComponent size={4} style={{ textAlign: "center", fontWeight: "bold" }}>
                        Montant
                    </ColumnComponent>
                    <ColumnComponent size={4} style={{ textAlign: "center", fontWeight: "bold" }}>
                        Par rapport à masse salariale chargée totale
                    </ColumnComponent>
                    <ColumnComponent size={4} style={{ textAlign: "center", fontWeight: "bold" }}>
                        Par rapport à masse salariale hors ESSMS sous CPOM
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="20px" />
                <hr />
                <RowComponent key={"row-get-cs"}>
                    <ColumnComponent size={4}>
                        <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{this.currencyFormatter?.format(total)}</div>
                    </ColumnComponent>
                    <ColumnComponent size={4}>
                        {/* {console.log("masseChargee "+masseSalarialeChargee+"\n total " +total+ "\n chargeeCpom " + masseSalarialeChargeeHorsCpom)}
                        {console.log(total) } */}
                        <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                            {masseSalarialeChargee != 0 ? Math.round((total / masseSalarialeChargee) * 100) + " %" : "0 %"}
                        </div>
                    </ColumnComponent>
                    <ColumnComponent size={4}>
                        <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                            {masseSalarialeChargeeHorsCpom != 0 ? Math.round((total / masseSalarialeChargeeHorsCpom) * 100) + " %" : "0 %"}
                        </div>
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="20px" />
                <hr />
                <RowComponent key={"row-get-" + this.props.demande.id + "-stats-header-2"}>
                    <ColumnComponent size={2}></ColumnComponent>
                    <ColumnComponent size={2} style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>
                        Dont financés par ressources propres
                    </ColumnComponent>
                    <ColumnComponent size={2} style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>
                        Dont financés par dotation existantes
                    </ColumnComponent>
                    <ColumnComponent size={2} style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>
                        Dont financés sur mesures nouvelles
                    </ColumnComponent>
                    <ColumnComponent size={4} style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>
                        Coût global de l'accord
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="20px" />
                <hr />
                {lignesCout}
                <VerticalSpacing height="20px" />
                <hr />
            </Fragment>
        )
    }

    getCoutsView(styleFieldset: object) {
        const coutAt = new Map<number, number>()
        if (this.props.demande.financementTotal == 0) {
            return <Fragment key={"cout-view"}>Sans impact financier</Fragment>
        } else if (this.props.demande.sansImpactFinancier) {
            return <Fragment key={"cout-view"}>Sans impact financier</Fragment>
        } else {
            const annees = [0, 1, 2, 3]
            const anneesFinancement: ReactElement[] = []
            annees.forEach((annee) => {
                if (this.hasDataForAnnee(annee)) {
                    anneesFinancement.push(
                        <Fragment key={"cout-view-" + annee}>
                            <fieldset style={styleFieldset}>
                                <legend style={{ fontWeight: "bold" }}>Année {this.getLabelAnnee(annee)}</legend>
                                {this.getAnneeN(annee)}
                            </fieldset>
                            <VerticalSpacing height="40px" />
                        </Fragment>
                    )
                }
            })
            return (
                <Fragment key={"cout-view"}>
                    {anneesFinancement}
                    <fieldset style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Total</legend>
                        {this.getTotal()}
                    </fieldset>
                    <VerticalSpacing height="40px" />
                    <fieldset style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Exploitation des données</legend>
                        {this.getStats(coutAt)}
                    </fieldset>
                    {this.getComplementExplication()}
                    <br />
                    <br />
                    {this.getCoutsAvis(coutAt)}
                </Fragment>
            )
        }
    }

    getComplementExplication() {
        if (this.props.demande.explication) {
            return (
                <div style={{ textAlign: "left" }}>
                    <br />
                    <FieldsetComponent centerContent={false} legend={"Explications complémentaires"} content={this.props.demande.explication} />
                    <br />
                </div>
            )
        }
    }

    getCoutsAvis(coutAt: Map<number, number>) {
        if (this.props.avisList != null && this.props.avisList.length > 0) {
            const avisList: ReactElement[] = []

            this.props.avisList.forEach((fullAvis) => {
                const nom = fullAvis.organisme?.nom + " (" + coutAt.get(fullAvis.organisme!.id!) + "%)"

                if (fullAvis.avis?.result != null && fullAvis.avis.commentaire != null && fullAvis.avis.commentaire != "") {
                    avisList.push(
                        <li key={fullAvis.avis.id} style={{ marginBottom: "15px" }}>
                            <span>
                                {nom} : {fullAvis.avis.result ? "accepté" : "refusé"} le {UiService.formatServerDateToHumanDate(fullAvis.avis?.dateAvis)}
                            </span>
                            <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                                Commentaire : <i>{fullAvis.avis?.commentaire}</i>
                            </div>
                        </li>
                    )
                } else if (fullAvis.avis?.result != null) {
                    avisList.push(
                        <li key={fullAvis.avis.id} style={{ marginBottom: "15px" }}>
                            <span>
                                {nom} : {fullAvis.avis.result ? "accepté" : "refusé"} le {UiService.formatServerDateToHumanDate(fullAvis.avis?.dateAvis)}
                            </span>
                        </li>
                    )
                } else if (UiService.formatServerDateToHumanDate(fullAvis.avis?.dateRelance) != UiService.formatServerDateToHumanDate(fullAvis.avis?.dateCreation)) {
                    avisList.push(
                        <li key={fullAvis.organisme!.id} style={{ marginBottom: "15px" }}>
                            <span>
                                {nom} : relance envoyée le {UiService.formatServerDateToHumanDate(fullAvis.avis?.dateRelance)}
                            </span>
                        </li>
                    )
                } else if (fullAvis.avis?.dateModification != null) {
                    avisList.push(
                        <li key={fullAvis.organisme!.id} style={{ marginBottom: "15px" }}>
                            <span>
                                {nom} : notification envoyée le {UiService.formatServerDateToHumanDate(fullAvis.avis?.dateCreation)}
                            </span>
                        </li>
                    )
                } else if (fullAvis.avis?.dateModification == null) {
                    avisList.push(
                        <li key={fullAvis.organisme!.id} style={{ marginBottom: "15px" }}>
                            <span>{nom}</span>
                        </li>
                    )
                } else {
                    avisList.push(
                        <li key={fullAvis.organisme!.id} style={{ marginBottom: "15px" }}>
                            <span>{nom} : pas de réponse</span>
                        </li>
                    )
                }
            })
            if (this.props.avisList != null) {
                if (this.props.avisList.length == 1) {
                    return <FieldsetComponent legend={"Autorité de tarification"} content={<ul style={{ listStyleType: "square" }}>{avisList}</ul>} />
                } else if (this.props.avisList.length > 1) {
                    return <FieldsetComponent legend={"Autorités de tarification"} content={<ul style={{ listStyleType: "square" }}>{avisList}</ul>} />
                }
            }
        }
    }

    saveTypoDemande(e: any) {
        const typoCode = this.state.typologies?.find((typo) => typo.id == e.target.value)!.code
        new HttpService().request("/gestion/demande/" + this.props.demande.id + "/typologie", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(typoCode)
        })
    }

    checkifTypoModAllowed() {
        const currentType = JSON.parse(localStorage.getItem("backAccountLocaleStorageKey")!).type
        if (currentType == Role.BACK.ADMIN || currentType == Role.BACK.GESTIONNAIRE) {
            if (this.allowedModStatus.includes(this.props.demande.etat!)) {
                return true
            }
        }
        return false
    }

    getAccord(): ReactElement {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        const data = this.getOptions()

        return (
            <Fragment>
                <RowComponent key={"accord-main"}>
                    <ColumnComponent size={12}>
                        <br />
                        {this.getTitleData()}
                        <br />
                        <br />
                        <RowComponent key={"accord-details-1"}>
                            <ColumnComponent size={5}>
                                <fieldset style={styleFieldset}>
                                    <legend style={{ fontWeight: "bold" }}>&nbsp;Générales&nbsp;</legend>
                                    {this.getNomAssociation()}
                                    <br />
                                    {this.getNumeroDossier()}
                                    <br />
                                    {this.getAccordDateSignature()}
                                    <br />
                                    {this.getAccordDateDepot()}
                                    <br />
                                    {this.getConventionCollective()}
                                    <br />
                                </fieldset>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={5}>
                                <fieldset style={styleFieldset}>
                                    <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                                    {this.getEffectifsEtSalaires()}
                                </fieldset>
                            </ColumnComponent>
                        </RowComponent>
                        <VerticalSpacing height="40px" />
                        <RowComponent key={"accord-details-2"}>
                            <ColumnComponent size={5}>
                                <fieldset style={styleFieldset}>
                                    <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                                    <RowComponent>
                                        <ColumnComponent size={12} style={{ paddingLeft: "15px" }}>
                                            {this.getEtablissementsConcernes()}
                                            <br />
                                        </ColumnComponent>
                                    </RowComponent>
                                </fieldset>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={5}>
                                <fieldset style={styleFieldset}>
                                    <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                                    {this.checkifTypoModAllowed() ? (
                                        <SelectComponent id="select-typologie" label="Typologie" value={data[0]} data={data[1]} onChange={(e) => this.saveTypoDemande(e)} />
                                    ) : (
                                        this.getThematique()
                                    )}
                                </fieldset>
                            </ColumnComponent>
                        </RowComponent>
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="80px" key={"ver-spa-1-" + this.props.demande.id} />
                <RowComponent key={"accord-cout-" + this.props.demande.id}>
                    <ColumnComponent size={12}>
                        {this.getTitleCouts()}
                        <br />
                        <br />
                        {this.getCoutsView(styleFieldset)}
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="80px" key={"ver-spa-2-" + this.props.demande.id} />
                <RowComponent key={"accord-pj-" + this.props.demande.id}>
                    <ColumnComponent size={12} key={"col1"}>
                        {this.getTitlePiecesJointes()}
                        <br />
                        <br />
                        {this.getPiecesJointes(styleFieldset)}
                    </ColumnComponent>
                    <ColumnComponent size={1} key={"col2"} />
                </RowComponent>
            </Fragment>
        )
    }

    getOptions(): [string, SelectOptionData[]] {
        let value = ""
        const options: SelectOptionData[] = []

        if (this.state && this.state.typologies) {
            this.state.typologies.forEach((typologie) => {
                let selected = false
                if (this.props.demande?.typologieAccord) {
                    selected = this.props.demande?.typologieAccord!.id === typologie.id
                }
                const option = new SelectOptionData(typologie.id!.toString(), typologie.libelle!, selected)
                options.push(option)
                if (selected) {
                    value = option.id
                }
            })
        }

        return [value, options]
    }

    getDue(): ReactElement {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        const data = this.getOptions()

        return (
            <Fragment>
                <RowComponent key={"due-title"}>
                    <ColumnComponent size={12}>
                        <br />
                        {this.getTitleData()}
                        <br />
                        <br />
                    </ColumnComponent>
                </RowComponent>
                <RowComponent key={"due-details-1"}>
                    <ColumnComponent size={5}>
                        <fieldset style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>&nbsp;Générales&nbsp;</legend>
                            {this.getDueDateSignature()}
                            <br />
                            {this.getConventionCollective()}
                            <br />
                        </fieldset>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={5}>
                        <fieldset style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                            {this.getEffectifsEtSalaires()}
                            <br />
                        </fieldset>
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="40px" />
                <RowComponent key={"due-details-2"}>
                    <ColumnComponent size={5}>
                        <fieldset style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                            <RowComponent>
                                <ColumnComponent size={1} />
                                <ColumnComponent size={10}>
                                    {this.getEtablissementsConcernes()}
                                    <br />
                                </ColumnComponent>
                                <ColumnComponent size={1} />
                            </RowComponent>
                        </fieldset>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={5}>
                        <fieldset style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                            {this.checkifTypoModAllowed() ? (
                                <SelectComponent id="select-typologie" label="Typologie" value={data[0]} data={data[1]} onChange={(e) => this.saveTypoDemande(e)} />
                            ) : (
                                this.getThematique()
                            )}
                        </fieldset>
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="80px" />
                <RowComponent key={"due-cout"}>
                    <ColumnComponent size={12}>
                        {this.getTitleCouts()}
                        <br />
                        <br />
                        {this.getCoutsView(styleFieldset)}
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height="80px" />
                <RowComponent key={"due-pj"}>
                    <ColumnComponent size={12}>
                        {this.getTitlePiecesJointes()}
                        <br />
                        <br />
                        {this.getPiecesJointes(styleFieldset)}
                    </ColumnComponent>
                </RowComponent>
                <br />
            </Fragment>
        )
    }

    render() {
        return <div key={"boic-" + this.props.demande.id}>{this.props.demande?.typeDemande === "ACCORD" ? this.getAccord() : this.getDue()}</div>
    }
}
