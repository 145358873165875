import React, {ChangeEvent, Component} from 'react';
import ContainerComponent from '../../../generic/component/grid/container/ContainerComponent';
import PrimaryContentBoxComponent from '../../shared/component/box/PrimaryContentBoxComponent';
import DateInputComponent from '../../../generic/component/form/input/DateInputComponent';
import Button from '../../../generic/component/button/Button';
import RadioButtonGroupComponent from '../../../generic/component/radio/RadioButtonGroupComponent';
import RadioButtonData from '../../../generic/component/radio/RadioButtonData';
import SelectOptionData from '../../../generic/component/select/SelectOptionData';
import SelectComponent from '../../../generic/component/select/SelectComponent';
import HttpReferentielService from "../../service/http/HttpReferentielService"
import OrganismeFinanceur from '../../model/OrganismeFinanceur';

interface BackOfficeInstructionExtractionState {
    typeExtraction: string,
    favo: boolean | null,
    organismes: OrganismeFinanceur[] | undefined,
    ATSelect: string,
    regionSelect: string,
    regions: string[] | undefined
}

class BackOfficeInstructionExtraction extends Component<any, BackOfficeInstructionExtractionState> {

    constructor(props: any) {
        super(props);
        this.state = {
            typeExtraction: "all",
            favo: null,
            organismes: undefined,
            regions: undefined,
            ATSelect: "all",
            regionSelect: "all"
        }
    }

    componentDidMount() {
        new HttpReferentielService()
            .useOrganismeFinanceurService()
            .getAll<OrganismeFinanceur>()
            .then((response) => {
                //update options
                this.setState({organismes: response.content})
            }).then(() => {
            new HttpReferentielService()
                .useRegionService()
                .getAll<string>()
                .then((response) => {
                    //update options
                    this.setState({regions: response.content})
                })
        })
    }

    today = new Date().toISOString().split('T')[0];

    setExtractType(event: ChangeEvent<HTMLInputElement>) {
        this.setState({typeExtraction: event.target.value})
    }

    downloadCsv() {
        const dateBegin = document.getElementById('extractDateBegin') as HTMLInputElement;
        const dateEnd = document.getElementById('extractDateEnd') as HTMLInputElement;
        let url: string;
        if (this.state.typeExtraction == "all") {
            url = process.env.REACT_APP_CONTEXT_PATH + "/back/csv/demandes/" + dateBegin.value + "/" + dateEnd.value;
        } else if (this.state.typeExtraction == "at") {
            url = process.env.REACT_APP_CONTEXT_PATH + "/back/csv/cout/" + dateBegin.value + "/" + dateEnd.value;
            this.state.ATSelect != "" ? url += "?finanId=" + this.state.ATSelect : ""
            this.state.favo != null ? url += "&favo=" + this.state.favo : ""
            console.log(url)
        } else if (this.state.typeExtraction == "region") {
            url = process.env.REACT_APP_CONTEXT_PATH + "/back/csv/cout/" + dateBegin.value + "/" + dateEnd.value;
            this.state.regionSelect != "" ? url += "?region=" + this.state.regionSelect : ""
            this.state.favo != null ? url += "&favo=" + this.state.favo : ""
            console.log(this.state.regions)
        } else {
            //Failsafe
            url = process.env.REACT_APP_CONTEXT_PATH + "/back/csv/demandes/" + dateBegin.value + "/" + dateEnd.value;
        }
        this.openInNewTab(url);
    }

    openInNewTab(url: string) {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    getRadios() {
        const radios: RadioButtonData[] = []
        console.log("getRadios");
        radios.push(new RadioButtonData("radio-all", "Toutes les données", true, "radio-all", "all"));
        radios.push(new RadioButtonData("radio-at", "Coût par AT", false, "radio-all", "at"));
        radios.push(new RadioButtonData("radio-region", "Coût par région", false, "radio-all", "region"));
        console.log(this.state.regions)
        return radios;
    }

    onChangeFavo(event: ChangeEvent<HTMLSelectElement>) {
        switch (event.target.value) {
            case "Favorable":
                this.setState({favo: true})
                break

            case "Défavorable":
                this.setState({favo: false})
                break

            default:
                this.setState({favo: null})
                break
        }
    }

    onChangeAT(event: ChangeEvent<HTMLSelectElement>) {
        this.setState({ATSelect: event.target.value})
    }

    onChangeRegion(event: ChangeEvent<HTMLSelectElement>) {
        this.setState({regionSelect: event.target.value})
    }

    getOrganismes() {
        const options: SelectOptionData[] = [new SelectOptionData("all", "Tout les AT", true)]
        this.state.organismes!.forEach((organisme: OrganismeFinanceur) => {
            options.push(new SelectOptionData(organisme.nom!, organisme.nom!, false))
        })
        return options;
    }

    //TODO faire l'affichage conditionnel du select des favo
    getCoutFields() {
        if (this.state.typeExtraction === "at" || this.state.typeExtraction === "region") {
            const types: SelectOptionData[] = []
            types.push(new SelectOptionData("", "Aucun filtre", true))
            types.push(new SelectOptionData("Favorable", "Favorable", false))
            types.push(new SelectOptionData("Défavorable", "Défavorable", false))
            return <SelectComponent label={"Filtre Avis CNA"} data={types} style={{width: "100%"}}
                                    id={"select-type-filter"} onChange={(event) => this.onChangeFavo(event)}/>
        } else {
            return null
        }
    }

    getCoutATSelect() {
        if (this.state.typeExtraction === "at") {
            return <SelectComponent label={"Autorité de Tarification concernée"} data={this.getOrganismes()}
                                    style={{width: "100%"}} id={"select-type-filter"}
                                    onChange={(event) => this.onChangeAT(event)}/>
        } else {
            return null
        }
    }

    getCoutRegionSelect() {
        if (this.state.typeExtraction === "region") {
            const options: SelectOptionData[] = [new SelectOptionData("all", "Toutes les régions", true)]
            this.state.regions!.forEach((region: string) => {
                options.push(new SelectOptionData(region, region, false))
            })
            return <SelectComponent label={"Région concernée"} data={options} style={{width: "100%"}}
                                    id={"select-type-filter"} onChange={(event) => this.onChangeRegion(event)}/>
        } else {
            return null
        }
    }


    render() {
        return (
            <ContainerComponent>
                <PrimaryContentBoxComponent title={'Extraction des données'}>
                    <RadioButtonGroupComponent label={'Type d\'extraction'} data={this.getRadios()} id={'radio-all'}
                                               onChange={(event) => this.setExtractType(event)}/>
                    <DateInputComponent label={'Date de début'} id={'extractDateBegin'} value={this.today}/>
                    <DateInputComponent label={'Date de fin'} id={'extractDateEnd'} value={this.today}/>
                    {this.getCoutFields()}
                    {this.getCoutATSelect()}
                    {this.getCoutRegionSelect()}
                    <Button onClick={() => this.downloadCsv()}>Télécharger</Button>
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        );
    }
}

export default BackOfficeInstructionExtraction;
