import React from "react"

import styled from "styled-components"

import DnumComponent, { DnumProps } from "../../DnumComponent"

interface ButtonGroupHorizontalProps extends DnumProps {
    width?: string
}

export default class ButtonGroupHorizontal extends DnumComponent<ButtonGroupHorizontalProps, any> {
    constructor(props: ButtonGroupHorizontalProps) {
        super(props)
    }

    render() {
        if (this.props.style && this.props.style.width) {
            return <div className="fr-btns-group--inline">{this.props.children}</div>
        } else if (this.props.style) {
            const StyleUL = styled.ul`
                width: "500px";
            `
            return (
                <StyleUL className="fr-btns-group--inline" style={this.props.style}>
                    {this.props.children}
                </StyleUL>
            )
        } else {
            const StyleUL = styled.ul`
                width: "500px";
            `
            return <StyleUL className="fr-btns-group--inline">{this.props.children}</StyleUL>
        }
    }
}
