import DnumComponent, { DnumProps } from "../DnumComponent"

interface PagerProps extends DnumProps {
    itemByPage: number
    itemCount: number
    currentPage: number
    choosePage: (pageNumber: number) => void
    href?: string
}

export default class PagerComponent extends DnumComponent<PagerProps, any> {
    defaultHref = "#"

    constructor(props: PagerProps) {
        super(props)
        if (props.href) {
            this.defaultHref = props.href
        }
    }

    showThePager() {
        return this.props.itemCount > this.props.itemByPage
    }

    onClickFunction(pageNumber: number) {
        this.props.choosePage(pageNumber)
        return false
    }

    getFirstPageDirectLink() {
        if (this.props.currentPage == 0 || this.props.currentPage == 1) {
            return (
                <li key="pager-first-page">
                    <a className="fr-pagination__link fr-pagination__link--first" aria-disabled="true" role="link">
                        Première page
                    </a>
                </li>
            )
        } else {
            return (
                <li key="pager-first-page">
                    <a className="fr-pagination__link fr-pagination__link--first" href={this.defaultHref} onClick={() => this.onClickFunction(0)} role="link">
                        Première page
                    </a>
                </li>
            )
        }
    }

    getLastPageDirectLink() {
        const lastPage = Math.floor(this.props.itemCount / this.props.itemByPage) + (this.props.itemCount % this.props.itemByPage > 0 ? 1 : 0) - 1
        if (this.props.currentPage + 1 < lastPage) {
            return (
                <li>
                    <a className="fr-pagination__link fr-pagination__link--last" onClick={() => this.props.choosePage(lastPage)} role="link" href={this.defaultHref}>
                        Dernière page
                    </a>
                </li>
            )
        } else {
            return (
                <li>
                    <a className="fr-pagination__link fr-pagination__link--last" aria-disabled="true" role="link">
                        Dernière page
                    </a>
                </li>
            )
        }
    }

    getPreviousPageDirectLink() {
        if (this.props.currentPage > 0) {
            return (
                <li key="pager-previous-page">
                    <a
                        className="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg"
                        href={this.defaultHref}
                        onClick={() => this.props.choosePage(this.props.currentPage - 1)}
                        role="link">
                        Page précédente
                    </a>
                </li>
            )
        } else {
            return (
                <li key="pager-previous-page">
                    <a className="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg" aria-disabled="true" role="link">
                        Page précédente
                    </a>
                </li>
            )
        }
    }

    getNextPageDirectLink() {
        if ((this.props.currentPage + 1) * this.props.itemByPage < this.props.itemCount) {
            return (
                <li>
                    <a className="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg" onClick={() => this.props.choosePage(this.props.currentPage + 1)} href={this.defaultHref}>
                        Page suivante
                    </a>
                </li>
            )
        } else {
            return (
                <li>
                    <a className="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg" aria-disabled="true" role="link">
                        Page suivante
                    </a>
                </li>
            )
        }
    }

    getPageDirectLinks() {
        const links = []
        if (this.props.currentPage > 0) {
            links.push(this.getPageDirectLink(this.props.currentPage - 1))
        }
        links.push(this.getPageDirectLink(this.props.currentPage))
        if ((this.props.currentPage + 1) * this.props.itemByPage < this.props.itemCount) {
            links.push(this.getPageDirectLink(this.props.currentPage + 1))
        }
        return links
    }

    getPageDirectLink(pageNumber: number) {
        if (pageNumber == this.props.currentPage) {
            return (
                <li key={`page-direct-page-${pageNumber}`}>
                    <a className="fr-pagination__link" aria-current="page" title={`Page ${pageNumber + 1}`}>
                        {pageNumber + 1}
                    </a>
                </li>
            )
        } else {
            return (
                <li key={`page-direct-page-${pageNumber}`}>
                    <a className="fr-pagination__link" href={this.defaultHref} onClick={() => this.props.choosePage(pageNumber)} title={`Page ${pageNumber + 1}`}>
                        {pageNumber + 1}
                    </a>
                </li>
            )
        }
    }

    render() {
        if (this.showThePager()) {
            return (
                <nav role="navigation" className="fr-pagination" aria-label="Pagination">
                    <ul className="fr-pagination__list">
                        {this.getFirstPageDirectLink()}
                        {this.getPreviousPageDirectLink()}
                        {this.getPageDirectLinks()}
                        {this.getNextPageDirectLink()}
                        {this.getLastPageDirectLink()}
                    </ul>
                </nav>
            )
        } else {
            return <br />
        }
    }
}
