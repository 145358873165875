import React, {CSSProperties, ReactNode} from "react"

export interface DnumProps {
    id?: string
    style?: CSSProperties
    children?: ReactNode
}

export default abstract class DnumComponent<P extends DnumProps, S> extends React.Component<P, S> {
    protected static version = "1.3.1"

    constructor(props: P) {
        super(props)
    }

    protected getStyle() {
        return this.props.style
    }

    protected getId() {
        return this.props.id
    }

    protected generateId(prefix?: string) {
        if (prefix) {
            return prefix + Math.random().toString(36).toString()
        } else {
            return Math.random().toString(36).toString()
        }
    }

    protected initState(): { [p: string]: any } {
        const newState: { [k: string]: any } = {}
        return newState
    }

    /*
    protected updatePartialState(obj: {[p: string]: any}) {
        if (this.state!=null) {
            this.setState(Object.assign(this.state, obj))
        } else {
            this.setState(Object.assign(obj))
        }
    }*/
}
