import { Component } from "react"

import { DnumProps } from "../DnumComponent"

interface TextAreaProps extends DnumProps {
    label?: string
    text?: string
    rows?: number
    id?: string
    maxLength?: number
}

export default class TextAreaComponent extends Component<TextAreaProps, any> {
    static DEFAULT_MAX_LENGTH = 100000

    constructor(props: TextAreaProps) {
        super(props)
    }

    getLabel() {
        if (this.props.label) {
            return (
                <label className="fr-label" htmlFor="textarea">
                    {this.props.label}
                </label>
            )
        }
    }

    getTextarea(rows: number | undefined) {
        if (rows) {
            if (this.props.id) {
                return (
                    <textarea
                        rows={rows}
                        style={this.props.style}
                        maxLength={this.props.maxLength ?? TextAreaComponent.DEFAULT_MAX_LENGTH}
                        className="fr-input"
                        id={this.props.id}
                        name="textarea"
                        defaultValue={this.props.text}></textarea>
                )
            } else {
                return (
                    <textarea
                        rows={rows}
                        style={this.props.style}
                        maxLength={this.props.maxLength ?? TextAreaComponent.DEFAULT_MAX_LENGTH}
                        className="fr-input"
                        id="textarea"
                        name="textarea"
                        defaultValue={this.props.text}></textarea>
                )
            }
        } else {
            if (this.props.id) {
                return (
                    <textarea
                        style={this.props.style}
                        className="fr-input"
                        id={this.props.id}
                        maxLength={this.props.maxLength ?? TextAreaComponent.DEFAULT_MAX_LENGTH}
                        name="textarea"
                        defaultValue={this.props.text}></textarea>
                )
            } else {
                return (
                    <textarea
                        style={this.props.style}
                        className="fr-input"
                        id="textarea"
                        name="textarea"
                        maxLength={this.props.maxLength ?? TextAreaComponent.DEFAULT_MAX_LENGTH}
                        defaultValue={this.props.text}></textarea>
                )
            }
        }
    }

    render() {
        let rows = undefined
        if (this.props.rows) {
            rows = this.props.rows
        }
        return (
            <div className="fr-input-group" style={{ width: "100%" }}>
                {this.getLabel()}
                {this.getTextarea(rows)}
            </div>
        )
    }
}
