import React, { ChangeEvent, Fragment, ReactElement } from "react"
import { Tooltip } from "react-tooltip"

import FinessEtablissement from "../../../model/FinessEtablissement"
import FinessJuridique from "../../../model/FinessJuridique"

import AutoCompleteAddress from "../../../../generic/component/address/AutoCompleteAddress"
import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import RadioButtonComponent from "../../../../generic/component/radio/RadioButtonComponent"

import { Constant } from "../../../constant/Constant"
import FrontService from "../../../service/FrontService"
import HttpAccountService from "../../../service/http/HttpAccountService"
import ErrorResponse from "../../../service/http/response/ErrorResponse"
import Response from "../../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import HorizontalSpacing from "../../../shared/component/space/HorizontalSpacing"
import VerticalSpacing from "../../../shared/component/space/VerticalSpacing"
import StepperBoxComponent from "../../../shared/component/step/StepperBoxComponent"
import ErrorService from "../../../shared/service/ErrorService"
import FrontPage from "../../FrontPage"

interface FrontDetailsAccountPageState {
    view?: "contact" | "finess" | "siren" | "idcc" | "adresse" | "recap" | "finance"

    errorMessage?: string | undefined | null
    data?: ResponseContent | undefined

    finessEJ?: string | undefined

    finessEJ_codePostal?: string | null
    finessEJ_numeroVoie?: string | null
    finessEJ_complementVoie?: string | null
    finessEJ_lieuDitBoitePostale?: string | null
    finessEJ_nomVoie?: string | null
    finessEJ_typeVoie?: string | null
    finessEJ_libelleRoutage?: string | null

    useFinessAdresse: boolean | null

    newAdresse_houseNumber?: string | null
    newAdresse_street?: string | null
    newAdresse_postcode?: string | null
    newAdresse_city?: string | null

    siren?: string | undefined
    idcc?: string | undefined
    labelcc?: string | undefined
    contactNom?: string | undefined
    contactPrenom?: string | undefined
    contactEmail?: string | undefined
    contactTelephone?: string | undefined

    contactErrors?: ErrorResponse[] | null

    financeErrors?: ErrorResponse[] | null

    finance_etp: number | null
    finance_masseSalarialeBrute: number | null
    finance_masseSalarialeChargee: number | null
}

class ResponseContent {
    entiteJuridique: FinessJuridique | null = null
    etablissements: FinessEtablissement[] | null = null
}

export default class FrontCreateAccountProcessPage extends FrontPage<any, FrontDetailsAccountPageState> {
    constructor(props: any) {
        super(props)
        this.state = {
            view: "finess",
            useFinessAdresse: true,
            finance_etp: null,
            finance_masseSalarialeBrute: null,
            finance_masseSalarialeChargee: null
        }
    }

    searchFinessJuridique() {
        const input = document.querySelector("#finess-ej") as HTMLInputElement

        if (input.value == null || input.value.length == 0) {
            this.setState({ errorMessage: "Veuillez renseigné le numéro FINESS" })
        } else if (input.value.length != 9) {
            this.setState({ errorMessage: "Le format est incorrect" })
        } else {
            const response = new HttpAccountService().searchFinessJuridique(input.value.trim())

            response.then((frontResponse) => {
                const cast = new Response<ResponseContent>(frontResponse)
                if (cast.errorMessage) {
                    this.setState({ errorMessage: frontResponse.errorMessage })
                } else if (cast.hasError()) {
                    this.setState({ errorMessage: frontResponse.errors![0].errorMessage })
                } else if (cast.content?.entiteJuridique == null) {
                    this.setState({ errorMessage: "Aucune entité correspondante n'a été trouvée" })
                } else {
                    this.setState({
                        data: cast.content,
                        finessEJ_codePostal: cast.content?.entiteJuridique?.adresseCodePostal,
                        finessEJ_numeroVoie: cast.content?.entiteJuridique?.adresseNumeroVoie,
                        finessEJ_complementVoie: cast.content?.entiteJuridique?.adresseComplementVoie,
                        finessEJ_libelleRoutage: cast.content?.entiteJuridique?.adresseLibelleRoutage,
                        finessEJ_lieuDitBoitePostale: cast.content?.entiteJuridique?.adresseLieuDitBoitePostale,
                        finessEJ_nomVoie: cast.content?.entiteJuridique?.adresseNomVoie,
                        finessEJ_typeVoie: cast.content?.entiteJuridique?.adresseTypeVoie,
                        finessEJ: input.value,
                        view: "siren",
                        errorMessage: null
                    })
                }
            })
        }
    }

    getEtablissements(etat: string) {
        const etablissements: any[] = []
        this.state.data?.etablissements?.forEach((etablissement, index) => {
            if (etablissement.etat == etat) {
                etablissements.push(<li key={"etablissement-" + index}>{etablissement.raisonSociale}</li>)
            }
        })
        return etablissements
    }

    goBackToFiness() {
        this.setState({ view: "finess", errorMessage: null })
    }

    goBackToFinance() {
        this.setState({ view: "finance", errorMessage: null })
    }

    goBackToSiren() {
        this.setState({ view: "siren", errorMessage: null })
    }

    goBackToIdcc() {
        this.setState({ view: "idcc", errorMessage: null })
    }

    goBackToContact() {
        this.setState({ view: "contact", errorMessage: null, contactErrors: null })
    }

    validSiren() {
        const input = document.querySelector("#siren-ej") as HTMLInputElement

        const response = new HttpAccountService().validSiren(this.state.finessEJ!, input.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.success) {
                this.setState({ view: "idcc", siren: input.value, errorMessage: null })
            } else {
                this.setState({ errorMessage: "Le numéro SIREN n'est pas correct" })
            }
        })
    }

    validIdcc() {
        const input = document.querySelector("#idcc-ej") as HTMLInputElement

        const response = new HttpAccountService().validIdcc(input.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.success) {
                this.setState({ view: "finance", idcc: input.value, labelcc: cast.content as string })
            } else {
                this.setState({ errorMessage: "Cet identifiant de convention collective n'est pas valide" })
            }
        })
    }

    validFinance() {
        // ok
        const etp = document.querySelector("#etp") as HTMLInputElement
        const masseSalarialeBrute = document.querySelector("#masse-salariale-brute") as HTMLInputElement
        const masseSalarialeChargee = document.querySelector("#masse-salariale-chargee") as HTMLInputElement

        const response = new HttpAccountService().validFinance(etp.value, masseSalarialeBrute.value, masseSalarialeChargee.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errors == null || cast.errors.length == 0) {
                this.setState({
                    view: "contact",
                    finance_etp: Number.parseFloat(etp.value),
                    finance_masseSalarialeBrute: Number.parseFloat(masseSalarialeBrute.value),
                    finance_masseSalarialeChargee: Number.parseFloat(masseSalarialeChargee.value)
                })
            } else {
                this.setState({ financeErrors: cast.errors })
            }
        })
    }

    validContact() {
        const inputNom = document.querySelector("#nom-inscription") as HTMLInputElement
        const inputPrenom = document.querySelector("#prenom-inscription") as HTMLInputElement
        const inputEmail = document.querySelector("#email-secondaire-inscription") as HTMLInputElement
        const inputTelephone = document.querySelector("#telephone-inscription") as HTMLInputElement

        const response = new HttpAccountService().validContact(inputNom.value, inputPrenom.value, inputEmail.value, inputTelephone.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errors == null || cast.errors.length == 0) {
                this.setState({ view: "adresse", contactNom: inputNom.value, contactPrenom: inputPrenom.value, contactEmail: inputEmail.value, contactTelephone: inputTelephone.value })
            } else {
                this.setState({ contactErrors: cast.errors })
            }
        })
    }

    getAdresseView() {
        let checked = { checked: false }
        if (this.state.useFinessAdresse) {
            checked = { checked: true }
        }
        return (
            <Fragment>
                <RowComponent>
                    <ColumnComponent size={1}></ColumnComponent>
                    <ColumnComponent size={10}>
                        <RadioButtonComponent
                            id={"adresse-finess-radio"}
                            onChange={(event) => this.onChangeTypeAdresse(event)}
                            label={"J'utilise cette adresse :"}
                            name="radio-adresse"
                            value="adresse-finess-radio"
                            {...checked}
                        />
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={2}></ColumnComponent>
                    <ColumnComponent size={8}>
                        <RowComponent>
                            <ColumnComponent size={2} />
                            <ColumnComponent size={8}>
                                <br />
                                {this.state.finessEJ_numeroVoie!} {this.state.finessEJ_typeVoie!} {this.state.finessEJ_nomVoie!}
                                <br />
                                {this.state.finessEJ_codePostal!} {this.state.finessEJ_libelleRoutage!}
                                <br />
                                {this.state.finessEJ_lieuDitBoitePostale!}
                                <br />
                                <br />
                                <br />
                            </ColumnComponent>
                            <ColumnComponent size={2} />
                        </RowComponent>
                        <VerticalSpacing height="25px" />
                    </ColumnComponent>
                    <ColumnComponent size={2}></ColumnComponent>
                </RowComponent>
                {this.getNewAdresseView()}
                <br />
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <div>
                        <Button onClick={() => this.goBackToContact()}>Pr&eacute;c&eacute;dent</Button>
                        <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                        <Button onClick={() => this.validAdresse()}>Suivant</Button>
                    </div>
                </div>
            </Fragment>
        )
    }

    getNewAdresseView() {
        let checked = { checked: true }
        if (this.state.useFinessAdresse) {
            checked = { checked: false }
        }
        return (
            <Fragment>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={10}>
                        <RadioButtonComponent
                            id={"new-adresse-radio"}
                            name="radio-adresse"
                            value="new-adresse-radio"
                            label={"Je définis une autre adresse"}
                            onChange={(event) => this.onChangeTypeAdresse(event)}
                            {...checked}
                        />
                        <br />
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <AutoCompleteAddress label={""} id={"new-adresse"} onSelectItemHandler={(item: any) => this.onAdresseSelected(item)} />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
            </Fragment>
        )
    }

    onChangeTypeAdresse(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "adresse-finess-radio") {
            const radio = document.querySelector("input[value='adresse-finess-radio']") as HTMLInputElement
            this.setState({ useFinessAdresse: radio.checked })
        } else if (event.target.value === "new-adresse-radio") {
            const radio = document.querySelector("input[value='new-adresse-radio']") as HTMLInputElement
            this.setState({ useFinessAdresse: !radio.checked })
        }
    }

    onAdresseSelected(item: any) {
        const inputElement: HTMLInputElement = document.querySelector("#new-adresse") as HTMLInputElement
        inputElement.value = item.label

        this.setState({
            newAdresse_city: item.city,
            newAdresse_houseNumber: item.housenumber,
            newAdresse_postcode: item.postcode,
            newAdresse_street: item.street
        })
    }

    validAdresseFromFiness() {
        const response = new HttpAccountService().validAdresse(true, null, null, null, null)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)
            if (cast.errors == null || cast.errors.length == 0) {
                this.setState({ view: "recap", errorMessage: null })
            } else {
                this.setState({ contactErrors: cast.errors })
            }
        })
    }

    validAdresse() {
        const radioAdresseFiness = document.querySelector("input[value='adresse-finess-radio']") as HTMLInputElement
        const radioNewAdresse = document.querySelector("input[value='new-adresse-radio']") as HTMLInputElement

        if (radioAdresseFiness.checked) {
            this.validAdresseFromFiness()
        } else if (radioNewAdresse.checked) {
            this.validNewAdresse()
        } else {
            this.setState({ errorMessage: "Vous devez sélectionner l'adresse existante ou en définir une nouvelle" })
        }
    }

    validNewAdresse() {
        const response = new HttpAccountService().validAdresse(false, this.state.newAdresse_houseNumber!, this.state.newAdresse_street!, this.state.newAdresse_postcode!, this.state.newAdresse_city!)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)
            if (cast.errors == null || cast.errors.length == 0) {
                this.setState({ view: "recap", errorMessage: null })
            } else {
                this.setState({ errorMessage: cast.errors[0].errorMessage })
            }
        })
    }

    getRecapAdresse() {
        if (this.state && this.state.useFinessAdresse) {
            if (this.state.finessEJ_numeroVoie != null) {
                return (
                    this.state.finessEJ_numeroVoie +
                    " " +
                    this.state.finessEJ_typeVoie +
                    " " +
                    this.state.finessEJ_nomVoie +
                    ", " +
                    this.state.finessEJ_codePostal +
                    " " +
                    this.state.finessEJ_libelleRoutage
                )
            } else {
                return this.state.finessEJ_typeVoie + " " + this.state.finessEJ_nomVoie + ", " + this.state.finessEJ_codePostal + " " + this.state.finessEJ_libelleRoutage
            }
        } else {
            if (this.state.newAdresse_houseNumber != null) {
                return this.state.newAdresse_houseNumber + " " + this.state.newAdresse_street + ", " + this.state.newAdresse_postcode + " " + this.state.newAdresse_city
            } else {
                return this.state.newAdresse_street + ", " + this.state.newAdresse_postcode + " " + this.state.newAdresse_city
            }
        }
    }

    cancel() {
        this.setState({
            view: "finess",
            ...{}
        })
    }

    saveAccountInformations() {
        const response = new HttpAccountService().saveInformations()

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)
            if (cast.success) {
                FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_ACCOUNT)
            } else {
                this.setState({ errorMessage: "Une erreur s'est produite" })
            }
        })
    }

    // etat = finess => siren => idcc => finance => contact => adresse => recap
    getStepperData() {
        const data: ReactElement[] = []
        data.push(<span>Association</span>) // finess + siren
        data.push(<span>IDCC</span>) // idcc
        data.push(<span>Financement</span>) // finance
        data.push(<span>Contact</span>) // contact
        data.push(<span>Adresse</span>) // adresse
        return data
    }

    getStepper() {
        if (this.state && this.state.view) {
            if (this.state.view == "adresse") {
                return <StepperBoxComponent data={this.getStepperData()} step={4} />
            } else if (this.state.view == "idcc") {
                return <StepperBoxComponent data={this.getStepperData()} step={1} />
            } else if (this.state.view == "finess" || this.state.view == "siren") {
                return <StepperBoxComponent data={this.getStepperData()} step={0} />
            } else if (this.state.view == "finance") {
                return <StepperBoxComponent data={this.getStepperData()} step={2} />
            } else if (this.state.view == "contact") {
                return <StepperBoxComponent data={this.getStepperData()} step={3} />
            }
        } else {
            return <StepperBoxComponent data={this.getStepperData()} step={0} />
        }
    }

    getContent() {
        // récapitulatif
        if (this.state && this.state.view && this.state.view == "recap") {
            return (
                <Fragment>
                    <br />
                    <p>Votre déclaration : </p>
                    <br />
                    <ul>
                        <li>{"Entité juridique : " + this.state.data?.entiteJuridique?.raisonSociale + " (" + this.state.finessEJ + ")"}</li>
                        <li>{"SIREN : " + this.state.siren}</li>
                        <li>{"Convention collective : " + this.state.labelcc + " (" + this.state.idcc + ")"}</li>
                        <li>{"Financement : données validées"}</li>
                        <li>{"Contact : " + this.state.contactPrenom + " " + this.state.contactNom + " - " + this.state.contactTelephone + " - " + this.state.contactEmail}</li>
                        <li>{"Adresse : " + this.getRecapAdresse()}</li>
                    </ul>
                    <br />
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <div>
                            <Button onClick={() => this.cancel()}>Annuler</Button>
                            <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                            <Button onClick={() => this.saveAccountInformations()}>Je confirme</Button>
                        </div>
                    </div>
                </Fragment>
            )

            // adresse
        } else if (this.state && this.state.view && this.state.view == "adresse") {
            return <Fragment key="adresse">{this.getAdresseView()}</Fragment>

            // contact
        } else if (this.state && this.state.view && this.state.view == "contact") {
            const errorService = new ErrorService()

            return (
                <Fragment key="contact">
                    <TextInputComponent
                        label="Votre prénom"
                        value={this.state.contactPrenom}
                        id="prenom-inscription"
                        error={errorService.isErrorKeyPresent("prenom", this.state?.contactErrors)}
                        errorMessage={errorService.getErrorMessage("prenom", this.state?.contactErrors)}
                    />
                    <TextInputComponent
                        label="Votre nom"
                        value={this.state.contactNom}
                        id="nom-inscription"
                        error={errorService.isErrorKeyPresent("nom", this.state?.contactErrors)}
                        errorMessage={errorService.getErrorMessage("nom", this.state?.contactErrors)}
                    />
                    <TextInputComponent
                        label="Courriel de contact"
                        value={this.state.contactEmail}
                        id="email-secondaire-inscription"
                        error={errorService.isErrorKeyPresent("email-secondaire", this.state?.contactErrors)}
                        errorMessage={errorService.getErrorMessage("email-secondaire", this.state?.contactErrors)}
                    />
                    <TextInputComponent
                        label="Téléphone"
                        value={this.state.contactTelephone}
                        id="telephone-inscription"
                        error={errorService.isErrorKeyPresent("telephone", this.state?.contactErrors)}
                        errorMessage={errorService.getErrorMessage("telephone", this.state?.contactErrors)}
                    />

                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <div>
                            <Button onClick={() => this.goBackToFinance()}>Pr&eacute;c&eacute;dent</Button>
                            <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                            <Button onClick={() => this.validContact()}>Suivant</Button>
                        </div>
                    </div>
                </Fragment>
            )

            // finance
        } else if (this.state && this.state.view && this.state.view == "finance") {
            const errorService = new ErrorService()

            return (
                <Fragment>
                    <TextInputComponent
                        label={"ETP relevant des ESSMS portés (tous ETP)"}
                        id={"etp"}
                        value={this.state?.finance_etp?.toString() ?? ""}
                        number
                        error={errorService.isErrorKeyPresent("etp", this.state?.financeErrors)}
                        errorMessage={errorService.getErrorMessage("etp", this.state?.financeErrors)}
                    />
                    <TextInputComponent
                        label={"Masse salariale brute (en milliers d'euros)"}
                        id={"masse-salariale-brute"}
                        value={this.state?.finance_masseSalarialeBrute?.toString()}
                        number
                        error={errorService.isErrorKeyPresent("masse-salariale-brute", this.state?.financeErrors)}
                        errorMessage={errorService.getErrorMessage("masse-salariale-brute", this.state?.financeErrors)}
                    />
                    <TextInputComponent
                        label={"Masse salariale chargée (en milliers d'euros)"}
                        id={"masse-salariale-chargee"}
                        value={this.state?.finance_masseSalarialeChargee?.toString()}
                        number
                        error={errorService.isErrorKeyPresent("masse-salariale-chargee", this.state?.financeErrors)}
                        errorMessage={errorService.getErrorMessage("masse-salariale-chargee", this.state?.financeErrors)}
                    />

                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <div>
                            <Button onClick={() => this.goBackToIdcc()}>Pr&eacute;c&eacute;dent</Button>
                            <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                            <Button onClick={() => this.validFinance()}>Suivant</Button>
                        </div>
                    </div>
                </Fragment>
            )

            // identifiant convention collective
        } else if (this.state && this.state.view && this.state.view == "idcc") {
            return (
                <Fragment key="idcc">
                    <TextInputComponent
                        label={
                            <Fragment>
                                Veuillez renseigner votre identifiant de convention collective&nbsp;
                                <span
                                    data-delay-hide={"1500"}
                                    data-tip={
                                        "L'<a href='https://travail-emploi.gouv.fr/dialogue-social/negociation-collective/article/conventions-collectives-nomenclatures' target='_blank'>identifiant de la Convention Collective</a> (IDCC) est un code unique attribué par le Ministère du travail.<br/>Seuls les employeurs relevant de conventions collectives concernées par l'article L314-6 du CASF peuvent créer un compte."
                                    }
                                    data-html={true}
                                    className={Constant.ICON.INFO_LINE}
                                    aria-hidden={true}></span>
                            </Fragment>
                        }
                        id="idcc-ej"
                        value={this.state?.idcc}
                        errorMessage={this.state.errorMessage!}
                        error={this.state.errorMessage != null}
                    />

                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <div>
                            <Button onClick={() => this.goBackToSiren()}>Pr&eacute;c&eacute;dent</Button>
                            <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                            <Button onClick={() => this.validIdcc()}>Suivant</Button>
                        </div>
                    </div>
                    <Tooltip clickable={true} />
                </Fragment>
            )

            // valide les détails FINESS, renseigner le SIREN
        } else if (this.state && this.state.view && this.state.view == "siren") {
            let countActuel = 0
            let countObsolete = 0
            this.state.data!.etablissements?.map((etablissement) => {
                if (etablissement.etat == "ACTUEL") {
                    countActuel++
                } else {
                    countObsolete++
                }
            })

            if (countActuel > 0) {
                if (this.state.data?.entiteJuridique?.siren == null) {
                    return (
                        <Fragment key={this.state.view}>
                            <span>Vous déclarez représenter l'entité juridique suivante :&nbsp;</span>
                            {this.state.data!.entiteJuridique!.raisonSociale}.<br />
                            Cette entité exerce des activités dans {countActuel == 1 ? "un" : countActuel} {countActuel > 1 ? "établissements" : "établissement"} : <br />
                            <ul>{this.getEtablissements("ACTUEL")}</ul>
                            Malheureusement nous ne pouvons donné suite à la création de votre compte car votre numéro SIREN n'est pas renseigné dans le référentiel FINESS.
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment key={this.state.view}>
                            <span>Vous déclarez représenter l'entité juridique suivante :&nbsp;</span>
                            {this.state.data!.entiteJuridique!.raisonSociale}.<br />
                            Cette entité exerce des activités dans {countActuel == 1 ? "un" : countActuel} {countActuel > 1 ? "établissements" : "établissement"} : <br />
                            <ul>{this.getEtablissements("ACTUEL")}</ul>
                            <TextInputComponent
                                label="Veuillez renseigner le numéro SIREN de l'entité juridique"
                                id="siren-ej"
                                value={this.state?.siren}
                                error={this.state.errorMessage != null}
                                errorMessage={this.state.errorMessage!}
                            />
                            <div style={{ textAlign: "center", marginTop: "30px" }}>
                                <div>
                                    <Button onClick={() => this.goBackToFiness()}>Pr&eacute;c&eacute;dent</Button>
                                    <HorizontalSpacing width={"30px"}>&nbsp;</HorizontalSpacing>
                                    <Button onClick={() => this.validSiren()}>Suivant</Button>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            } else if (countObsolete > 0) {
                return (
                    <Fragment key={this.state.view}>
                        <span>Vous déclarez représenter l'entité juridique suivante :&nbsp;</span>
                        {this.state.data!.entiteJuridique!.raisonSociale}.<br />
                        Cette entité compte {countObsolete} {countObsolete > 1 ? "établissements fermés" : "établissement fermé"}.<br />
                        <ul>{this.getEtablissements("OBSOLETE")}</ul>
                        Vous ne pouvez donc pas faire de demande d'agrément.
                    </Fragment>
                )
            } else {
                return (
                    <Fragment key={this.state.view}>
                        <span>Vous déclarez représenter l'entité juridique suivante :&nbsp;</span>
                        {this.state.data!.entiteJuridique!.raisonSociale}.<br />
                        Selon nos informations cette entité ne possède pas d'établissement en exercice.
                    </Fragment>
                )
            }

            // saisie numéro FINESS
        } else {
            return (
                <Fragment key="finess">
                    <RowComponent>
                        <ColumnComponent size={12}>
                            <TextInputComponent
                                label={
                                    <>
                                        Numéro FINESS de votre entité juridique EJ&nbsp;
                                        <span
                                            data-delay-hide={"1500"}
                                            data-tip={
                                                "Pour trouver le numéro EJ de votre entité juridique, se rendre sur <a href='https://finess.sante.gouv.fr' target='_blank'>https://finess.sante.gouv.fr</a>"
                                            }
                                            data-html={true}
                                            className={Constant.ICON.INFO_LINE}
                                            aria-hidden={true}></span>
                                    </>
                                }
                                value={this.state?.finessEJ}
                                id="finess-ej"
                                error={this.state && this.state.errorMessage != null}
                                errorMessage={this.state && this.state.errorMessage!}
                            />
                            <div style={{ textAlign: "center" }}>
                                <Button onClick={() => this.searchFinessJuridique()}>Suivant</Button>
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"20px"} />
                    <Tooltip clickable={true} />
                </Fragment>
            )
        }
    }

    render() {
        return (
            <PrimaryContentBoxComponent title={`Mon compte : informations nécessaires`}>
                {this.getStepper()}
                <RowComponent>
                    <ColumnComponent size={1}></ColumnComponent>
                    <ColumnComponent size={10}>{this.getContent()}</ColumnComponent>
                    <ColumnComponent size={1}></ColumnComponent>
                </RowComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
