import React from "react"

import DnumComponent, { DnumProps } from "./DnumComponent"

interface BadgeChooseColorsComponentProps extends DnumProps {
    color: string
    backgroundColor: string
    label: string
    small?: any
    noIcon?: true | null
    oneLine?: null
}

export default class BadgeChooseColorsComponent extends DnumComponent<BadgeChooseColorsComponentProps, any> {
    protected readonly className: string = "fr-badge"

    constructor(props: BadgeChooseColorsComponentProps) {
        super(props)

        this.className = "fr-badge"

        if (this.props.small) {
            this.className += " fr-badge--sm"
        }

        if (this.props.noIcon) {
            this.className += " fr-badge--no-icon"
        }
    }

    render() {
        if (this.props.oneLine) {
            return (
                <p className={this.className} style={{ backgroundColor: this.props.backgroundColor }}>
                    <span className="fr-ellipsis" style={{ color: this.props.color }}>
                        {this.props.label}
                    </span>
                </p>
            )
        } else {
            return (
                <p className={this.className} style={{ backgroundColor: this.props.backgroundColor, color: this.props.color }}>
                    {this.props.label}
                </p>
            )
        }
    }
}
