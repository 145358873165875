import { ReactElement } from "react"

import AccountData from "../../model/AccountData"
import FinessEtablissement from "../../model/FinessEtablissement"

import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent"
import Button from "../../../generic/component/button/Button"
import CheckboxComponent from "../../../generic/component/checkbox/CheckboxComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import BackOfficePage from "../../back/BackOfficePage"
import { Constant } from "../../constant/Constant"
import FrontService from "../../service/FrontService"
import HttpAccountService from "../../service/http/HttpAccountService"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import FrontDemandeAgrementForm from "./FrontDemandeAgrementForm"

interface FrontDemandeAgrementSelectEtablissementsPageState {
    etablissements: FinessEtablissement[] | null
    error: boolean
}

export default class FrontDemandeAgrementSelectEtablissementsPage extends BackOfficePage<any, FrontDemandeAgrementSelectEtablissementsPageState> {
    componentDidMount() {
        const response = new HttpAccountService().getInformations()

        response.then((response) => {
            const cast = new Response<AccountData>(response)
            if (cast.success) {
                this.setState({
                    etablissements: cast.content!.etablissements,
                    error: false
                })
            }
        })
    }

    getEtablissements(): ReactElement[] {
        const etablissements: ReactElement[] = []
        if (this.state && this.state.etablissements) {
            this.state.etablissements.forEach((etablissement: FinessEtablissement, index: number) => {
                if (etablissement.cpomValue === Constant.STATUS.CPOM.NON || etablissement.cpomValue === "SOUS_CPOM_CD") {
                    etablissements.push(
                        <div key={"cb-etablissement-" + index}>
                            <CheckboxComponent
                                label={etablissement.raisonSociale + " / " + (etablissement.siret != null ? etablissement.siret : "SIRET non renseigné")}
                                id={etablissement.id!.toString()}
                                checked={false}
                            />
                        </div>
                    )
                }
            })
        }
        return etablissements
    }

    onEtablissementsSelected() {
        // recupération des etablissements
        const inputs = document.querySelectorAll<HTMLInputElement>("div.fr-checkbox-group > input")
        const list: string[] = []
        inputs.forEach((input: HTMLInputElement, index) => {
            if (input.checked) {
                list.push(input.name)
            }
        })

        if (list.length > 0) {
            //new LocalStorageService().setJsonItem("demandeAgrementEtablissementList", list)
            const form = new FrontDemandeAgrementForm()
            form.etablissementIds = list
            new HttpAccountService().saveDemande(form).then((response) => {
                if (response.errors == null || response.errors.length == 0) {
                    const params = new Map()
                    params.set(Constant.KEY.FRONT.EDITION_PARAM, true)
                    params.set(Constant.KEY.FRONT.DEMANDE_ID_PARAM, response.content?.id)
                    FrontService.getInstance().switchPage(Constant.DOM.FRONT.DEMANDE_AGREMENT_FORM_PAGE, params)
                }
            })
        } else {
            this.setState({
                error: true
            })
        }
    }

    getError(): ReactElement | undefined {
        if (this.state && this.state.error) {
            return <AlertErrorComponent description={"Un établissement est requis"} />
        }
    }

    clickCancel() {
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_ACCOUNT)
    }

    render(): JSX.Element {
        return (
            <PrimaryContentBoxComponent title="Nouvelle demande d'agrément">
                <RowComponent>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <p>Sélectionnez le(s) établissement(s) concerné(s) :</p>
                        {this.getError()}
                        <br />
                        {this.getEtablissements()}
                        <br />
                        <br />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={1}>
                        <Button onClick={() => this.onEtablissementsSelected()}>Valider</Button>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={1}>
                        <Button secondary onClick={() => this.clickCancel()}>
                            Annuler
                        </Button>
                    </ColumnComponent>
                    <ColumnComponent size={7} />
                </RowComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
