import React from "react"

import DnumComponent, { DnumProps } from "../../DnumComponent"

interface ColumnProps extends DnumProps {
    xsmall?: null
    small?: null
    medium?: null
    large?: null
    xlarge?: null
    size?: number
    middle?: any
}

export default class ColumnComponent extends DnumComponent<ColumnProps, any> {
    private className = "fr-col"

    constructor(props: ColumnProps) {
        super(props)

        if (this.props.xsmall) {
            this.className += "-xs"
        } else if (this.props.small) {
            this.className += "-sm"
        } else if (this.props.medium) {
            this.className += "-md"
        } else if (this.props.large) {
            this.className += "-lg"
        } else if (this.props.xlarge) {
            this.className += "-xl"
        }

        if (this.props.size) {
            this.className += `-${this.props.size}`
        }
    }

    render() {
        return (
            <div className={this.className} id={this.props.id} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}
