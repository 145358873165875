import AccountData from "../../../model/AccountData"

import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import FrontPage from "../../FrontPage"
import FrontDemandeAgrementForm from "../FrontDemandeAgrementForm"
import FrontConsultationDemandeAgrementComponent from "./FrontConsultationDemandeAgrementComponent"
import {DnumProps} from "../../../../generic/component/DnumComponent";

interface FrontConsultationDemandeAgrementFormProps extends DnumProps {
    demandeInProgress: FrontDemandeAgrementForm | undefined
    informations: AccountData | undefined
}

export default class FrontConsultationDemandeAgrementPage extends FrontPage<FrontConsultationDemandeAgrementFormProps, any> {
    constructor(props: FrontConsultationDemandeAgrementFormProps) {
        super(props)
    }

    render(): JSX.Element {
        return (
            <PrimaryContentBoxComponent title="Mon compte > Consultation">
                <RowComponent>
                    <ColumnComponent size={2}></ColumnComponent>
                    <ColumnComponent size={8}>
                        <FrontConsultationDemandeAgrementComponent demandeAgrement={this.props.demandeInProgress} informations={this.props.informations} />
                    </ColumnComponent>
                </RowComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
