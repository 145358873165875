import BackAccount from "../../model/BackAccount"

import HttpBackAccountService from "../../service/http/HttpBackAccountService"
import Response from "../../service/http/response/Response"
import BackOfficeAbstractUtilisateurManagePage from "./BackOfficeAbstractUtilisateurManagePage"
import PagerResponse from "../../shared/dto/PagerResponse";

export default class BackOfficeUtilisateurAssociationPage extends BackOfficeAbstractUtilisateurManagePage {
    getTitle(): string {
        return "Comptes associés aux entreprises & associations"
    }

    choosePage(page: number) {
        const response = new HttpBackAccountService().getListAssociationAccount(page, 20, this.getFilterValue())
        response.then((response) => {
            const cast = new Response<PagerResponse<BackAccount>>(response)
            if (cast.success) {
                this.setState({
                    accounts: cast.content!.list!,
                    count: cast.content!.count!,
                    currentPage: page
                })
            }
        })
    }

    getActions(account: BackAccount): JSX.Element {
        return this.getStatutAction(account)
    }
}
