import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeTypologieAccordFormComponent from "./BackOfficeTypologieAccordFormComponent"
import ListTypologieAccordComponent from "./BackOfficeTypologieAccordListComponent"

export default class BackOfficeTypologieAccordPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Typologies d'accord"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeTypologieAccordFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <ListTypologieAccordComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPOLOGIE_ACCORD
    }
}
