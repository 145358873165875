import React, { ChangeEvent, Fragment, ReactElement } from "react"

import Cna from "../../model/Cna"
import IdentifiantConventionCollective from "../../model/IdentifiantConventionCollective"
import TypeFinanceur from "../../model/TypeFinanceur"
import TypologieAccord from "../../model/TypologieAccord"

import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import AlertWarningComponent from "../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../generic/component/button/Button"
import DateInputComponent from "../../../generic/component/form/input/DateInputComponent"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import { PageProps } from "../../../generic/component/grid/page/PageComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../generic/component/select/SelectOptionData"
import TableCellComponent from "../../../generic/component/table/TableCellComponent"
import TableContainerComponent from "../../../generic/component/table/TableContainerComponent"
import TableHeaderComponent from "../../../generic/component/table/TableHeaderComponent"

import { Constant } from "../../constant/Constant"
import RoleBackOffice from "../../constant/role/RoleBackOffice"
import BackOfficeService from "../../service/BackOfficeService"
import HttpBackCnaService from "../../service/http/HttpBackCnaService"
import HttpBackDemande from "../../service/http/HttpBackDemande"
import HttpReferentielService from "../../service/http/HttpReferentielService"
import Response from "../../service/http/response/Response"
import UiService from "../../service/ui/UiService"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import PagerResponse from "../../shared/dto/PagerResponse"
import BackOfficePage from "../BackOfficePage"
import BackOfficeDemandeAgrementForm from "../common/BackOfficeDemandeAgrementForm"
import LazyWrapperComponent from "../../shared/component/LazyWrapperComponent"
import BackOfficeModalShowDemandeComponent from "../common/BackOfficeModalShowDemandeComponent"
import BackOfficeModalEditCommentaireAcceptationComponent from "./BackOfficeModalEditCommentaireAcceptationComponent"
import BackOfficeModalEditRaisonRefusComponent from "./BackOfficeModalEditRaisonRefusComponent"

interface BackOfficeListDemandeCnaPageState {
    demandes: BackOfficeDemandeAgrementForm[] | undefined
    count: number
    currentPage: number
    typologies: TypologieAccord[] | undefined
    typologieId: number | undefined | null
    conventionCollectives: IdentifiantConventionCollective[] | undefined
    conventionCollectiveCode: string | undefined | null
    etat: string | undefined | null
    type: string | undefined | null
    entiteJuridiqueFilter: string | undefined | null
    codePostal: string | undefined
    typesFinanceur: TypeFinanceur[] | undefined
    typeFinanceurId: string | undefined | null
    showValidationButton: boolean
    forceRefresh: number
    confirmValidation: boolean
    dateEffectiveDefined: boolean
}

interface BackOfficeListDemandeCnaPageProps extends PageProps {
    etat: string
    cna: Cna
    goBack: () => void
    showValidationForUser?: boolean
    showDateEffectiveForUser?: boolean
}

export default class BackOfficeListDemandeCnaPage extends BackOfficePage<BackOfficeListDemandeCnaPageProps, BackOfficeListDemandeCnaPageState> {

    readonly ITEM_BY_PAGE = 100

    map: Map<string, string> = new Map()

    constructor(props: BackOfficeListDemandeCnaPageProps) {
        super(props)
        this.state = {
            confirmValidation: false,
            forceRefresh: 0,
            demandes: undefined,
            count: 0,
            currentPage: 0,
            typologies: undefined,
            typologieId: undefined,
            type: undefined,
            conventionCollectives: undefined,
            conventionCollectiveCode: undefined,
            etat: this.props.etat,
            entiteJuridiqueFilter: undefined,
            codePostal: undefined,
            typesFinanceur: undefined,
            typeFinanceurId: undefined,
            showValidationButton: false,
            dateEffectiveDefined: false
        }
    }

    initData() {
        this.map.set(Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA, "#eea042")
        this.map.set(Constant.STATUS.AVIS_CNA.DEFAVORABLE, "#f7512d")
        this.map.set(Constant.STATUS.AVIS_CNA.FAVORABLE, "#60f38f")
        this.map.set("", "inherit")
    }

    componentDidMount() {
        this.initData()
        this.choosePage(0)
        new HttpReferentielService()
            .useTypologieAccordService()
            .getAll<TypologieAccord>()
            .then((response) => {
                this.setState({ typologies: response.content })
            })
        new HttpReferentielService()
            .useIdentifiantConventionCollectiveService()
            .getAll<IdentifiantConventionCollective>()
            .then((response) => {
                this.setState({ conventionCollectives: response.content })
            })
        new HttpReferentielService()
            .useTypeFinanceurService()
            .getAll<TypeFinanceur>()
            .then((response) => {
                this.setState({ typesFinanceur: response.content })
            })
    }

    getFiltre(): ReactElement {
        return (
            <Fragment>
                <ContainerComponent fluid>
                    <RowComponent key={"line-filter-0"} style={{ width: "100%" }}>
                        <ColumnComponent size={2}>{this.getSelectType()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={3}>{this.getSelectTypeFinanceur()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={5}>{this.getSelectConventionCollective()}</ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                    <RowComponent key={"line-filter-1"} style={{ width: "100%" }}>
                        <ColumnComponent size={2}>
                            <TextInputComponent label="Code postal EJ" placeholder="2 car. mini." id="code-postal-ej" onKeyUpHandler={() => this.getCodePostalEventHandler()} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={3}>
                            <TextInputComponent
                                placeholder="3 car. mini."
                                style={{ width: "100%" }}
                                id={"entite-juridique-filter"}
                                onKeyUpHandler={() => this.getEntiteJuridiqueEventHandler()}
                                label="Raison sociale EJ"
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={5}>{this.getSelectTypologie()}</ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                    <RowComponent key={"line-filter-2"} style={{ width: "100%" }}></RowComponent>
                </ContainerComponent>
            </Fragment>
        )
    }

    getCodePostalEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 1) {
            this.choosePage(0)
        }
    }

    getEntiteJuridiqueEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 2) {
            this.choosePage(0)
        }
    }

    getSelectType() {
        const types: SelectOptionData[] = []
        types.push(new SelectOptionData("", "Type", false))
        types.push(new SelectOptionData("ACCORD", "Accord", false))
        types.push(new SelectOptionData("DUE", "DUE", false))
        return <SelectComponent label={"Type"} data={types} style={{ width: "100%" }} id={"select-type-filter"} onChange={(event) => this.onChangeType(event)} />
    }

    getSelectTypeFinanceur() {
        const types: SelectOptionData[] = []
        if (this.state && this.state.typesFinanceur) {
            types.push(new SelectOptionData("-1", "Type financeur", false))
            this.state.typesFinanceur.forEach((type) => {
                types.push(new SelectOptionData(type.id!.toString(), type.nom!, false))
            })
        }
        return <SelectComponent label={"Financeur"} data={types} style={{ width: "100%" }} id={"select-type-financeur-filter"} onChange={(event) => this.onChangeTypeFinanceur(event)} />
    }

    onChangeType(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeTypeFinanceur(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeTypologie(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getSelectTypologie() {
        if (this.state && this.state.typologies) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "Thématique", false))
            this.state.typologies.forEach((typologie) => {
                const option = new SelectOptionData(typologie.id!.toString(), typologie.libelle!, false)
                list.push(option)
            })
            return <SelectComponent id={"select-typologie-filter"} onChange={(event) => this.onChangeTypologie(event)} style={{ width: "100%" }} label={"Thématique"} data={list} />
        }
        return <span></span>
    }

    getSelectConventionCollective() {
        if (this.state && this.state.conventionCollectives) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "IDCC", false))
            this.state.conventionCollectives.forEach((idcc) => {
                if (idcc.active) {
                    const option = new SelectOptionData(idcc.code!.toString(), idcc.label!, false)
                    list.push(option)
                }
            })
            return <SelectComponent label={"IDCC"} data={list} onChange={(event) => this.onChangeConventionCollective(event)} style={{ width: "100%" }} id={"select-idcc-filter"} />
        }
        return <span></span>
    }

    onChangeConventionCollective(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getEtablissements(demande: BackOfficeDemandeAgrementForm) {
        if ((demande.etablissements?.length ?? 0) > 1) {
            return <small>{demande.etablissements?.length} établissements concernés</small>
        } else {
            return <small>{demande.etablissements?.length} établissement concerné</small>
        }
    }

    isMembreCna() {
        const account = BackOfficeService.getInstance().getBackAccount
        return account?.type === RoleBackOffice.MEMBRE_CNA
    }

    getAvisCna(demande: BackOfficeDemandeAgrementForm) {
        if (this.props.cna.dateValidation == null && !this.isMembreCna()) {
            const options: SelectOptionData[] = []
            options.push(new SelectOptionData("", "- avis CNA -", null == demande.avisCna))
            options.push(new SelectOptionData(Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA, "Renvoi prochaine CNA", Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA === demande.avisCna))
            options.push(new SelectOptionData(Constant.STATUS.AVIS_CNA.DEFAVORABLE, "Défavorable", Constant.STATUS.AVIS_CNA.FAVORABLE === demande.avisCna))
            options.push(new SelectOptionData(Constant.STATUS.AVIS_CNA.FAVORABLE, "Favorable", Constant.STATUS.AVIS_CNA.DEFAVORABLE === demande.avisCna))

            return (
                <div id={"avis-cna-container-" + demande.id} style={this.getAvisCnaStyle(demande.avisCna ?? "")}>
                    <SelectComponent id={"select-avis-cna-" + demande.id} value={demande.avisCna ?? ""} onChange={(event) => this.onAvisCnaChange(event, demande.id!)} label={""} data={options} />
                </div>
            )
        } else {
            switch (demande.avisCna) {
                case "RENVOI_PROCHAINE_CNA": {
                    return (
                        <Fragment>
                            <span style={{ color: "orange" }}>Renvoi à la prochaine CNA</span>
                        </Fragment>
                    )
                }
                case "FAVORABLE": {
                    return (
                        <Fragment>
                            <span style={{ color: "green" }}>Favorable</span>
                        </Fragment>
                    )
                }
                case "DEFAVORABLE": {
                    return (
                        <Fragment>
                            <span style={{ color: "red" }}>Defavorable</span>
                        </Fragment>
                    )
                }
                default: {
                    return (
                        <Fragment>
                            <div style={{ textAlign: "center" }}>-</div>
                        </Fragment>
                    )
                }
            }
        }
    }

    getAvisCnaStyle(value: string) {
        const css = {
            padding: "7px",
            paddingTop: "4px",
            backgroundColor: "inherit",
            displayMinistere: "block",
            borderRadius: "7px"
        }
        switch (value) {
            case Constant.STATUS.AVIS_CNA.FAVORABLE: {
                css.backgroundColor = this.map.get(Constant.STATUS.AVIS_CNA.FAVORABLE)!
                break
            }
            case Constant.STATUS.AVIS_CNA.DEFAVORABLE: {
                css.backgroundColor = this.map.get(Constant.STATUS.AVIS_CNA.DEFAVORABLE)!
                break
            }
            case Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA: {
                css.backgroundColor = this.map.get(Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA)!
                css.displayMinistere = "none"
                break
            }
        }
        return css
    }

    onAvisCnaChange(event: ChangeEvent<HTMLSelectElement>, demandeId: number) {
        const containerAvisCna = document.querySelector("#avis-cna-container-" + demandeId) as HTMLElement
        const selectAvisMinistere = document.querySelector("#select-avis-ministere-" + demandeId) as HTMLSelectElement
        const containerAvisMinistere = document.querySelector("#avis-ministere-container-" + demandeId) as HTMLElement

        const css = this.getAvisCnaStyle(event.target.value)
        containerAvisCna.style.backgroundColor = css.backgroundColor
        containerAvisMinistere.style.backgroundColor = css.backgroundColor
        containerAvisMinistere.style.display = css.displayMinistere

        this.changeAvisCnaState(demandeId, event.target.value)

        if (event.target.value === Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA) {
            selectAvisMinistere.value = ""
        } else {
            selectAvisMinistere.value = event.target.value
        }

        new HttpBackCnaService().saveAvisCna(demandeId, this.props.cna.id!, event.target.value).then((response) => {
            if (response.success) {
                // TODO ?
            } else {
                containerAvisCna.style.backgroundColor = "inherit"
            }
            this.setState({ forceRefresh: this.state.forceRefresh + 1 })
        })
    }

    changeAvisCnaState(demandeId: number, value: string) {
        this.state.demandes?.forEach((demande) => {
            if (demande.id === demandeId) {
                demande.avisCna = value
                switch (value) {
                    case "FAVORABLE":
                    case "DEFAVORABLE": {
                        demande.avisMinistere = value
                        break
                    }
                    default:
                        demande.avisMinistere = ""
                }
            }
        })
    }

    changeAvisMinistereState(demandeId: number, value: string) {
        this.state.demandes?.forEach((demande) => {
            if (demande.id === demandeId) {
                demande.avisMinistere = value
            }
        })
    }

    getAvisMinistere(demande: BackOfficeDemandeAgrementForm) {
        if (this.props.cna.dateValidation == null && !this.isMembreCna()) {
            const options: SelectOptionData[] = []
            options.push(new SelectOptionData("", "- avis Ministère -", null === demande.avisMinistere))
            options.push(new SelectOptionData(Constant.STATUS.AVIS_MINISTERE.DEFAVORABLE, "Défavorable", Constant.STATUS.AVIS_MINISTERE.DEFAVORABLE === demande.avisMinistere))
            options.push(new SelectOptionData(Constant.STATUS.AVIS_MINISTERE.FAVORABLE, "Favorable", Constant.STATUS.AVIS_MINISTERE.FAVORABLE === demande.avisMinistere))
            return (
                <div id={"avis-ministere-container-" + demande.id} style={this.getAvisMinistereStyle(demande.avisMinistere ?? "", demande.avisCna ?? "")}>
                    <SelectComponent
                        id={"select-avis-ministere-" + demande.id}
                        value={demande.avisMinistere ?? ""}
                        onChange={(event) => this.onAvisMinistereChange(event, demande.id!)}
                        label={""}
                        data={options}
                    />
                </div>
            )
        } else {
            switch (demande.avisMinistere) {
                case "": {
                    return <Fragment>-</Fragment>
                }
                case "FAVORABLE": {
                    return (
                        <Fragment>
                            <span style={{ color: "green" }}>Favorable</span>
                        </Fragment>
                    )
                }
                case "DEFAVORABLE": {
                    return (
                        <Fragment>
                            <span style={{ color: "red" }}>Defavorable</span>
                        </Fragment>
                    )
                }
                default: {
                    return (
                        <Fragment>
                            <div style={{ textAlign: "center" }}>-</div>
                        </Fragment>
                    )
                }
            }
        }
    }

    getAvisMinistereStyle(value: string, valueAvisCna: string) {
        const css = {
            padding: "7px",
            paddingTop: "4px",
            backgroundColor: "inherit",
            borderRadius: "7px",
            display: "block"
        }
        css.backgroundColor = this.map.get(value) ?? "inherit"
        if (valueAvisCna === Constant.STATUS.AVIS_CNA.RENVOI_PROCHAINE_CNA) {
            css.display = "none"
        }
        return css
    }

    onAvisMinistereChange(event: ChangeEvent<HTMLSelectElement>, demandeId: number) {
        const container = document.querySelector("#avis-ministere-container-" + demandeId) as HTMLElement
        container.style.backgroundColor = this.map.get(event.target.value) ?? "inherit"
        this.changeAvisMinistereState(demandeId, event.target.value)
        new HttpBackCnaService().saveAvisMinistere(demandeId, this.props.cna.id!, event.target.value).then((response) => {
            if (response.success) {
                // TODO ?
            } else {
                container.style.backgroundColor = "inherit"
            }
            this.setState({ forceRefresh: this.state.forceRefresh + 1 })
        })
    }

    getDateTransmission(demande: BackOfficeDemandeAgrementForm) {
        if (demande.dateUpdateFromFront != null) {
            return UiService.formatServerDateToHumanNumericDate(demande.dateUpdateFromFront)
        }
    }

    getDateSva(demande: BackOfficeDemandeAgrementForm) {
        if (demande.dateSva != null) {
            return UiService.formatServerDateToHumanNumericDate(demande.dateSva)
        }
    }

    clickDemande(idDemande: number) {
        const map = new Map<string, any>()
        map.set(Constant.KEY.BACK.DEMANDE_ID, idDemande)
        BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__SHOW_DEMANDE, map)
    }

    getButton(demande: BackOfficeDemandeAgrementForm, index: number) {
        return (
            <Button key={"clickItem" + index} modal={"cna-demande-" + demande.id}>
                {this.state.currentPage * this.ITEM_BY_PAGE + (index + 1)}
            </Button>
        )
    }

    getCellCodePostalIfNotValidator(demande: BackOfficeDemandeAgrementForm, color: string) {
        if (!this.props.showValidationForUser) {
            return <TableCellComponent style={{ color: color }}>{demande.entiteJuridiqueCodePostal}</TableCellComponent>
        }
    }

    manageRaisonRefus(demande: BackOfficeDemandeAgrementForm, raison: string) {
        new HttpBackCnaService().saveRaisonRefus(demande.id!, this.props.cna.id!, raison).then((response) => {
            demande.raisonRefus = raison
            this.setState({ forceRefresh: this.state.forceRefresh + 1 })
        })
    }

    manageCommentaireAcceptation(demande: BackOfficeDemandeAgrementForm, commentaireAcceptation: string) {
        new HttpBackCnaService().saveCommentaireAcceptation(demande.id!, this.props.cna.id!, commentaireAcceptation).then((response) => {
            demande.commentaireAcceptation = commentaireAcceptation
            this.setState({ forceRefresh: this.state.forceRefresh + 1 })
        })
    }

    getCellCommentaireSurDecision(demande: BackOfficeDemandeAgrementForm) {
        // TODO 1ere condition nécessaire ?
        if (demande.avisCna === "FAVORABLE" && demande.avisMinistere === "FAVORABLE") {
            return (
                <TableCellComponent key={"button-edit-commentaire-acceptation-" + demande.id}>
                    {this.getButtonCellCommentaireAcceptation(demande)}
                    <BackOfficeModalEditCommentaireAcceptationComponent
                        demandeId={demande.id!}
                        commentaireAcceptation={demande.commentaireAcceptation ?? ""}
                        saveCommentaireAcceptation={(commentaire) => this.manageCommentaireAcceptation(demande, commentaire)}
                    />
                </TableCellComponent>
            )

            // TODO 1ere condition nécessaire ?
        } else if (/*demande.avisCna === "DEFAVORABLE" &&*/ demande.avisMinistere === "DEFAVORABLE") {
            return (
                <TableCellComponent key={"button-edit-raison-refus-" + demande.id}>
                    {this.getButtonCellRaisonRefus(demande)}
                    <BackOfficeModalEditRaisonRefusComponent demandeId={demande.id!} raisonRefus={demande.raisonRefus ?? ""} saveRaisonRefus={(raison) => this.manageRaisonRefus(demande, raison)} />
                </TableCellComponent>
            )
        } else {
            return <TableCellComponent key={"button-edit-commentaire-sur-decision-" + demande.id}></TableCellComponent>
        }
    }

    getButtonCellRaisonRefus(demande: BackOfficeDemandeAgrementForm) {
        if (demande.raisonRefus == null || demande.raisonRefus.trim() === "") {
            return <Button key={"bcrr-question-" + demande.id} iconOnly={Constant.ICON.QUESTION_FILL} modal={"demande-raison-refus-" + demande.id} />
        } else {
            return <Button key={"bcrr-check-" + demande.id} iconOnly={Constant.ICON.CHECK_LINE} modal={"demande-raison-refus-" + demande.id} />
        }
    }

    getButtonCellCommentaireAcceptation(demande: BackOfficeDemandeAgrementForm) {
        if (demande.commentaireAcceptation == null || demande.commentaireAcceptation.trim() === "") {
            return <Button key={"bcca-question-" + demande.id} iconOnly={Constant.ICON.QUESTION_FILL} modal={"demande-commentaire-acceptation-" + demande.id} />
        } else {
            return <Button key={"bcca-check-" + demande.id} iconOnly={Constant.ICON.CHECK_LINE} modal={"demande-commentaire-acceptation-" + demande.id} />
        }
    }

    getCellNotificationStatus(demande: BackOfficeDemandeAgrementForm) {
        if (this.props.cna.dateValidation != null) {
            //console.log("passiveTrackingNotificationChecked :: " + demande.passiveTrackingNotificationChecked)
            if (demande.passiveTrackingNotificationChecked == true) {
                return <TableCellComponent style={{ backgroundColor: "green", width: "5px" }}></TableCellComponent>
            } else {
                return <TableCellComponent style={{ backgroundColor: "red", width: "5px" }}></TableCellComponent>
            }
        }
    }

    getHeaderCodePostalIfNotValidator(): ReactElement | undefined {
        if (!this.props.showValidationForUser) {
            return <TableHeaderComponent>Code postal</TableHeaderComponent>
        }
    }

    getHeaderEmptyIfValidator() {
        if (this.props.showValidationForUser) {
            return <TableHeaderComponent>&nbsp;</TableHeaderComponent>
        }
    }

    getHeaderNotificationStatusIfCnaValidated() {
        if (this.props.cna.dateValidation != null) {
            return <TableHeaderComponent style={{ width: "5px" }}>&nbsp;</TableHeaderComponent>
        }
    }

    getHeader(): ReactElement {
        return (
            <tr key="header-line-0">
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
                <TableHeaderComponent>Type</TableHeaderComponent>
                <TableHeaderComponent>Entité juridique</TableHeaderComponent>
                {this.getHeaderCodePostalIfNotValidator()}
                <TableHeaderComponent>Date SVA</TableHeaderComponent>
                <TableHeaderComponent>Date de transmission</TableHeaderComponent>
                <TableHeaderComponent>Avis CNA</TableHeaderComponent>
                <TableHeaderComponent>Avis Ministère</TableHeaderComponent>
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
                {this.getHeaderNotificationStatusIfCnaValidated()}
            </tr>
        )
    }

    checkImpact(demande: BackOfficeDemandeAgrementForm) {
        if (demande.sansImpactFinancier || demande.financementTotal == 0 || demande.financementTotal == null) {
            return true
        }
        return false
    }

    getContent() {
        const tbody: ReactElement[] = []
        if (this.state && this.state.demandes && this.state.demandes.length > 0) {
            let h = 0
            const demandes = this.state.demandes
            demandes.sort((a, b) => (this.checkImpact(a) > this.checkImpact(b) ? 1 : -1))
            demandes.forEach((demande, index) => {
                h = 0
                const color = this.checkImpact(demande) ? "blue" : ""
                tbody.push(
                    <tr key={"demande-" + demande.id + "-" + index}>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            <LazyWrapperComponent source={this.getButton(demande, index)}>
                                <BackOfficeModalShowDemandeComponent prefixId={"cna"} demandeId={demande.id!} typologies={this.state.typologies} showActionOrStatusRow={false} parentMethod={() => this.deleteDemandeFromList(demande.id!)} />
                            </LazyWrapperComponent>
                        </TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center", color: color }} key={"column-" + index + "-" + h++}>
                            {demande.typeDemande == "ACCORD" ? "Accord" : demande.typeDemande}
                            <br />
                            {demande.id}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {demande.entiteJuridique}
                            <br />
                            {this.getEtablissements(demande)}
                        </TableCellComponent>
                        {this.getCellCodePostalIfNotValidator(demande, color)}
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {this.getDateSva(demande)}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ color: color }}>
                            {this.getDateTransmission(demande)}
                        </TableCellComponent>
                        <TableCellComponent>{this.getAvisCna(demande)}</TableCellComponent>
                        <TableCellComponent>{this.getAvisMinistere(demande)}</TableCellComponent>
                        {this.getCellCommentaireSurDecision(demande)}
                        {this.getCellNotificationStatus(demande)}
                    </tr>
                )
            })
        }

        return tbody
    }

    choosePage(pageNumber: number) {
        let typologieId: number | null = null
        let conventionCollectiveCode: string | null = null

        // typologie / thematique
        if (this.state && this.state.typologies) {
            const selectTypologieComponent = document.querySelector("#select-typologie-filter") as HTMLInputElement
            const typologieDefined = selectTypologieComponent.value !== "" && !isNaN(Number(selectTypologieComponent.value))
            typologieId = typologieDefined ? Number.parseInt(selectTypologieComponent.value) : null
        }

        // id convention collective
        if (this.state && this.state.conventionCollectives) {
            const selectConventionCollectiveComponent = document.querySelector("#select-idcc-filter") as HTMLInputElement
            const conventionCollectiveDefined = selectConventionCollectiveComponent.value !== "" && !isNaN(Number(selectConventionCollectiveComponent.value))
            conventionCollectiveCode = conventionCollectiveDefined ? selectConventionCollectiveComponent.value : null
        }

        // etat
        const etatArray: string[] | null = null // [Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA]

        // type
        const selectTypeComponent = document.querySelector("#select-type-filter") as HTMLInputElement
        const typeValue = selectTypeComponent.value !== "" ? selectTypeComponent.value : null

        // entite juridique
        const inputEntiteJuridique = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        const entiteJuridiqueFilter = inputEntiteJuridique.value

        const codePostalEntiteJuridique = document.querySelector("#code-postal-ej") as HTMLInputElement
        const codePostal = codePostalEntiteJuridique.value

        const selectTypeFinanceur = document.querySelector("#select-type-financeur-filter") as HTMLInputElement
        const typeFinanceurValue = selectTypeFinanceur.value !== "" ? selectTypeFinanceur.value : null

        //console.log("Choose page : "+pageNumber)
        new HttpBackDemande()
            .getDemandes(
                this.props.cna.id,
                entiteJuridiqueFilter,
                etatArray,
                typeValue,
                conventionCollectiveCode,
                typologieId,
                codePostal,
                typeFinanceurValue,
                pageNumber,
                this.ITEM_BY_PAGE
            )
            .then((response) => {
                const cast = new Response<PagerResponse<BackOfficeDemandeAgrementForm>>(response)
                if (cast.success) {
                    this.setState({
                        demandes: cast.content!.list!,
                        count: cast.content!.count!,
                        currentPage: pageNumber,
                        conventionCollectiveCode: conventionCollectiveCode,
                        //etat: Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA,
                        type: typeValue,
                        typologieId: typologieId,
                        entiteJuridiqueFilter: entiteJuridiqueFilter,
                        codePostal: codePostal,
                        typeFinanceurId: typeFinanceurValue
                    })
                }
            })
    }

    getTableOrMessage() {
        const content = this.getContent()
        if (content.length > 0) {
            return <TableContainerComponent headers={[this.getHeader()]} cells={content} />
        } else {
            return <AlertWarningComponent small description={"Il n'y a actuellement aucune demande associée à cette CNA"} style={{ margin: "40px" }} />
        }
    }

    validCnaFirstTime() {
        this.setState({ confirmValidation: true })
    }

    validCnaSecondTime() {
        new HttpBackCnaService().validAvisCna(this.props.cna.id!).then((response) => {
            if (response.success) {
                this.props.goBack()
            }
        })
    }

    sendDateEffective() {
        const input = document.querySelector("#date-effective") as HTMLInputElement
        new HttpBackCnaService().changeDateEffective(this.props.cna.id!, input.value).then((response) => {
            if (response.success) {
                // todo
                this.setState({ dateEffectiveDefined: true })
            } else {
                console.error("une erreur s'est produite")
                this.setState({ dateEffectiveDefined: false })
            }
        })
    }

    getDateEffectiveForm() {
        if (this.props.cna.dateValidation != null || !this.props.showDateEffectiveForUser) {
            return <div>{null}</div>
        }
        const effective = this.props.cna.dateEffective != null ? UiService.formatServerDateToHumanDate(this.props.cna.dateEffective) : null
        const today = UiService.formatDateToHumanDate(new Date())
        if (this.state?.dateEffectiveDefined) {
            const value = (document.querySelector("#date-effective") as HTMLInputElement).value
            return (
                <div style={{ marginBottom: "20px", padding: "20px" }}>
                    <Fragment>
                        <FieldsetComponent
                            legend={"Date effective"}
                            content={
                                <ContainerComponent>
                                    <RowComponent>
                                        <ColumnComponent size={7}>La CNA a eu lieu le {UiService.formatDatePickerToHumanDate(value)}.</ColumnComponent>
                                        <ColumnComponent size={2} />
                                        <ColumnComponent size={3}></ColumnComponent>
                                    </RowComponent>
                                </ContainerComponent>
                            }
                        />
                    </Fragment>
                </div>
            )
        } else if (effective == null || today !== effective) {
            return (
                <div style={{ marginBottom: "20px", padding: "20px" }}>
                    <Fragment>
                        <FieldsetComponent
                            legend={"Date effective"}
                            content={
                                <ContainerComponent>
                                    <RowComponent>
                                        <ColumnComponent size={7}>
                                            Si le quorum n'est pas atteint ou pour une autre raison la CNA a lieu un autre jour que la date prévue, vous pouvez définir cette date ici.
                                        </ColumnComponent>
                                        <ColumnComponent size={2} />
                                        <ColumnComponent size={3}>
                                            <DateInputComponent
                                                label={""}
                                                id={"date-effective"}
                                                value={this.props.cna.dateEffective ? UiService.formatServerDateToDatePicker(this.props.cna.dateEffective) : ""}
                                            />
                                            <Button onClick={() => this.sendDateEffective()}>Enregistrer</Button>
                                        </ColumnComponent>
                                    </RowComponent>
                                </ContainerComponent>
                            }
                        />
                    </Fragment>
                </div>
            )
        }
    }

    getValidationButton() {
        if (this.props.cna.dateValidation != null) {
            return (
                <div style={{ marginBottom: "20px", padding: "20px" }}>
                    <FieldsetComponent legend={"Validation"} content={<p>La CNA a été validée le {UiService.formatServerDateToHumanDate(this.props.cna.dateValidation)}</p>} />
                </div>
            )
        }

        let countAvisCnaFavorableDefavorable = 0
        let countAvisCnaRenvoi = 0
        let countAvisMinistereFavorableDefavorable = 0
        let countAvisMinistereDefavorable = 0
        let countAvisMinistereDefavorableMotiver = 0
        let countDemande = 0

        this.state.demandes?.forEach((demande) => {
            countDemande++
            if (demande.avisCna === "FAVORABLE" || demande.avisCna === "DEFAVORABLE") {
                countAvisCnaFavorableDefavorable++
            } else if (demande.avisCna === "RENVOI_PROCHAINE_CNA") {
                countAvisCnaRenvoi++
            }
            if (demande.avisMinistere === "FAVORABLE" || demande.avisMinistere === "DEFAVORABLE") {
                countAvisMinistereFavorableDefavorable++
                if (demande.avisMinistere === "DEFAVORABLE") {
                    countAvisMinistereDefavorable++
                    if (demande.raisonRefus != null) {
                        countAvisMinistereDefavorableMotiver++
                    }
                }
            }
        })

        //console.log(countAvisMinistereDefavorable + " ## " + countAvisMinistereDefavorableMotiver)

        //console.log("A "+countDemande+" == "+ countAvisCnaFavorableDefavorable+" "+countAvisCnaRenvoi)
        //console.log("B "+countAvisMinistereFavorableDefavorable+" == "+countAvisMinistereFavorableDefavorable)
        //console.log("C "+countAvisMinistereDefavorable+" == "+countAvisMinistereDefavorableMotiver)

        const allValuesDefined =
            countDemande == countAvisCnaFavorableDefavorable + countAvisCnaRenvoi &&
            countAvisMinistereFavorableDefavorable == countAvisMinistereFavorableDefavorable &&
            countAvisMinistereDefavorable == countAvisMinistereDefavorableMotiver

        if (this.props.showValidationForUser) {
            if (this.state.confirmValidation) {
                return (
                    <div style={{ marginBottom: "20px", padding: "20px" }}>
                        <FieldsetComponent
                            legend={"Validation"}
                            content={
                                <Fragment>
                                    <ContainerComponent key={"validation-2"}>
                                        <RowComponent key={"validation-2-row-1"}>
                                            <ColumnComponent size={2} />
                                            <ColumnComponent size={8}>
                                                <p>Vous êtes sur le point de valider et donc clôturer cette CNA.</p>
                                            </ColumnComponent>
                                            <ColumnComponent size={2} />
                                        </RowComponent>
                                        <RowComponent key={"validation-2-row-2"}>
                                            <ColumnComponent size={3} />
                                            <ColumnComponent size={2}>
                                                <Button onClick={() => this.validCnaSecondTime()} medium iconLeft={Constant.ICON.CHECK_LINE}>
                                                    Valider
                                                </Button>
                                            </ColumnComponent>
                                            <ColumnComponent size={2} />
                                            <ColumnComponent size={2}>
                                                <Button onClick={() => this.setState({ confirmValidation: false })} secondary medium>
                                                    Annuler
                                                </Button>
                                            </ColumnComponent>
                                            <ColumnComponent size={3} />
                                        </RowComponent>
                                    </ContainerComponent>
                                </Fragment>
                            }
                        />
                    </div>
                )
            } else {
                if (allValuesDefined) {
                    return (
                        <div style={{ marginBottom: "30px", padding: "20px" }}>
                            <FieldsetComponent
                                legend={"Validation"}
                                content={
                                    <Fragment>
                                        <ContainerComponent key={"validation-1"}>
                                            <RowComponent>
                                                <ColumnComponent size={7}>
                                                    <p>
                                                        La validation de la CNA déclenchera l'envoi des notifications pour les avis favorables et défavorables. Les demandes renvoyées seront libérées
                                                        puis associées à la prochaine CNA.
                                                    </p>
                                                </ColumnComponent>
                                                <ColumnComponent size={2}></ColumnComponent>
                                                <ColumnComponent size={3}>
                                                    <Button onClick={() => this.validCnaFirstTime()} medium iconLeft={Constant.ICON.CHECK_LINE}>
                                                        Valider
                                                    </Button>
                                                </ColumnComponent>
                                            </RowComponent>
                                        </ContainerComponent>
                                    </Fragment>
                                }
                            />
                        </div>
                    )
                } else {
                    return (
                        <div style={{ marginBottom: "30px", padding: "20px" }}>
                            <FieldsetComponent
                                legend={"Validations"}
                                content={
                                    <Fragment>
                                        <ContainerComponent key={"validation-0"}>
                                            <RowComponent>
                                                <ColumnComponent size={7}>
                                                    <p>Pour valider la CNA, il est nécessaire de renseigner tous les avis (CNA et ministère).</p>
                                                </ColumnComponent>
                                                <ColumnComponent size={2}></ColumnComponent>
                                                <ColumnComponent size={3}>
                                                    <Button disabled medium iconLeft={Constant.ICON.CHECK_LINE}>
                                                        Valider
                                                    </Button>
                                                </ColumnComponent>
                                            </RowComponent>
                                        </ContainerComponent>
                                    </Fragment>
                                }
                            />
                        </div>
                    )
                }
            }
        }
    }

    deleteDemandeFromList(id: number) {
        const demandes = this.state.demandes
        const newDemandes = demandes?.filter((demande) => demande.id !== id)
        this.setState({ demandes: newDemandes })
    }

    render() {
        //console.log("render")
        return (
            <>
                <ContainerComponent id={Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES}>
                    <PrimaryContentBoxComponent
                        title={"Demandes d'agrément"}
                        rightComponent={
                            <RowComponent>
                                <ColumnComponent size={2} />
                                <ColumnComponent size={6}>
                                    <Button
                                        onClick={() => window.open("/accolade/back/csv/cna/" + this.props.cna.id, "_blank", "noopener,noreferrer")}
                                        iconLeft={Constant.ICON.DOWNLOAD_FILL}
                                        style={{ marginTop: "4px", marginRight: "2px" }}>
                                        {"Télécharger"}
                                    </Button>
                                </ColumnComponent>
                                <ColumnComponent size={4}>
                                    <Button iconLeft={Constant.ICON.ARROW_LEFT_LINE} onClick={() => this.props.goBack()} style={{ marginTop: "4px" }}>
                                        Retour
                                    </Button>
                                </ColumnComponent>
                            </RowComponent>
                        }>
                        <RowComponent>
                            <ColumnComponent size={10} />
                            <ColumnComponent size={2}></ColumnComponent>
                        </RowComponent>

                        <ColumnComponent size={6}></ColumnComponent>
                        <div style={{ padding: "20px" }}>{this.getFiltre()}</div>
                        {this.getDateEffectiveForm()}
                        {this.getValidationButton()}
                        <div style={{ padding: "20px" }}>{this.getTableOrMessage()}</div>

                        {/*
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <PagerComponent itemByPage={BackOfficeListDemandeCnaPage.ITEM_BY_PAGE} itemCount={this.state.count} currentPage={this.state.currentPage} choosePage={(page)=>this.choosePage(page)} />
                    </div>
                    */}
                    </PrimaryContentBoxComponent>
                </ContainerComponent>
            </>
        )
    }
}
