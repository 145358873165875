import Captcha from "./captcha/lib/reactjs-captcha"
import React from "react"

import Account from "../../../model/Account"

import Button from "../../../../generic/component/button/Button"
import PasswordInputComponent from "../../../../generic/component/form/input/PasswordInputComponent"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import DnumComponent, { DnumProps } from "..//../../../generic/component/DnumComponent"

import HttpAccountService from "../../../service/http/HttpAccountService"
import Response from "../../../service/http/response/Response"
import SecondaryContentBoxComponent from "../../../shared/component/box/SecondaryContentBoxComponent"
import FrontCaptchaComponent from "./captcha/FrontCaptchaComponent"

interface FrontCreateLoginPasswordFormState {
    captchaHtml: string | null
    errorMessage?: string | undefined
    errors?: Map<string, string> | undefined
    captchaId?: string | undefined
}

interface FrontCreateLoginPasswordFormProps extends DnumProps {
    onCreate: () => void
}

export default class FrontCreateLoginPasswordFormComponent extends DnumComponent<FrontCreateLoginPasswordFormProps, FrontCreateLoginPasswordFormState> {
    public static KEY = "create-login-password"

    httpService: HttpAccountService = new HttpAccountService()
    captcha: Captcha | null = null

    constructor(props: FrontCreateLoginPasswordFormProps) {
        super(props)
    }

    setCaptchaId(captchaId: string) {
        this.setState({ captchaId: captchaId })
    }

    getFormAccount() {
        const account = new Account()
        account.email = (document.querySelector("#account-email") as HTMLInputElement).value
        account.confirmEmail = (document.querySelector("#account-confirm-email") as HTMLInputElement).value
        account.password = (document.querySelector("#account-password") as HTMLInputElement).value
        account.confirmPassword = (document.querySelector("#account-confirm-password") as HTMLInputElement).value
        account.confirmCaptcha = (document.querySelector("#captchaFormulaireExtInput") as HTMLInputElement).value
        account.captchaId = this.state.captchaId ?? ""
        return account
    }

    createAccount() {
        const response = this.httpService.createAccount(this.getFormAccount())

        response.then((backResponse) => {
            const cast = new Response(backResponse)

            if (cast.errorMessage) {
                this.setState({ errorMessage: backResponse.errorMessage })
                this.captcha?.reloadImage()
            } else if (cast.hasError()) {
                this.setState({ errors: cast.getErrorMap() })
                this.captcha?.reloadImage()
            } else {
                this.props.onCreate()
            }
        })
    }

    render() {
        return (
            <SecondaryContentBoxComponent title="Définition des identifiants">
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <TextInputComponent label="Courriel" id="account-email" error={this.state?.errors?.has("email")} errorMessage={this.state?.errors?.get("email")} />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <TextInputComponent
                            label="Confirmation de courriel"
                            error={this.state?.errors?.has("confirmEmail")}
                            errorMessage={this.state?.errors?.get("confirmEmail")}
                            id="account-confirm-email"
                        />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <PasswordInputComponent label="Mot de passe" id="account-password" password error={this.state?.errors?.has("password")} errorMessage={this.state?.errors?.get("password")} />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={8}>
                        <TextInputComponent
                            label="Confirmation du mot de passe"
                            password
                            error={this.state?.errors?.has("confirmPassword")}
                            errorMessage={this.state?.errors?.get("confirmPassword")}
                            id="account-confirm-password"
                        />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>

                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={2} />
                    <ColumnComponent size={4}>
                        {/*
                    <Test />
                    */}
                        <FrontCaptchaComponent setCaptchaId={(captchaId: string) => this.setCaptchaId(captchaId)} />
                    </ColumnComponent>
                    <ColumnComponent size={4}>
                        <TextInputComponent
                            label="Entrez les caractères de l'image :"
                            error={this.state?.errors?.has("confirmCaptcha")}
                            errorMessage={this.state?.errors?.get("confirmCaptcha")}
                            id="captchaFormulaireExtInput"
                        />
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>

                <RowComponent>
                    <ColumnComponent size={5} />
                    <ColumnComponent size={2}>
                        <Button onClick={() => this.createAccount()}>Valider</Button>
                    </ColumnComponent>
                    <ColumnComponent size={5} />
                </RowComponent>
            </SecondaryContentBoxComponent>
        )
    }
}
