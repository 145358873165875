import React, {ReactElement, ReactNode} from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface FooterProps extends DnumProps {
    headContentLinks?: string
    contentLinks?: ReactNode[]
    bottomLinks?: ReactNode[]
    underContentLinks?: ReactNode | string
    marianneText?: string
    marianneTextFirstPart?: string
    marianneTextSecondPart?: string
}

export default class FooterComponent extends DnumComponent<FooterProps, any> {
    private DEFAULT_BACKGROUND_COLOR = "white"

    constructor(props: FooterProps) {
        super(props)
    }

    getMarianneText(): ReactElement {
        if (this.props.marianneText) {
            return <div>{this.props.marianneText}</div>
        }
        return (
            <>
                {this.props.marianneTextFirstPart}
                <br />
                {this.props.marianneTextSecondPart}
            </>
        )
    }

    getContentLinks(): ReactElement | undefined {
        if (this.props.contentLinks) {
            return (
                <div className="fr-footer__content">
                    <p className="fr-footer__content-desc">{this.props.headContentLinks}</p>
                    <ul className="fr-footer__content-list">
                        {this.props.contentLinks.map((item, index) => (
                            <li key={`content-link-${index}`} className="fr-footer__content-item">
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        }
    }

    getBottomLinks() {
        if (this.props.bottomLinks) {
            return (
                <ul className="fr-footer__bottom-list">
                    {this.props.bottomLinks.map((item, index) => (
                        <li key={`bottom-link-${index}`} className="fr-footer__bottom-item">
                            {item}
                        </li>
                    ))}
                </ul>
            )
        }
    }

    protected getStyle(): React.CSSProperties | undefined {
        if (this.props.style) {
            return super.getStyle()
        } else {
            return { background: this.DEFAULT_BACKGROUND_COLOR }
        }
    }

    getHomeLink() {
        return window.location.pathname
    }

    render() {
        return (
            <footer className="fr-footer" role="contentinfo" id="footer" style={this.getStyle()}>
                <div className="fr-container">
                    <div className="fr-footer__body">
                        <div className="fr-footer__brand fr-enlarge-link">
                            <a href={this.getHomeLink()} title="Retour à l’accueil">
                                <p className="fr-logo">{this.getMarianneText()}</p>
                            </a>
                        </div>
                        {this.getContentLinks()}
                    </div>
                    <div className="fr-footer__bottom">
                        {this.getBottomLinks()}
                        <div className="fr-footer__bottom-copy">{this.props.underContentLinks}</div>
                    </div>
                </div>
            </footer>
        )
    }
}
