import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeIdentifiantConventionCollectiveFormComponent from "./BackOfficeIdentifiantConventionCollectiveFormComponent"
import BackOfficeIdentifiantConventionCollectiveListComponent from "./BackOfficeIdentifiantConventionCollectiveListComponent"

export default class BackOfficeIdentifiantConventionCollectivePage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Identifiant de convention collective"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeIdentifiantConventionCollectiveFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return (
            <BackOfficeIdentifiantConventionCollectiveListComponent
                forceRefresh={forceRefresh}
                addButtonHandler={addButtonHandler}
                editButtonHandler={editButtonHandler}
                deleteButtonHandler={deleteButtonHandler}
            />
        )
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_FINANCEUR
    }
}
