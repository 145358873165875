import React, { Fragment, ReactElement } from "react"

import ContactFinanceur from "../../model/ContactFinanceur"
import Esms from "../../model/Esms"

import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import Button from "../../../generic/component/button/Button"
import CheckboxComponent from "../../../generic/component/checkbox/CheckboxComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import HttpBackAccountService from "../../service/http/HttpBackAccountService"
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService"
import LocalStorageService from "../../service/storage/LocalStorageService"
import SecondaryContentBoxComponent from "../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import EspaceFinanceurPage from "../EspaceFinanceurPage"

interface EspaceFinanceurAccountPageState {
    contactFinanceur: ContactFinanceur | null
    esmsList: Esms[] | null
    editParamEmail: boolean
    myList: number[] | null
}

export default class EspaceFinanceurAccountPage extends EspaceFinanceurPage<any, EspaceFinanceurAccountPageState> {
    constructor(props: any) {
        super(props)
        this.state = {
            contactFinanceur: null,
            esmsList: null,
            editParamEmail: false,
            myList: null
        }
    }

    componentDidMount() {
        new HttpBackAccountService().getFinanceurSettings().then((response) => {
            const myList: number[] = response.content?.esmsList ?? []

            new HttpEspaceFinanceurService().getEsmsList().then((responseB) => {
                if (responseB.success) {
                    this.setState({
                        myList: myList,
                        esmsList: responseB.content!,
                        contactFinanceur: new LocalStorageService().getJsonItem(Constant.KEY.FINANCEUR.CONTACT_FINANCEUR) as ContactFinanceur
                    })
                }
            })
        })
    }

    getEmailParameterBox() {
        if (this.state.editParamEmail) {
            return <FieldsetComponent legend={"Paramétrage des emails"} content={this.getEsmsForm()} />
        } else {
            return <FieldsetComponent legend={"Paramétrage des emails"} content={this.getEsmsView()} />
        }
    }

    getEsmsView() {
        const list: ReactElement[] = []
        if (this.state?.myList?.length ?? 0 > 0) {
            this.state.esmsList
                ?.sort((a, b) => a.label!.localeCompare(b.label!))
                .forEach((esms) => {
                    if (esms.active && this.state.myList?.includes(esms.id!)) {
                        list.push(<li key={"esms-" + esms.id}>{esms.label}</li>)
                    }
                })
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={9}>
                                Vous avez associé les alertes et relances emails pour ces catégories d'établissement :<br />
                                <ul>{list}</ul>
                            </ColumnComponent>
                            <ColumnComponent size={1}>
                                <Button key={"edit-params"} onClick={() => this.editParametrage()}>
                                    Edit
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={1}></ColumnComponent>
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={9}>
                                <span>
                                    Aucune catégorie d'ESMS n'est associée à votre compte.
                                    <br />
                                    Vous êtes donc par défaut associé à toutes les catégories.
                                </span>
                            </ColumnComponent>
                            <ColumnComponent size={1}>
                                <Button key={"edit-params"} onClick={() => this.editParametrage()}>
                                    Edit
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={1}></ColumnComponent>
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    editParametrage() {
        this.setState({
            editParamEmail: true
        })
    }

    getEsmsCheckbox(esms: Esms) {
        return <CheckboxComponent key={"checkbox-" + esms.id} label={esms.label!} id={esms.id!.toString()} checked={this.state.myList?.includes(esms.id!) ?? false} />
    }

    saveParameters() {
        const list: NodeListOf<HTMLInputElement> = document.querySelectorAll("input[type=checkbox]")
        const listId: number[] = []
        list.forEach((item) => {
            if (item.checked) {
                console.log(item.name)
                listId.push(Number.parseInt(item.name))
            }
        })

        new HttpBackAccountService().updateFinanceurSettings(listId).then((response) => {
            if (response.success) {
                this.setState({
                    myList: listId,
                    editParamEmail: false
                })
            } else {
                this.setState({
                    editParamEmail: false
                })
            }
        })
    }

    getEsmsForm(): ReactElement {
        const list: ReactElement[] = []
        this.state.esmsList
            ?.sort((a, b) => a.label!.localeCompare(b.label!))
            .forEach((esms) => {
                list.push(this.getEsmsCheckbox(esms))
            })
        return (
            <Fragment>
                <ContainerComponent>
                    <RowComponent>
                        <ColumnComponent size={1}></ColumnComponent>
                        <ColumnComponent size={9}>
                            <ul>{list}</ul>
                        </ColumnComponent>
                        <ColumnComponent size={1}>
                            <Button key={"save-params"} onClick={() => this.saveParameters()}>
                                Enregistrer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1}></ColumnComponent>
                    </RowComponent>
                </ContainerComponent>
            </Fragment>
        )
    }

    getInformations() {
        return (
            <ContainerComponent>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>
                        <b>Organisme</b>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>
                        <b>Email</b>
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>{this.state?.contactFinanceur?.organisme.nom}</ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>{this.state?.contactFinanceur?.email}</ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <br />
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>
                        <b>Nom</b>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>
                        <b>Prénom</b>
                    </ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>{this.state?.contactFinanceur?.nom}</ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={4}>{this.state?.contactFinanceur?.prenom}</ColumnComponent>
                    <ColumnComponent size={2} />
                </RowComponent>
            </ContainerComponent>
        )
    }

    render() {
        if (this.state?.contactFinanceur == null) {
            return <Fragment>{this.state.contactFinanceur == null}</Fragment>
        } else {
            return (
                <Fragment>
                    <VerticalSpacing key="vs-1" height={"50px"} />
                    <ContainerComponent key="esfhp-content">
                        <SecondaryContentBoxComponent title={"Mon compte"}>
                            <br />
                            <FieldsetComponent legend={"Informations"} content={this.getInformations()} />
                            <br />
                            {this.getEmailParameterBox()}
                        </SecondaryContentBoxComponent>
                    </ContainerComponent>
                    <VerticalSpacing key="vs-2" height={"50px"} />
                    <br />
                    <br />
                </Fragment>
            )
        }
    }
}
