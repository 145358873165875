import React, { Fragment, ReactElement } from "react"

import Signature from "../../../model/Signature"

import AlertErrorComponent from "../../../../generic/component/alert/AlertErrorComponent"
import Button from "../../../../generic/component/button/Button"
import FileUploadComponent from "../../../../generic/component/fileupload/FileUploadComponent"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import HttpReferentielSignatureService from "../../../service/http/HttpReferentielSignatureService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeSignatureFormComponent extends BackOfficeAbstractReferentielFormComponent<Signature, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielSignatureService()
    }

    formToObject(id: number): Signature {
        const signature = new Signature(this.props.objectId)
        signature.code = (document.querySelector("#signature-code") as HTMLInputElement).value
        signature.libelle = (document.querySelector("#signature-libelle") as HTMLInputElement).value
        signature.active = (document.querySelector("input[name='signature-actif']") as HTMLInputElement).checked
        return signature
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("signature-actif", "Actif", condition!)
    }

    getImageInput(): ReactElement {
        if (this.props.objectId) {
            return (
                <RowComponent>
                    <ColumnComponent size={6}>
                        <FileUploadComponent maxSizeInMo={12} label={null} id={"signature-file"} error={this.state?.errors?.has("signature-file")} errorMessage={this.state?.errors?.get("signature-file")} />
                    </ColumnComponent>
                    <ColumnComponent size={6}>
                        <img width="100" src={process.env.REACT_APP_CONTEXT_PATH + "/back/admin/referentiel/local/signature/image/" + this.state.item?.uuid} alt={"Signature "} />
                    </ColumnComponent>
                </RowComponent>
            )
        } else {
            return (
                <RowComponent>
                    <ColumnComponent size={6}>
                        <FileUploadComponent
                            maxSizeInMo={12}
                            label={null}
                            informations={"Extension jpg ou jpeg requise"}
                            id={"signature-file"}
                            error={this.state?.errors?.has("signature-file")}
                            errorMessage={this.state?.errors?.get("signature-file")}
                        />
                    </ColumnComponent>
                    <ColumnComponent size={6}></ColumnComponent>
                </RowComponent>
            )
        }
    }

    getImportantError() {
        if (this.state && this.state.errors && this.state.errors.get("signature-actif") != null) {
            return (
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={10}>
                        <AlertErrorComponent small description={this.state?.errors?.get("signature-actif")} />
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
            )
        }
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    {this.getImportantError()}
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Code"
                                id="signature-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="signature-libelle"
                                value={this.getProperty("libelle")}
                                error={this.state?.errors?.has("libelle")}
                                errorMessage={this.state?.errors?.get("libelle")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getImageInput()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
