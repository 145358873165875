import React from "react"

import DnumComponent, { DnumProps } from "../../DnumComponent"
import { DnumErrorHandlerProps } from "../../DnumErrorHandlerProps"
import ErrorMessageComponent from "../../message/ErrorMessageComponent"

interface DateInputProps extends DnumErrorHandlerProps {
    label: string
    id: string
    value?: string
}

export default class DateInputComponent extends DnumComponent<DateInputProps, any> {
    constructor(props: DateInputProps) {
        super(props)
    }

    getMessage() {
        if (this.props.errorMessage !== null && this.props.errorMessage !== undefined) {
            return <ErrorMessageComponent message={this.props.errorMessage} />
        }
    }

    render() {
        let className = "fr-input"
        let groupClassName = "fr-input-group"
        if (this.props.error == true) {
            className += " fr-input--error"
            groupClassName += " fr-input-group--error"
        }

        return (
            <div className={groupClassName}>
                <label className="fr-label" htmlFor="text-input-calendar">
                    {this.props.label}
                </label>
                <div className="fr-input-wrap fr-fi-calendar-line">
                    <input className={className} type="date" id={this.props.id} defaultValue={this.props.value} name="text-input-calendar" />
                    {this.getMessage()}
                </div>
            </div>
        )
    }
}
