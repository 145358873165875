import TypologieAccord from "../../../../model/TypologieAccord"

import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../../generic/component/select/SelectOptionData"

import HttpFrontReferentielService from "../../../../service/http/HttpFrontReferentielService"
import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFromStep0CState {
    typologies: TypologieAccord[] | undefined
}

export default class FrontDemandeAgrementFormStep0CComponent extends FrontComponent<FrontDemandeAgrementFormStepProps, FrontDemandeAgrementFromStep0CState> {
    constructor(props: FrontDemandeAgrementFormStepProps) {
        super(props)
    }

    componentDidMount() {
        new HttpFrontReferentielService().getAll<TypologieAccord>().then((response) => {
            this.setState({ typologies: response.content })
        })
    }

    getOptions(): [string, SelectOptionData[]] {
        let value = ""
        const options: SelectOptionData[] = []

        if (this.state && this.state.typologies) {
            options.push(new SelectOptionData("", "", false))
            this.state.typologies.forEach((typologie) => {
                let selected = false
                if (this.props.demandeInProgress?.typologieAccord) {
                    selected = this.props.demandeInProgress?.typologieAccord!.id === typologie.id
                }
                const option = new SelectOptionData(typologie.id!.toString(), typologie.libelle!, selected)
                options.push(option)
                if (selected) {
                    value = option.id
                }
            })
        }

        return [value, options]
    }

    render() {
        const data = this.getOptions()

        return (
            <>
                <SecondaryContentBoxComponent title="Thématique de l'accord">
                    <RowComponent>
                        <ColumnComponent size={12}>
                            <SelectComponent
                                id="select-typologie"
                                label="Typologie"
                                value={data[0]}
                                data={data[1]}
                                errorMessage={this.props.errors?.get("select-typologie")}
                                error={this.props.errors?.has("select-typologie")}
                            />
                        </ColumnComponent>
                    </RowComponent>
                </SecondaryContentBoxComponent>
            </>
        )
    }
}
