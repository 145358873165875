import Cout from "../../model/Cout"
import FinessEtablissement from "../../model/FinessEtablissement"
import TypologieAccord from "../../model/TypologieAccord"

export default class FrontDemandeAgrementForm {
    id: number | null = null
    typeDemande: string | null = null

    etat: string | null = null

    numeroDossier: string | null = null
    dateDepot: string | null = null
    dateSignature: string | null = null
    etpConcernes: string | null = null

    typologieAccordId: string | null = null

    // envoi
    etablissementIds: string[] | null = null

    emailContact: string | null = null
    masseSalarialeChargee: string | null = null
    sansImpactFinancier: boolean | null = null

    explication: string | null = null

    dateUpdateFromFront: string | null = null

    typologieAccord: TypologieAccord | null = null

    // reception
    etablissements: FinessEtablissement[] | null = null

    commentaireCompletude: string | null = null

    financement: Cout[] | null = null

    constructor() {
        // nothing
    }

    public get getNumeroDossier(): string {
        if (this.numeroDossier != null) {
            return this.numeroDossier
        }
        return ""
    }
}
