import React, {
    ChangeEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    ReactElement,
} from 'react'
import DnumComponent, { DnumProps } from '../../DnumComponent'
import { Constant } from '../../../../accolade/constant/Constant'
import TextInputDataListItem from './TextInputDataListItem'
import { DnumErrorHandlerProps } from '../../DnumErrorHandlerProps'
import ErrorMessageComponent from '../../message/ErrorMessageComponent'
import SuccessMessageComponent from '../../message/SuccessMessageComponent'

interface TextInputProps extends DnumProps, DnumErrorHandlerProps {
    label: string | ReactElement
    id: string
    description?: string
    password?: any
    number?: any
    icon?: null
    value: string
    readonly?: any
    readOnly?: any
    placeholder?: string
    name?: string
    onKeyDownHandler?: KeyboardEventHandler
    onKeyUpHandler?: KeyboardEventHandler
    onChangeHandler?: ChangeEventHandler
    dataList?: TextInputDataListItem[]
    valid?: string | any
    onBlurHandler?: FocusEventHandler
}

export default class ControlledTextInputComponent extends DnumComponent<
    TextInputProps,
    any
> {
    private className = 'fr-input-wrap'
    private textClassName = 'fr-label'
    private htmlFor = 'text-input-icon'

    constructor(props: TextInputProps) {
        super(props)
    }

    getTexts() {
        if (this.props.error == true) {
            this.htmlFor = 'text-input-error'
        } else if (this.props.valid) {
            this.htmlFor = 'text-input-valid'
        }
        if (this.props.description) {
            if (this.props.label) {
                return (
                    <label
                        className={this.textClassName}
                        htmlFor={this.htmlFor}>
                        {this.props.label}
                        <span className="fr-hint-text">
                            {this.props.description}
                        </span>
                    </label>
                )
            }
            return (
                <label className={this.textClassName} htmlFor={this.htmlFor}>
                    <span className="fr-hint-text">
                        {this.props.description}
                    </span>
                </label>
            )
        } else {
            return (
                <label className={this.textClassName} htmlFor={this.htmlFor}>
                    {this.props.label}
                </label>
            )
        }
    }

    getMessage() {
        if (
            this.props.errorMessage !== null &&
            this.props.errorMessage !== undefined
        ) {
            return <ErrorMessageComponent message={this.props.errorMessage} />
        } else if (this.props.valid && this.props.valid.length > 0) {
            return <SuccessMessageComponent message={this.props.valid} />
        }
    }

    onKeyDown(eventHandler: React.KeyboardEvent) {
        if (this.props.onKeyDownHandler) {
            this.props.onKeyDownHandler(eventHandler)
        }
    }

    onKeyUp(eventHandler: React.KeyboardEvent) {
        if (this.props.onKeyUpHandler) {
            this.props.onKeyUpHandler(eventHandler)
        }
    }

    onChange(eventHandler: React.ChangeEvent) {
        if (this.props.onChangeHandler) {
            this.props.onChangeHandler(eventHandler)
        }
    }

    onBlur(eventHandler: React.FocusEvent) {
        if (this.props.onBlurHandler) {
            this.props.onBlurHandler(eventHandler)
        }
    }

    getDataList(id: string) {
        if (this.props.dataList && this.props.dataList.length > 0) {
            const list: ReactElement[] = []
            this.props.dataList.forEach(function (item: TextInputDataListItem) {
                list.push(
                    <option
                        key={item.label!}
                        label={item.label!}
                        value={item.value!}
                    />
                )
            })
            return <datalist id={id}>{list}</datalist>
        }
    }

    getInput() {
        let inputClassName = 'fr-input'
        let messageAttachedProp = {}
        let dataListProp = {}

        if (this.props.error === true) {
            this.className += ' fr-input--error'
            messageAttachedProp = {
                'aria-describedby': 'text-input-error-desc-error',
            }
            inputClassName += ' fr-input--error'
        } else if (this.props.valid) {
            this.className += ' fr-input--valid'
            messageAttachedProp = {
                'aria-describedby': 'text-input-valid-desc-valid',
            }
            inputClassName += ' fr-input--valid'
        }

        const dataListId = this.generateId('data-list')
        if (this.props.dataList) {
            dataListProp = { list: dataListId }
        }

        let name = 'text-input-icon'
        if (this.props.name) {
            name = this.props.name
        }

        if (this.props.icon) {
            // TODO change defaut icone
            this.className += ` ${Constant.ICON.EDIT_LINE}`
        }

        let readOnlyTranslation = {}
        if (this.props.readonly || this.props.readOnly) {
            readOnlyTranslation = { readOnly: true }
        }

        let localStyle = {}
        if (this.props.style) {
            localStyle = { width: this.props.style.width }
        }

        if (this.props.password) {
            return (
                <div className={this.className}>
                    <input
                        placeholder={this.props.placeholder}
                        className={inputClassName}
                        style={localStyle}
                        type="password"
                        {...messageAttachedProp}
                        {...dataListProp}
                        {...readOnlyTranslation}
                        id={this.props.id}
                        value={this.props.value}
                        name={name}
                        onChange={this.onChange.bind(this)}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onKeyUp={this.onKeyUp.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                    />
                    {this.getDataList(dataListId)}
                </div>
            )
        } else if (this.props.number) {
            return (
                <div className={this.className}>
                    <input
                        placeholder={this.props.placeholder}
                        className={inputClassName}
                        style={localStyle}
                        type="number"
                        {...messageAttachedProp}
                        {...dataListProp}
                        {...readOnlyTranslation}
                        id={this.props.id}
                        value={this.props.value}
                        name={name}
                        onChange={this.onChange.bind(this)}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onKeyUp={this.onKeyUp.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                    />
                    {this.getDataList(dataListId)}
                </div>
            )
        } else {
            return (
                <div className={this.className}>
                    <input
                        placeholder={this.props.placeholder}
                        className={inputClassName}
                        type="text"
                        id={this.props.id}
                        {...readOnlyTranslation}
                        {...messageAttachedProp}
                        style={localStyle}
                        onChange={this.onChange.bind(this)}
                        {...dataListProp}
                        value={this.props.value}
                        name={name}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onKeyUp={this.onKeyUp.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                    />
                    {this.getDataList(dataListId)}
                </div>
            )
        }
    }

    render() {
        if (this.props.error == true) {
            return (
                <div className="fr-input-group fr-input-group--error">
                    {this.getTexts()}
                    {this.getInput()}
                    {this.getMessage()}
                </div>
            )
        } else if (this.props.valid) {
            return (
                <div className="fr-input-group fr-input-group--valid">
                    {this.getTexts()}
                    {this.getInput()}
                    {this.getMessage()}
                </div>
            )
        } else {
            return (
                <div className="fr-input-group">
                    {this.getTexts()}
                    {this.getInput()}
                </div>
            )
        }
    }
}
