import React from "react"

import Cna from "../../model/Cna"

import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import { PageProps } from "../../../generic/component/grid/page/PageComponent"

import { Constant } from "../../constant/Constant"
import HttpBackCnaService from "../../service/http/HttpBackCnaService"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import BackOfficePage from "../BackOfficePage"
import BackOfficeFormCnaComponent from "./BackOfficeFormCnaComponent"
import BackOfficeListCnaComponent from "./BackOfficeListCnaComponent"

interface BackOfficeListCnaPageState {
    cnaList: Cna[] | null
    mode: "list" | "add" | "edit"
    current: Cna | null
    errors: Map<string, string> | undefined
    title: string
}

export default class BackOfficeManageCnaPage extends BackOfficePage<PageProps, BackOfficeListCnaPageState> {
    getContent() {
        if (this.state && this.state.mode === "add") {
            return (
                <PrimaryContentBoxComponent title={"CNA > Création"}>
                    <BackOfficeFormCnaComponent errors={this.state.errors} saveCna={() => this.createCna()} cancelView={() => this.listCnaView()} />
                </PrimaryContentBoxComponent>
            )
        } else if (this.state && this.state.mode === "edit") {
            return (
                <PrimaryContentBoxComponent title={"CNA > Edition"}>
                    <BackOfficeFormCnaComponent cna={this.state.current} errors={this.state.errors} saveCna={() => this.editCna()} cancelView={() => this.listCnaView()} />
                </PrimaryContentBoxComponent>
            )
        } else {
            return (
                <PrimaryContentBoxComponent title={this.state?.title ?? "Liste des CNA"}>
                    <BackOfficeListCnaComponent changeTitle={(title) => this.setTitle(title)} createCnaView={() => this.createCnaView()} editCnaView={(cna) => this.editCnaView(cna)} />
                </PrimaryContentBoxComponent>
            )
        }
    }

    setTitle(title: string) {
        this.setState({ title: title })
    }

    createCna() {
        const input = document.querySelector("#date-cna") as HTMLInputElement
        new HttpBackCnaService().create(input.value).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    errors: undefined,
                    mode: "list"
                })
            } else {
                this.setState({
                    errors: cast.getErrorMap()
                })
            }
        })
    }

    editCna() {
        if (this.state && this.state.current) {
            const input = document.querySelector("#date-cna") as HTMLInputElement
            new HttpBackCnaService().update(input.value, this.state.current.id!).then((response) => {
                const cast = new Response(response)
                if (cast.success) {
                    this.setState({
                        errors: undefined,
                        mode: "list"
                    })
                } else {
                    this.setState({
                        errors: cast.getErrorMap()
                    })
                }
            })
        }
    }

    createCnaView() {
        this.setState({
            mode: "add",
            errors: undefined
        })
    }

    editCnaView(cna: Cna) {
        this.setState({
            mode: "edit",
            current: cna,
            errors: undefined
        })
    }

    listCnaView() {
        this.setState({
            mode: "list",
            errors: undefined
        })
    }

    render(): JSX.Element {
        return <ContainerComponent id={Constant.DOM.BACK_OFFICE.CNA__LIST}>{this.getContent()}</ContainerComponent>
    }
}
