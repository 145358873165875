import { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface ErrorMessageProps extends DnumProps {
    message?: string
    htmlMessage?: ReactElement
}
export default class ErrorMessageComponent extends DnumComponent<ErrorMessageProps, any> {
    constructor(props: ErrorMessageProps) {
        super(props)
    }

    render() {
        if (this.props.message) {
            return (
                <p id="text-input-error-desc-error" className="fr-error-text">
                    {this.props.message}
                </p>
            )
        } else {
            return (
                <p id="text-input-error-desc-error" className="fr-error-text">
                    {this.props.htmlMessage}
                </p>
            )
        }
    }
}
