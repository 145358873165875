import VerticalMenuComponent from "../../../generic/component/menu/vertical/VerticalMenuComponent"
import VerticalMenuItemData from "../../../generic/component/menu/vertical/VerticalMenuItemData"

import FrontComponent from "../FrontComponent"
import FrontDemandeAgrementFormPage from "./FrontDemandeAgrementFormPage"
import {DnumProps} from "../../../generic/component/DnumComponent";

interface FrontDemandeAgrementVerticalMenuProps extends DnumProps {
    index?: number
}

export default class FrontDemandeAgrementVerticalMenu extends FrontComponent<FrontDemandeAgrementVerticalMenuProps, any> {
    public static MENU_VERTICAL_DATA_ACCORD = "menu-vertical-data-accord-0"
    public static MENU_VERTICAL_COUT = "menu-vertical-cout-1"
    public static MENU_VERTICAL_PIECES_JOINTES = "menu-vertical-pieces-jointes-2"
    public static MENU_VERTICAL_RECAPITULATIF = "menu-vertical-recapitulatif-3"

    constructor(props: FrontDemandeAgrementVerticalMenuProps) {
        super(props)
    }

    render() {
        const data = [
            new VerticalMenuItemData("1. Données de l'accord", FrontDemandeAgrementVerticalMenu.MENU_VERTICAL_DATA_ACCORD, false),
            new VerticalMenuItemData("2. Coûts", FrontDemandeAgrementVerticalMenu.MENU_VERTICAL_COUT, false),
            new VerticalMenuItemData("3. Pièces jointes", FrontDemandeAgrementVerticalMenu.MENU_VERTICAL_PIECES_JOINTES, false),
            new VerticalMenuItemData("4. Récapitulatif", FrontDemandeAgrementVerticalMenu.MENU_VERTICAL_RECAPITULATIF, false)
        ]

        return <VerticalMenuComponent targetDomId={FrontDemandeAgrementFormPage.FORM_CONTAINER_ID} data={data} index={this.props.index} />
    }
}
