export default class LocalStorageService {
    setItem(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    setJsonItem(key: string, value: object) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    setBooleanItem(key: string, value: boolean) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    getItem(key: string) {
        return localStorage.getItem(key)
    }

    removeItem(key: string) {
        localStorage.removeItem(key)
    }

    getJsonItem(key: string) {
        const value = this.getItem(key)
        if (value != null) {
            return JSON.parse(value)
        } else {
            return null
        }
    }

    getBooleanItem(key: string) {
        return this.getJsonItem(key) as boolean
    }
}
