import { ReactElement } from "react"

import PersistentObject from "../../model/PersistentObject"

import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent"
import CheckboxComponent from "../../../generic/component/checkbox/CheckboxComponent"
import TextInputDataListItem from "../../../generic/component/form/input/TextInputDataListItem"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../service/http/HttpReferentielService"
import Response from "../../service/http/response/Response"
import BackOfficeComponent from "../BackOfficeComponent"
import IReferentielLocalFormState from "./BackOfficeIRefeferentielLocalFormState"
import BackOfficeIReferentielLocalFormProps from "./BackOfficeIReferentielLocalFormProps"

export default abstract class BackOfficeAbstractReferentielFormComponent<OBJECT extends PersistentObject, EXTRA_STATE> extends BackOfficeComponent<
    BackOfficeIReferentielLocalFormProps,
    IReferentielLocalFormState<OBJECT, EXTRA_STATE>
> {
    abstract getHttpReferentielService(): HttpReferentielService
    abstract formToObject(id: number): OBJECT
    abstract getContent(): ReactElement | undefined

    onComponentDidMount(object: OBJECT | null): void {
        // empty
    }

    componentDidMount() {
        if (this.props.objectId != null) {
            this.getHttpReferentielService()
                .getById<OBJECT>(this.props.objectId)
                .then((response) => {
                    this.onComponentDidMount(response.content as OBJECT)
                    this.setState({ item: response.content })
                })
        } else {
            this.onComponentDidMount(null)
        }
    }

    getDataList() {
        if (this.state && this.state.dataList) {
            const list: TextInputDataListItem[] = []
            this.state.dataList.forEach((item: string) => {
                list.push(new TextInputDataListItem(item, item))
            })
            return list
        }
    }

    save() {
        const object = this.formToObject(this.props.objectId)
        const response = this.getHttpReferentielService().save<OBJECT>(object)

        response.then((backResponse) => {
            const cast = new Response(backResponse)

            if (cast.errorMessage) {
                this.setState({ errorMessage: backResponse.errorMessage })
            } else if (cast.hasError()) {
                this.setState({ errors: cast.getErrorMap() })
            } else {
                this.props.showListHandler()
            }
        })
    }

    getCheckbox(id: string, label: string, condition: boolean) {
        return <CheckboxComponent label={label} id={id.toString()} checked={condition} />
    }

    // recupère dynamiquement une propriété de l'objet
    getProperty(propertyKey: string): string {
        if (this.state && this.state.item) {
            const object: OBJECT = this.state.item
            type ObjectKey = keyof typeof object
            const key = propertyKey as ObjectKey
            return object[key]! as unknown as string
        }
        return ""
    }

    getGlobalErrorMessage() {
        if (this.state && this.state.errorMessage) {
            return (
                <RowComponent style={{ marginBottom: "24px" }}>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={10}>
                        <AlertErrorComponent description={this.state.errorMessage} />
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
            )
        }
    }

    render() {
        return (
            <>
                {this.getGlobalErrorMessage()}
                {this.getContent()}
            </>
        )
    }
}
