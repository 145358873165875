import React from "react"

import SimpleFigureComponent from "../../../generic/component/figure/SimpleFigureComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import TextComponent from "../../../generic/component/text/TextComponent"

import { Constant } from "../../constant/Constant"
import BackOfficeComponent from "../BackOfficeComponent"
import signInItem1 from "./sign-in-item-1.png"
import signInItem2 from "./sign-in-item-2.png"
import signInItem3 from "./sign-in-item-3.png"

export default class BackOfficeInformationPanelComponent extends BackOfficeComponent<any, any> {
    private figureWidth = "70px"

    constructor(props: any) {
        super(props)
    }

    protected getStyle(): React.CSSProperties | undefined {
        if (this.props.style) {
            this.props.style.width = this.figureWidth
            return super.getStyle()
        } else {
            return { width: this.figureWidth }
        }
    }

    getFirstBox() {
        return (
            <>
                <ColumnComponent size={4} middle>
                    <SimpleFigureComponent image={signInItem1} style={this.getStyle()} />
                </ColumnComponent>
                <ColumnComponent size={8} middle>
                    <TextComponent>
                        <i>
                            L'application <u>{Constant.TEXT.BACK_OFFICE.APPLICATION_TITLE}</u> vous permet de consulter, gérer et suivre les demandes d'agréments déposées par les établissements
                            médico-sociaux, ainsi que les accords de branche.
                        </i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    getSecondBox() {
        return (
            <>
                <ColumnComponent size={4} middle>
                    <SimpleFigureComponent image={signInItem2} style={this.getStyle()} />
                </ColumnComponent>
                <ColumnComponent size={8} middle>
                    <TextComponent>
                        <i>Pour avoir accès à cette application, il vous suffit de vous identifier en utilisant votre login et votre mot de passe fournis par le gestionaire de l'application.</i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    getThirdBox() {
        return (
            <>
                <ColumnComponent size={4} middle>
                    <SimpleFigureComponent image={signInItem3} style={this.getStyle()} />
                </ColumnComponent>
                <ColumnComponent size={8} middle>
                    <TextComponent>
                        <i>
                            Lors de votre première connexion, merci de vous assurer que vous disposez bien des droits d'accès à l'application. Si ce n'est pas le cas, merci de vous rapprocher de
                            l'administrateur afin qu'il vous en autorise l'accès.
                        </i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    render() {
        return (
            <RowComponent middle>
                {this.getFirstBox()}
                {this.getSecondBox()}
                {this.getThirdBox()}
            </RowComponent>
        )
    }
}
