import DemandeDetails from "../../model/DemandeDetails"
import FullAvisFinanceur from "../../model/FullAvisFinanceur"

import BackOfficeDemandeAgrementForm from "../../back/common/BackOfficeDemandeAgrementForm"
import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpBackInstruction extends HttpService {
    BACK_GESTION_INSTRUCTION_URI = "/back/gestion/instruction"

    updateDemande(demandeId: number, etat: string, commentaire?: string) {
        if (commentaire != null) {
            return this.updateDemande3Params(demandeId, etat, commentaire!)
        } else {
            return this.updateDemande2Params(demandeId, etat)
        }
    }

    private updateDemande2Params(demandeId: number, etat: string) {
        return this.request<Response<BackOfficeDemandeAgrementForm>>(this.BACK_GESTION_INSTRUCTION_URI + "/" + etat + "?demandeId=" + demandeId.toString(), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    private updateDemande3Params(demandeId: number, etat: string, commentaire: string) {
        return this.request<Response<BackOfficeDemandeAgrementForm>>(this.BACK_GESTION_INSTRUCTION_URI + "/" + etat + "?demandeId=" + demandeId.toString(), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(commentaire),
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDetailsByDemande(demandeId: number) {
        return this.request<Response<DemandeDetails>>(this.BACK_GESTION_INSTRUCTION_URI + "/details?demandeId=" + demandeId.toString(), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDaccordStatus(id: string) {
        // /back/gestion/instruction/daccord/{id}
        return this.request<Response<string>>(this.BACK_GESTION_INSTRUCTION_URI + "/daccord/"+id)
            .then((response) => this.backSessionExpired(response))
    }
}
