export default class FormService {
    private static INSTANCE: FormService | null = null

    static getInstance(): FormService {
        if (this.INSTANCE == null) {
            this.INSTANCE = new FormService()
        }
        return this.INSTANCE
    }

    getRadioButtonsValue(nameAttributeValue: string): string {
        let result = ""

        try {
            const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('[type="radio"][name="' + nameAttributeValue + '"]')
            inputs.forEach((item) => {
                if (item.checked) {
                    result = item.value
                }
            })
        } catch (e: any) {
            console.log("Exception à la recherche de la valeur des radios [" + nameAttributeValue + "]")
        }

        return result
    }
}
