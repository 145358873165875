import React from "react"

import SimpleFigureComponent from "../../../generic/component/figure/SimpleFigureComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import TextComponent from "../../../generic/component/text/TextComponent"

import FrontComponent from "../FrontComponent"
import pictoPng1 from "./picto-1.png"
import pictoPng2 from "./picto-2.png"
import pictoPng3 from "./picto-3.png"
import pictoPng4 from "./picto-4.png"

export default class FrontInformationPanelComponent extends FrontComponent<any, any> {
    constructor(props: any) {
        super(props)
    }

    getFirstBox() {
        return (
            <>
                <ColumnComponent size={4}>
                    <SimpleFigureComponent image={pictoPng1} style={{ width: "100px" }} />
                </ColumnComponent>
                <ColumnComponent size={8}>
                    <TextComponent>
                        <i>
                            <b>Une fois votre accord déposé auprès de la Direction Générale du Travail ou décision unilatérale de l’employeur (DUE) prise</b>, la procédure prévue par les articles
                            L.314-6 et R.314-197 à R.314-200 du code de l'action sociale et des familles nécessite une demande d'agrément pour à cet accord (ou DUE).
                        </i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    getSecondBox() {
        return (
            <>
                <ColumnComponent size={4}>
                    <SimpleFigureComponent image={pictoPng2} style={{ width: "100px" }} />
                </ColumnComponent>
                <ColumnComponent size={8}>
                    <TextComponent>
                        <i>
                            <b>Ce site vous permet d'accomplir cette demande d'agrément en ligne dans des conditions sécurisées et simplifiées.</b>
                        </i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    getThirdBox() {
        return (
            <>
                <ColumnComponent size={4}>
                    <SimpleFigureComponent image={pictoPng3} style={{ width: "100px" }} />
                </ColumnComponent>
                <ColumnComponent size={8}>
                    <TextComponent>
                        <i>
                            A l'issue de la procédure de demande d'agrément en ligne (après avis de la CNA ou quatre mois, délai de décision tacite), une notification vous sera transmise indiquant le
                            sens de la décision (favorable ou défavorable).
                        </i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    getFourthBox() {
        return (
            <>
                <ColumnComponent size={4}>
                    <SimpleFigureComponent image={pictoPng4} style={{ width: "100px" }} />
                </ColumnComponent>
                <ColumnComponent size={8}>
                    <TextComponent>
                        <i>A tout moment, suite à votre dépôt, vous pouvez vous connecter à nouveau pour suivre l'état d'avancement de votre demande d'agrément.</i>
                    </TextComponent>
                </ColumnComponent>
            </>
        )
    }

    render() {
        return (
            <RowComponent middle>
                {this.getFirstBox()}
                {this.getSecondBox()}
                {this.getThirdBox()}
                {this.getFourthBox()}
            </RowComponent>
        )
    }
}
