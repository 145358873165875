import { DnumProps } from "../../../generic/component/DnumComponent"

import BackOfficeComponent from "../BackOfficeComponent"

interface ModalDeleteProps extends DnumProps {
    title: string
    message: string
    deleteButtonHandler: () => void
}

export default class BackOfficeModalDeleteComponent extends BackOfficeComponent<ModalDeleteProps, any> {
    render() {
        return (
            <dialog aria-labelledby={this.props.id + "-title"} id={this.props.id} className="fr-modal" role="dialog">
                <div className="fr-container fr-container--fluid fr-container-md">
                    <div className="fr-grid-row fr-grid-row--center">
                        <div className="fr-col-12 fr-col-md-8 fr-col-lg-6">
                            <div className="fr-modal__body">
                                <div className="fr-modal__header">&nbsp;</div>
                                <div className="fr-modal__content">
                                    <h1 id={this.props.id + "-title"} className="fr-modal__title">
                                        <span className="fr-fi-arrow-right-line fr-fi--lg" aria-hidden="true"></span>
                                        {this.props.title}
                                    </h1>
                                    <p>{this.props.message}</p>
                                </div>
                                <div className="fr-modal__footer">
                                    <ul className="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
                                        <li>
                                            <button className="fr-btn fr-fi-checkbox-line" onClick={() => this.props.deleteButtonHandler()} aria-controls={this.props.id}>
                                                Supprimer
                                            </button>
                                        </li>
                                        <li>
                                            <button className="fr-btn fr-btn--close fr-fi-checkbox-line fr-btn--secondary" aria-controls={this.props.id}>
                                                Annuler
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        )
    }
}
