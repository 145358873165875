import ErrorResponse from "../../service/http/response/ErrorResponse"

export default class ErrorService {
    isErrorKeyPresent(key: string, errors: ErrorResponse[] | null | undefined) {
        let result = false
        if (errors != undefined) {
            errors?.forEach((value: ErrorResponse) => {
                if (value.errorKey == key) {
                    result = true
                }
            })
        }
        return result
    }

    getErrorMessage(key: string, errors: ErrorResponse[] | null | undefined) {
        let message = undefined
        errors?.forEach((value: ErrorResponse) => {
            if (value.errorKey == key) {
                message = value.errorMessage!
            }
        })
        return message
    }
}
