import React, { ChangeEvent, ReactElement } from "react";
import Button from "../../../generic/component/button/Button";
import ControlledTextInputComponent from "../../../generic/component/form/input/ControlledTextInputComponent";
import SecondaryContentBoxComponent from "../../shared/component/box/SecondaryContentBoxComponent";
import BackOfficeComponent from "../BackOfficeComponent";
import HttpBackMaintenanceService from "../../service/http/HttpBackMaintenanceService";
import ButtonGroupHorizontal from "../../../generic/component/button/group/ButtonGroupHorizontal";
import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent";
import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent";

interface BackOfficeMaintenanceRefreshFinessDataComponentState {
    email: string
    response: ReactElement |null
}

export default class BackOfficeMaintenanceWithEmailComponent extends BackOfficeComponent<any ,BackOfficeMaintenanceRefreshFinessDataComponentState>{

    EMAIL_ERROR_MESSAGE = "Veuillez entrer un email"
    UPDATE_PASSWORD = "Le mot de passe a bien été mis à jour"

    protected constructor(props: any) {
        super(props)
        this.state = {email: "", response: null}
    }

    updatePassword():void{
        new HttpBackMaintenanceService().updatePassword(this.state.email).then((response: string) => {
            if (this.state.email == ""){
                this.setState({email: "", response: <AlertErrorComponent description={this.EMAIL_ERROR_MESSAGE} />})
            } else if (response == "ERREUR"){
                this.setState({email: "", response: <AlertErrorComponent description={this.EMAIL_ERROR_MESSAGE + " valide"} />})
            } else {
                this.setState({email:"", response: <AlertSuccessComponent description={this.UPDATE_PASSWORD} />})
            }
        })
    }

    render(){
        return(
            <SecondaryContentBoxComponent title={"Génération d'un nouveau mot de passe"}>
                <ControlledTextInputComponent
                    label={"Email du compte (membre CNA / organisme financeur / gestionnaire / admin) : "}
                    id="email"
                    value={this.state.email}
                    onChangeHandler={(event: ChangeEvent<HTMLInputElement>) => this.setState({...this.state, email: event.target.value})}
                />
                <div>{this.state.response}</div><br/>
                <ButtonGroupHorizontal>
                    <Button onClick={() => this.updatePassword()}>
                        Mettre à jour le mot de passe
                    </Button>
                </ButtonGroupHorizontal>
            </SecondaryContentBoxComponent>
        )
    }
}
