import React from "react"

import TypeFinanceur from "../../../model/TypeFinanceur"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeTypeFinanceurListComponent extends BackOfficeAbstractReferentielListComponent<TypeFinanceur> {
    getLine(item: TypeFinanceur, index: number): React.ReactElement {
        return (
            <tr key={"row-type-financeur-" + item.id}>
                <td></td>
                <td>{item.nom}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"type-financeur-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"type-financeur-" + item.id}
                        title={"Suppression du type de financeur : " + item.nom}
                        message={"Vous confirmez la suppression de ce type d'autorité de tarification ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </td>
                <td></td>
            </tr>
        )
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "20%" }}></th>
                    <th scope="col" style={{ width: "30%" }}>
                        Nom
                    </th>
                    <th scope="col" style={{ width: "30%" }}>
                        Actions
                    </th>
                    <th scope="col" style={{ width: "20%" }}></th>
                </tr>
            </thead>
        )
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useTypeFinanceurService()
    }

    getAddLabel(): string {
        return "Ajouter un type"
    }
}
