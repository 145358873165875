import React, { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface AccordionProps extends DnumProps {
    title: string | ReactElement
    index: number
    id: string
}

export default class AccordionComponent extends DnumComponent<AccordionProps, any> {
    render() {
        const id = "accordion-" + this.props.id + "-" + this.props.index

        return (
            <section className="fr-accordion">
                <h3 className="fr-accordion__title">
                    <button className="fr-accordion__btn" aria-expanded="false" aria-controls={id}>
                        {this.props.title}
                    </button>
                </h3>
                <div className="fr-collapse" id={id}>
                    <span>{this.props.children}</span>
                </div>
            </section>
        )
    }
}
