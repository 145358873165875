import { CSSProperties, ReactElement } from "react"

import AccoladeComponent from "../../../accolade/AccoladeComponent"

import TabData from "./TabData"

interface TabComponentProps {
    data: TabData[]
    style?: CSSProperties
}

export default class TabComponent extends AccoladeComponent<TabComponentProps, any> {
    constructor(props: TabComponentProps) {
        super(props)
    }

    getHeaders() {
        const headers: ReactElement[] = []
        this.props.data.forEach((tabData: TabData, index: number) => {
            headers.push(this.getHeader(tabData.label!, index))
        })
        return (
            <ul className="fr-tabs__list" role="tablist" aria-label="[A modifier | nom du système d'onglet]">
                {headers}
            </ul>
        )
    }

    getHeader(label: string, index: number) {
        let selected = true
        if (index > 0) {
            selected = false
        }
        return (
            <li role="presentation" key={"header-" + index}>
                <button id={"tabpanel-" + index} className="fr-tabs__tab" tabIndex={-1} role="tab" aria-selected={selected} aria-controls={"tabpanel-" + index + "-panel"}>
                    {label}
                </button>
            </li>
        )
    }

    getBodies() {
        const bodies: ReactElement[] = []
        this.props.data.forEach((tabData: TabData, index: number) => {
            bodies.push(this.getBody(tabData.content!, index))
        })
        return bodies
    }

    getBody(element: ReactElement | JSX.Element, index: number) {
        let className = "fr-tabs__panel fr-tabs__panel--selected"
        if (index > 0) {
            className = "fr-tabs__panel"
        }
        return (
            <div
                id={"tabpanel-" + index + "-panel"}
                className={className}
                role="tabpanel"
                aria-labelledby={"tabpanel-" + index}
                tabIndex={-1}
                key={"tab-content-" + index}
                style={{ ...this.props.style }}>
                {element}
            </div>
        )
    }

    render() {
        return (
            <div className="fr-tabs">
                {this.getHeaders()}
                {this.getBodies()}
            </div>
        )
    }
}
