import UtilService from "../../generic/service/UtilService"

import AccoladeComponent from "../../accolade/AccoladeComponent"

import BackApplicationService from "../service/application/BackApplicationService"
import HttpService from "../service/http/HttpService"
import {DnumProps} from "../../generic/component/DnumComponent";

export default abstract class BackOfficeComponent<P extends DnumProps, S> extends AccoladeComponent<P, S> {
    httpService: HttpService = new HttpService()
    utilService: UtilService = new UtilService()
    applicationService: BackApplicationService = new BackApplicationService()
}
