import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeMotifRefusFormComponent from "./BackOfficeMotifRefusFormComponent"
import BackOfficeMotifRefusListComponent from "./BackOfficeMotifRefusListComponent"

export default class BackOfficeMotifRefusPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Motifs de refus"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeMotifRefusFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <BackOfficeMotifRefusListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__MOTIF_REFUS
    }
}
