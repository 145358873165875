import { Constant } from "../../constant/Constant"
import BackOfficeService from "../BackOfficeService"
import EspaceFinanceurService from "../EspaceFinanceurService"
import FrontService from "../FrontService"
import HttpServiceEnv from "./HttpServiceEnv"
import Response from "./response/Response"

export default class HttpService extends HttpServiceEnv {

    CEGEDIM_ANTIVIRUS_ERROR_CODE = [400]

    // Make the `request` function generic
    // to specify the return data type:
    request<TResponse>(
        url: string,
        // `RequestInit` is a type for configuring
        // a `fetch` request. By default, an empty object.
        config: RequestInit = {}
        // This function is async, it will return a Promise:
    ): Promise<TResponse> {
        // Inside, we call the `fetch` function with
        // a URL and config given:
        return (
            fetch(this.contextPath + url, config)
                .then((response) => {
                    const status = response.status
                    return response.json().then((body) => {
                        if (this.CEGEDIM_ANTIVIRUS_ERROR_CODE.includes(status)) {
                            return {
                                success: false,
                                errorMessage: body.error
                            }
                        } else return body
                    })
                })
                // and return the result data.
                .then((data) => data as TResponse)
        )

        // We also can use some post-response
        // data-transformations in the last `then` clause.
    }

    frontSessionExpired = function (response: Response<any>) {
        const cast = new Response(response)
        if (cast.errors && cast.errors?.length > 0 && cast.errors[0].errorKey == "session-expired") {
            FrontService.getInstance().switchPage(Constant.DOM.FRONT.HOME_PAGE_SESSION_EXPIRED)
            return null
        }
        return response
    }

    backSessionExpired = function (response: Response<any>) {
        const cast = new Response(response)
        if (cast.errors && cast.errors?.length > 0 && cast.errors[0].errorKey == "session-expired") {
            BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.LOGIN_PAGE)
            return null
        }
        return response
    }

    espaceFinanceurSessionExpired = function (response: Response<any>) {
        const cast = new Response(response)
        if (cast.errors && cast.errors?.length > 0 && cast.errors[0].errorKey == "session-expired") {
            EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.LOGIN_PAGE)
            return null
        }
        return response
    }

    /*
    manageFrontSessionExpired = (response: Response<any>) => {
        const cast = new Response(response)
        if (cast.errors && cast.errors?.length>0 && cast.errors[0].errorKey=="session-expired") {
            FrontService.getInstance().switchPage(Constant.DOM.FRONT.HOME_PAGE_SESSION_EXPIRED)
            return null
        }
        return response
    }
*/
}
