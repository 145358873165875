import React from "react"

import FooterComponent from "../../../generic/component/footer/FooterComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import PageComponent from "../../../generic/component/grid/page/PageComponent"
import HeaderComponent from "../../../generic/component/header/HeaderComponent"

import { Constant } from "../../constant/Constant"
import FrontService from "../../service/FrontService"
import FrontApplicationService from "../../service/application/FrontApplicationService"
import IPageCoordinator from "../../service/contract/IPageCoordinator"
import FrontPage from "../FrontPage"
import FrontRouter from "./FrontRouter"

interface MainPageState {
    pageId: string
    connected: boolean
    pageData: Map<string, any>
}

export default class FrontMainPage extends FrontPage<any, MainPageState> implements IPageCoordinator {
    applicationService = new FrontApplicationService()
    router = new FrontRouter(this.applicationService)

    constructor(props: any) {
        super(props)
        FrontService.getInstance().setPageCoordinator(this)
    }

    getPageSwitcher() {
        return (pageId: any, pageData: Map<string, any>) => {
            this.setState({ pageId: pageId, pageData: pageData })
        }
    }

    isUserConnected(): boolean {
        return this.applicationService!.isUserConnected()
    }

    logout() {
        this.applicationService.logout()
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.HOME_PAGE)
        // moche
        document.getElementById("logoutButton")!.style.display = "none"
    }

    showMyAccount() {
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_ACCOUNT)
    }

    componentDidMount() {
        // gestion des ancres pour le pages faq, mentions légales et contacts
        if (self.location.hash.endsWith("#faq")) {
            this.setState({
                pageId: Constant.DOM.FRONT.FAQ_PAGE,
                connected: false
            })
        } else if (self.location.hash.endsWith("#mentions-legales")) {
            this.setState({
                pageId: Constant.DOM.FRONT.LEGAL_NOTICE_PAGE,
                connected: false
            })
        } else if (self.location.hash.endsWith("#contacts")) {
            this.setState({
                pageId: Constant.DOM.FRONT.CONTACT_PAGE,
                connected: false
            })
        } else if (self.location.hash.endsWith("#signature")) {
            this.setState({
                pageId: Constant.DOM.FRONT.SIGNATURE_VALIDATION_PAGE,
                connected: false
            })
        } else if (self.location.hash.startsWith("#confirmation")) {
            this.setState({
                pageId: Constant.DOM.FRONT.CONFIRMATION_PAGE,
                connected: false
            })
        } else {
            this.setState({
                pageId: Constant.DOM.FRONT.HOME_PAGE,
                connected: false
            })
        }
    }

    getToolLinks(): any[] {
        const toolLinks = []
        if (this.isUserConnected()) {
            toolLinks.push(
                <a href="#compte" className="fr-link fr-fi-account-fill" rel="noopener" title="Mon compte" id="mon-compte" onClick={() => this.showMyAccount()}>
                    Mon compte
                </a>
            )
        }
        toolLinks.push(
            <a
                href="#contact"
                className="fr-link fr-fi-question-fill"
                rel="noopener"
                title="Nous contacter - nouvelle fenêtre"
                onClick={() => FrontService.getInstance().switchPage(Constant.DOM.FRONT.CONTACT_PAGE)}>
                {" "}
                Nous contacter
            </a>
        )

        if (this.isUserConnected()) {
            toolLinks.push(
                <a href="#logout" className="fr-link fr-fi-logout-box-r-fill" rel="noopener" title="Déconnexion" id="logoutButton" onClick={() => this.logout()}>
                    Déconnexion
                </a>
            )
        }
        //toolLinks.push(<button className="fr-link fr-fi-theme-fill fr-link--icon-left" aria-controls="fr-theme-modal" data-fr-opened="false">Param&egrave;tres d affichage</button>)
        return toolLinks
    }

    getBottomLinks(): any[] {
        const bottomLinks = []
        bottomLinks.push(
            <a className="fr-footer__bottom-link" onClick={() => FrontService.getInstance().switchPage(Constant.DOM.FRONT.LEGAL_NOTICE_PAGE)} href="#">
                Mentions légales
            </a>
        )
        bottomLinks.push(
            <a className="fr-footer__bottom-link" onClick={() => FrontService.getInstance().switchPage(Constant.DOM.FRONT.FAQ_PAGE)} href="#faq">
                FAQ
            </a>
        )
        bottomLinks.push(
            <a className="fr-footer__bottom-link" onClick={() => FrontService.getInstance().switchPage(Constant.DOM.FRONT.CONTACT_PAGE)} href="#contact">
                Contact
            </a>
        )
        return bottomLinks
    }

    getContent() {
        if (this.state) {
            return this.router.search(this.state.pageId, this.state.pageData)
        }
    }

    render() {
        return (
            <PageComponent fluid>
                <HeaderComponent marianneTextFirstPart={"République"} marianneTextSecondPart={"française"} siteName={"Demande d'agrément des accords"} toolLinks={this.getToolLinks()} />

                <ContainerComponent>{this.getContent()}</ContainerComponent>

                <FooterComponent marianneTextFirstPart={"République"} marianneTextSecondPart={"française"} bottomLinks={this.getBottomLinks()} />
            </PageComponent>
        )
    }
}
