import React from "react"

import Signature from "../../../model/Signature"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"

export default class BackOfficeSignatureListComponent extends BackOfficeAbstractReferentielListComponent<Signature> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useSignatureService()
    }

    getAddLabel(): string {
        return "Ajouter une signature"
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "10%" }}>
                        Code
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                        Libellé
                    </th>
                    <th scope="col" style={{ width: "45%" }}>
                        Image
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getLine(item: Signature, index: number): React.ReactElement {
        return (
            <tr key={"row-signature-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.code}</td>
                <td>{item.libelle}</td>
                <td>
                    <img width="250" src={process.env.REACT_APP_CONTEXT_PATH + "/back/admin/referentiel/local/signature/image/" + item.uuid} alt={"Signature"} />
                </td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                </td>
            </tr>
        )
    }
}
