import React from "react"

import AccordionComponent from "../../../generic/component/accordion/AccordionComponent"
import AccordionGroupComponent from "../../../generic/component/accordion/group/AccordionGroupComponent"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import CustomHighlightComponent from "../../shared/component/highlight/CustomHighlightComponent"
import FrontPage from "../FrontPage"

export default class FrontFaqPage extends FrontPage<any, any> {
    render() {
        return (
            <PrimaryContentBoxComponent title="Questions fréquentes">
                <AccordionGroupComponent>
                    <AccordionComponent title="Pour quels types d'accord faut-il effectuer une demande d'agrément ?" id="group-faq" index={0}>
                        <CustomHighlightComponent>
                            <br />
                            Il s'agit de tous les accords et conventions applicables aux salariés des établissements sociaux et médico-sociaux privés à but non lucratif qui sont soumis à la procédure
                            d'agrément et mentionnés à l'article L314-6 du code de l'action sociale et des familles. Cette procédure est également applicable aux décisions unilatérales de l’employeur.
                            <br />
                            <br />
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Qui doit déposer la demande d'agrément ?" id="group-faq" index={1}>
                        <CustomHighlightComponent>
                            <br />
                            La demande d'agrément en ligne doit être déposée par une organisation signataire d’un accord collectif ou l'employeur.
                            <br />
                            <br />
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Quand déposer une demande d'agrément sur le site ?" id="group-faq" index={2}>
                        <CustomHighlightComponent>
                            <br />
                            Vous devez déposer votre demande d'agrément auprès du ministère en charge des affaires sociales après le dépôt de l'accord auprès des services du ministère chargé du
                            travail (procédure décrite sur le site service-public.fr dans la rubrique comment déposer un accord d'entreprise)
                            <br />
                            <a href="https://www.teleaccords.travail-emploi.gouv.fr/PortailTeleprocedures/" target="_blank">
                                Portail - Ministère du travail (travail-emploi.gouv.fr)
                            </a>
                            <br />
                            <br />
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Comment accéder au site ?" id="group-faq" index={3}>
                        <CustomHighlightComponent>
                            <>
                                <br />
                                Pour créer un compte utilisateur :
                                <ul style={{ listStyleType: "square" }}>
                                    <li>
                                        Se connecter sur{" "}
                                        <a href="https://accolade.social.gouv.fr" target="_blank">
                                            https://accolade.social.gouv.fr
                                        </a>
                                    </li>
                                    <li>Cliquer sur « Pas encore inscrit »</li>
                                    <li>Renseigner un courriel et définir un mot de passe</li>
                                    <li>Renseigner le code d’activation reçu par courriel</li>
                                    <li>Se connecter</li>
                                </ul>
                                <br />
                                Une fois connecté, il faudra :<br />
                                <br />
                                <u>1/ Compléter les informations relatives à l’entité juridique :</u>
                                <ul style={{ listStyleType: "square" }}>
                                    <li>
                                        Le numéro{" "}
                                        <a href="http://finess.sante.gouv.fr" target="_blank">
                                            FINESS
                                        </a>{" "}
                                        de votre entité juridique
                                    </li>
                                    <li>Le numéro SIREN</li>
                                    <li>
                                        L’identifiant de la convention collective (
                                        <a href="https://travail-emploi.gouv.fr/IMG/pdf/dares_donnes_identifiant_convention_collective_decembre22.pdf" target="_blank">
                                            IDCC
                                        </a>
                                        )* :{" "}
                                    </li>
                                    <li>Les informations financières : nombre d’ETP, masse salariale</li>
                                    <li>Le contact et l’adresse</li>
                                </ul>
                                <br />
                                * Selon l'article L. 2261-2 du code du travail, dans le cadre d'une association, c'est l'activité principale de l'employeur qui doit être prise en compte pour
                                déterminer la convention collective nationale applicable. Il n’y a donc qu’une seule convention collective à renseigner.
                                <br />
                                <br />
                                <u>2/ Compléter les informations relatives aux établissements rattachés à l’entité juridique :</u>
                                <ul>
                                    <li>Nombre d’ETP</li>
                                    <li>Masse salariale</li>
                                </ul>
                                Ces informations administratives et financières (diapositives précédentes) ne sont à renseigner qu’une seule fois. Ces données seront cependant à actualiser en cas de
                                modification.
                                <br />
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Comment renseigner une demande d'agrément ?" id="group-faq" index={4}>
                        <CustomHighlightComponent>
                            <>
                                <br />
                                Chaque accord concerné par la procédure d'agrément, conformément à l'article L 314-6 du code de l'action sociale et des familles doit faire l'objet d'une demande
                                d'agrément. Si plusieurs accords sont conclus à la même date et dans les mêmes conditions, ils impliquent plusieurs demandes d'agrément.
                                <br />
                                <br />
                                Pour chaque demande d'agrément d'un accord vous devez suivre chacune des étapes décrites sur le service en ligne. Vous pouvez à tout moment enregistrer vos démarches et
                                interrompre votre saisie puis la reprendre ultérieurement.
                                <br />
                                <br />
                                La saisie d’une demande d’agrément s’opère en plusieurs étapes
                                <br />
                                <ol>
                                    <li>Sélectionner les établissements concernés par la demande d’agrément</li>

                                    <li>
                                        Renseigner les données générales de l’accord
                                        <ol>
                                            <li>S’il s’agit d’un accord collectif / d’entreprise, cocher et renseigner le numéro de dossier + la date de dépôt + la date de signature</li>
                                            <li>S’il s’agit d’une décision unilatérale de l’employeur, renseigner la date de signature</li>
                                        </ol>
                                    </li>

                                    <li>
                                        Renseigner les effectifs et salariés concernés par la mesure : « ETP concernés par la mesure » est la seule information à remplir sur cette page. Les autres
                                        données ont déjà été remplies au cours des étapes précédentes
                                    </li>

                                    <li>Renseigner la typologie de l’accord de la demande en cours en choisissant dans la liste déroulante proposée</li>

                                    <li>Renseigner le coût des mesures proposées</li>
                                    <ol>
                                        <li>
                                            Si votre demande concerne l’agrément d’un accord sans impact financier, cochez la case « sans impact financier » et passez directement à l’étape des pièces
                                            jointes
                                        </li>
                                        <li>Les années N et N+1 sont obligatoirement renseignées</li>
                                        <li>Les données renseignées correspondent à des dépenses nouvelles.</li>
                                        <li>Les années N+2 et N+3 sont renseignées uniquement si des dépenses nouvelles interviennent en N+2 et en N+3.</li>
                                        <li>Remplir les différents champs (les calculs sont automatiques)</li>
                                        <ol>
                                            <li>
                                                Les ressources propres correspondent aux ressources hors gestion contrôlée par un financeur public. En d'autres termes, les excédents et les reports de
                                                crédits n’en font pas partie
                                            </li>
                                            <li>Les dotations /subventions correspondent aux financements des autorités de tarifications</li>
                                            <li>Les mesures nouvelles correspondent à une demande de financement supplémentaire aux financements publics</li>
                                            <li>Pour information, les coûts compris dans les prestations CNAV / CAF</li>
                                        </ol>
                                    </ol>

                                    <li>
                                        Ajouter les pièces jointes : joindre le texte de l’accord et toute pièce jointe permettant de faciliter le traitement de la demande et réduire le délai
                                        d’instruction. Le dépôt du texte de l’accord est obligatoire pour transmettre une demande d’agrément.
                                    </li>

                                    <li>Vérifier le récapitulatif et valider</li>
                                </ol>
                                <br />
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Comment puis-je connaître l'état d'avancement d'une demande d'agrément ?" id="group-faq" index={5}>
                        <CustomHighlightComponent>
                            <>
                                <br />
                                Dès l'envoi de votre demande d'agrément, un message signale que votre demande a été correctement transmise. A tout moment vous pouvez consulter l'état d'avancement de
                                la demande d'agrément dans l’onglet DEMANDES :
                                <ul style={{ listStyleType: "square" }}>
                                    <li>EN ATTENTE DE VOTRE ACTION : signifie que la demande a été sauvegardée mais qu’elle n’a pas été transmise par l’établissement</li>
                                    <li>EN COURS D’INSTRUCTION : signifie que la demande a été transmise et qu’elle est en cours d’instruction</li>
                                    <li>AGRÉÉE : signifie que la demande a été agréée</li>
                                    <li>REFUSÉE : signifie que la demande a été refusée</li>
                                </ul>
                                <br />
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title="Je n'ai pas trouvé de réponse à mes interrogations, que faire ?" id="group-faq" index={6}>
                        <CustomHighlightComponent>
                            <>
                                <br />
                                Vous pouvez contacter le bureau de l'emploi et de la politique salariale de la Direction générale de la cohésion sociale chargé de l'instruction de votre demande
                                d'agrément en lui adressant un message électronique à l'adresse suivante : <a href="mailto:DGCS-ACCORDS@social.gouv.fr">DGCS-ACCORDS@social.gouv.fr</a>.
                                <br />
                                <br />
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                </AccordionGroupComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
