import React from "react"

import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import FrontPage from "../../FrontPage"
import FrontChooseAccountTypeComponent from "./FrontChooseAccountTypeComponent"
import FrontConfirmAccountComponent from "./FrontConfirmAccountComponent2"
import FrontConfirmAccountComponent2 from "./FrontConfirmAccountComponent2"
import FrontCreateLoginPasswordFormComponent from "./FrontCreateLoginPasswordFormComponent"

interface FrontCreateAccountState {
    accountType: "ccn" | "association"
    view: string | null
}

export default class FrontCreateAccountPage extends FrontPage<any, FrontCreateAccountState> {
    changeView(key: string) {
        this.setState({ view: key })
    }

    getContent() {
        if (this.state) {
            switch (this.state.view) {
                case FrontChooseAccountTypeComponent.KEY:
                    return <FrontChooseAccountTypeComponent />
                case FrontCreateLoginPasswordFormComponent.KEY:
                    return <FrontCreateLoginPasswordFormComponent onCreate={() => this.changeView(FrontConfirmAccountComponent.KEY)} />
                case FrontConfirmAccountComponent2.KEY:
                    return <FrontConfirmAccountComponent2 />
            }
        } else {
            return <FrontCreateLoginPasswordFormComponent onCreate={() => this.changeView(FrontConfirmAccountComponent2.KEY)} />
        }
    }

    render() {
        return <PrimaryContentBoxComponent title={`Création de compte`}>{this.getContent()}</PrimaryContentBoxComponent>
    }
}
