import React from "react"

import AccountData from "../../../../model/AccountData"
import File from "../../../../model/File"

import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementForm from "../../FrontDemandeAgrementForm"
import FrontConsultationDemandeAgrementComponent from "../../consultation/FrontConsultationDemandeAgrementComponent"
import {DnumProps} from "../../../../../generic/component/DnumComponent";

interface FrontDemandeAgrementFormStep3AComponentProps extends DnumProps {
    demandeInProgress: FrontDemandeAgrementForm | undefined
    informations: AccountData | undefined
    fileAccordDue: File | undefined
    otherFiles: File[]
}

export default class FrontDemandeAgrementFormStep3AComponent extends FrontComponent<FrontDemandeAgrementFormStep3AComponentProps, any> {
    constructor(props: FrontDemandeAgrementFormStep3AComponentProps) {
        super(props)
    }

    render() {
        const array: File[] = this.props.otherFiles ?? []
        array.push(this.props.fileAccordDue!)
        return <FrontConsultationDemandeAgrementComponent demandeAgrement={this.props.demandeInProgress} files={array} informations={this.props.informations} />
    }
}
