import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface TextProps extends DnumProps {
    large?: null
    medium?: null
    small?: null
}

export default class TextComponent extends DnumComponent<TextProps, any> {
    private className = "fr-text"

    constructor(props: TextProps) {
        super(props)
    }

    getClassName() {
        this.className = "fr-text"
        if (this.props.large) {
            this.className += " fr-text-lg"
        } else if (this.props.medium) {
            this.className += " fr-display-md"
        } else if (this.props.small) {
            this.className += " fr-display-sm"
        }
        return this.className
    }

    render() {
        return <p className={this.className}>{this.props.children}</p>
    }
}
