export default class SelectOptionData {
    public id: string
    public label: string
    public selected: boolean

    constructor(id: string, label: string, selected: boolean) {
        this.id = id
        this.label = label
        this.selected = selected
    }
}
