import { Constant } from "../../constant/Constant"
import FrontApplicationService from "../../service/application/FrontApplicationService"
import FrontCreateAccountPage from "../account/create/FrontCreateAccountPage"
import FrontCreateAccountProcessPage from "../account/create/FrontCreateAccountProcessPage"
import FrontShowAccountPage from "../account/show/FrontShowAccountPage"
import FrontConfirmationPage from "../confirmation/FrontConfirmationPage"
import FrontContactPage from "../contact/FrontContactPage"
import FrontDemandeAgrementFormPage from "../demande/FrontDemandeAgrementFormPage"
import FrontDemandeAgrementSelectEtablissementsPage from "../demande/FrontDemandeAgrementSelectEtablissementsPage"
import FrontConsultationDemandeAgrementPage from "../demande/consultation/FrontConsultationDemandeAgrementPage"
import FrontFaqPage from "../faq/FrontFaqPage"
import FrontHomePage from "../home/FrontHomePage"
import FrontLegalNoticePage from "../legalnotice/FrontLegalNoticePage"
import FrontSignaturePage from "../signature/FrontSignaturePage"

export default class FrontRouter {
    applicationService: FrontApplicationService

    constructor(applicationService: FrontApplicationService) {
        this.applicationService = applicationService
    }

    search(pageId: string, pageData: Map<string, any> | undefined) {
        switch (pageId) {
            case Constant.DOM.FRONT.HOME_PAGE:
                return this.applicationService.isUserConnected() ? <FrontShowAccountPage /> : <FrontHomePage />
            case Constant.DOM.FRONT.HOME_PAGE_SESSION_EXPIRED:
                return <FrontHomePage cause="session-expired" />
            case Constant.DOM.FRONT.DEMANDE_AGREMENT_ETABLISSEMENTS_PAGE:
                return <FrontDemandeAgrementSelectEtablissementsPage />
            case Constant.DOM.FRONT.DEMANDE_AGREMENT_FORM_PAGE:
                return <FrontDemandeAgrementFormPage currentId={pageData?.get(Constant.KEY.FRONT.DEMANDE_ID_PARAM)} edition={pageData?.get(Constant.KEY.FRONT.EDITION_PARAM)} />
            case Constant.DOM.FRONT.FAQ_PAGE:
                return <FrontFaqPage />
            case Constant.DOM.FRONT.SIGNATURE_VALIDATION_PAGE:
                return <FrontSignaturePage />
            case Constant.DOM.FRONT.CONFIRMATION_PAGE:
                return <FrontConfirmationPage />
            case Constant.DOM.FRONT.LEGAL_NOTICE_PAGE:
                return <FrontLegalNoticePage />
            case Constant.DOM.FRONT.CONTACT_PAGE:
                return <FrontContactPage />
            case Constant.DOM.FRONT.CREATE_ACCOUNT:
                return <FrontCreateAccountPage />
            case Constant.DOM.FRONT.SET_DETAILS_ACCOUNT:
                return <FrontCreateAccountProcessPage />
            case Constant.DOM.FRONT.SHOW_ACCOUNT:
                return <FrontShowAccountPage />
            case Constant.DOM.FRONT.SHOW_DEMANDE_AGREMENT_PAGE:
                return (
                    <FrontConsultationDemandeAgrementPage
                        demandeInProgress={pageData?.get(Constant.KEY.FRONT.DEMANDE_OBJECT_PARAM)}
                        informations={pageData?.get(Constant.KEY.FRONT.ACCOUNT_INFORMATIONS_PARAM)}
                    />
                )
        }
    }
}
