import FileType from "./FileType"

export default class File {
    static TYPE = FileType

    id: number | null = null
    originalName: string | null = null
    type: string | null = null
    extension: string | null = null
    key: string | null = null
    externalKey: string | null = null
    parentType: string | null = null
    deleted: boolean | null = null
    dateCreation: string | null = null
}
