import { Fragment } from "react"

import Cna from "../../model/Cna"

import { DnumProps } from "../../../generic/component/DnumComponent"
import Button from "../../../generic/component/button/Button"
import DateInputComponent from "../../../generic/component/form/input/DateInputComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import UiService from "../../service/ui/UiService"
import BackOfficeComponent from "../BackOfficeComponent"

interface BackOfficeFormCnaComponentState {
    cna: Cna | null
}

interface BackOfficeFormCnaComponentProps extends DnumProps {
    cna?: Cna | null
    errors: Map<string, string> | undefined
    saveCna: () => void
    cancelView: () => void
}

export default class BackOfficeFormCnaComponent extends BackOfficeComponent<BackOfficeFormCnaComponentProps, BackOfficeFormCnaComponentState> {
    constructor(props: BackOfficeFormCnaComponentProps) {
        super(props)
        this.setState({
            cna: null
        })
    }

    render() {
        //console.log(JSON.stringify(this.props.errors))

        return (
            <Fragment>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={10}>
                        <DateInputComponent
                            label={"Date de la CNA"}
                            id={"date-cna"}
                            errorMessage={this.props.errors?.get("datePrevue")}
                            error={this.props.errors?.has("datePrevue")}
                            value={UiService.formatServerDateToDatePicker(this.props.cna?.datePrevue)}
                        />
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
                <RowComponent style={{ marginTop: "30px", marginBottom: "30px" }}>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={2}>
                        <Button onClick={() => this.props.saveCna()}>Valider</Button>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={2}>
                        <Button onClick={() => this.props.cancelView()}>Annuler</Button>
                    </ColumnComponent>
                    <ColumnComponent size={4} />
                </RowComponent>
            </Fragment>
        )
    }
}
