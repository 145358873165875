import React, { MouseEventHandler, ReactElement } from "react"

import DnumComponent, { DnumProps } from "../../DnumComponent"
import VerticalMenuItemData from "./VerticalMenuItemData"

interface VerticalMenuProps extends DnumProps {
    title?: string
    targetDomId: string
    data: VerticalMenuItemData[]
    index?: number | undefined
}

interface VerticalMenuState {
    selectedIndex: number
}

export default class VerticalMenuComponent<P extends VerticalMenuProps, S extends VerticalMenuState> extends DnumComponent<P, S> {
    constructor(props: P) {
        super(props)
    }

    getTitle() {
        if (this.props.title) {
            return <div className="fr-sidemenu__title">${this.props.title}</div>
        }
    }

    setState_SelectedIndex(index: number) {
        this.setState({ selectedIndex: index })
    }

    getState_SelectedIndex() {
        if (this.state) {
            return this.state.selectedIndex
        } else {
            return 0
        }
    }

    switchVerticalMenuItem(index: number) {
        this.setState_SelectedIndex(index)
        this.props.data.forEach((item, loopIndex) => {
            const domId = `${this.props.targetDomId}${loopIndex + 1}`
            document.getElementById(domId)!.style.display = "none"
        })
        document.getElementById(`${this.props.targetDomId}${index + 1}`)!.style.display = "block"
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
        if (prevProps != null && prevProps.index != this.props.index) {
            this.setState({ selectedIndex: this.props.index ?? 0 })
        }
    }

    getItem(label: string, index: number, selectedIndex: number, onClick: MouseEventHandler, externId?: string | null): ReactElement {
        const elementId = `vertical-menu-${this.generateId()}-`

        let idProp = {}
        if (externId != null) {
            idProp = { id: externId }
        }

        if (index === selectedIndex) {
            return (
                <li key={`${elementId}-${index}`} id={elementId} className="fr-sidemenu__item fr-sidemenu__item--active">
                    <a className="fr-sidemenu__link" href="#" {...idProp} onClick={onClick} aria-current="page">
                        {label}
                    </a>
                </li>
            )
        } else {
            return (
                <li key={`${elementId}-${index}`} id={elementId} className="fr-sidemenu__item">
                    <a className="fr-sidemenu__link" href="#" {...idProp} onClick={onClick}>
                        {label}
                    </a>
                </li>
            )
        }
    }

    getMenuItems(): ReactElement[] {
        const children: ReactElement[] = []
        this.props.data.forEach((item, index) => {
            if (item.clickable) {
                children.push(this.getItem(item.label!, index, this.getState_SelectedIndex(), () => this.switchVerticalMenuItem(index), item.id))
            } else {
                children.push(
                    this.getItem(
                        item.label!,
                        index,
                        this.getState_SelectedIndex(),
                        () => {
                            console.debug("nothing")
                        },
                        item.id
                    )
                )
            }
        })
        return children
    }

    render() {
        return (
            <nav className="fr-sidemenu" aria-label="Menu latéral">
                <div className="fr-sidemenu__inner">
                    <button className="fr-sidemenu__btn" hidden aria-controls="fr-sidemenu-wrapper" aria-expanded="false">
                        Menu
                    </button>
                    <div className="fr-collapse" id="fr-sidemenu-wrapper">
                        {this.getTitle()}
                        <ul className="fr-sidemenu__list">{this.getMenuItems()}</ul>
                    </div>
                </div>
            </nav>
        )
    }
}
