import React from "react"

import Button from "../Button"
import logoPdf from "./logoPdf.png"

export default class ButtonPdf extends Button {
    constructor(props: any) {
        super(props)
    }

    render(): JSX.Element {
        return (
            <button title={"Télécharger PDF"} style={{ marginTop: "8px" }} className={this.className} onClick={this.props.onClick}>
                <img width={20} height={20} alt={"PDF"} src={logoPdf} style={{ paddingRight: "4px" }} />
                <span>Télécharger</span>
            </button>
        )
    }
}
