import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeOrganismeFinanceurFormComponent from "./BackOfficeOrganismeFinanceurFormComponent"
import BackOfficeOrganismeFinanceurListComponent from "./BackOfficeOrganismeFinanceurListComponent"

export default class BackOfficeOrganismeFinanceurPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Organismes des autorités de tarification"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeOrganismeFinanceurFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return (
            <BackOfficeOrganismeFinanceurListComponent
                forceRefresh={forceRefresh}
                addButtonHandler={addButtonHandler}
                editButtonHandler={editButtonHandler}
                deleteButtonHandler={deleteButtonHandler}
            />
        )
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__ORGANISME_FINANCEUR
    }
}
