import React, { Fragment, ReactElement } from "react"

import ContactFinanceur from "../../model/ContactFinanceur"
import ContactFinanceurInformation from "../../model/ContactFinanceurInformation"
import FullAvisFinanceur from "../../model/FullAvisFinanceur"

import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent"
import BadgeGreenMintComponent from "../../../generic/component/badge/BadgeGreenMintComponent"
import BadgeInfoComponent from "../../../generic/component/badge/BadgeInfoComponent"
import BadgeWarningComponent from "../../../generic/component/badge/BadgeWarningComponent"
import Button from "../../../generic/component/button/Button"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import TabComponent from "../../../generic/component/tab/TabComponent"
import TabData from "../../../generic/component/tab/TabData"

import { Constant } from "../../constant/Constant"
import EspaceFinanceurApplicationService from "../../service/application/EspaceFinanceurApplicationService"
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService"
import FinanceurMainResponse from "../../service/http/response/FinanceurMainResponse"
import Response from "../../service/http/response/Response"
import LocalStorageService from "../../service/storage/LocalStorageService"
import UiService from "../../service/ui/UiService"
import SecondaryContentBoxComponent from "../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import EspaceFinanceurPage from "../EspaceFinanceurPage"
import EspaceFinanceurRouter from "../EspaceFinanceurRouter"
import EspaceFinanceurConsultationDemandeAgrementComponent from "./EspaceFinanceurConsultationDemandeAgrementComponent"
import EspaceFinanceurDemandeAgrementForm from "./EspaceFinanceurDemandeAgrementForm"
import EspaceFinanceurToutesLesDemandesComponent from "./EspaceFinanceurToutesLesDemandesComponent";
import {DnumProps} from "../../../generic/component/DnumComponent";

interface EspaceFinanceurHomePageState {
    avisListTodo: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>[] | undefined
    avisListEnAttenteCna: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>[] | undefined
    avisListCnaDone: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>[] | undefined
    mode: "list" | "avisTodo" | "avisDone" | "confirmAccepte" | "confirmRefuse"
    current: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm> | undefined
    contactFinanceur: ContactFinanceur | undefined
    commentaire: string | undefined
    errorCommentaire: boolean
    errorConfirmation: string | null
}

interface EspaceFinanceurHomePageProps extends DnumProps {
    contactFinanceur: ContactFinanceur | undefined
}

export default class EspaceFinanceurHomePage extends EspaceFinanceurPage<EspaceFinanceurHomePageProps, EspaceFinanceurHomePageState> {
    constructor(props: EspaceFinanceurHomePageProps) {
        super(props)
        this.state = {
            avisListTodo: undefined,
            avisListCnaDone: undefined,
            avisListEnAttenteCna: undefined,
            current: undefined,
            mode: "list",
            commentaire: undefined,
            contactFinanceur: undefined,
            errorCommentaire: false,
            errorConfirmation: null
        }
    }

    componentDidMount() {
        try {
            const espaceFinanceurHttp = new HttpEspaceFinanceurService()

            espaceFinanceurHttp.getStatus().then((response) => {
                if (response.success) {
                    espaceFinanceurHttp.getAvis().then((response) => {
                        const cast = new Response<FinanceurMainResponse>(response)
                        this.manageResponse(cast)
                    })
                } else {
                    this.logout()
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    manageResponse(cast: Response<FinanceurMainResponse>) {
        if (cast.success && cast.content) {
            this.setState({
                avisListTodo: cast.content.avisTodo!,
                avisListEnAttenteCna: cast.content.avisEnAttenteCna!,
                avisListCnaDone: cast.content.avisCnaDone!,
                contactFinanceur: cast.content.contactFinanceur!
            })

            new LocalStorageService().setJsonItem(Constant.KEY.FINANCEUR.CONTACT_FINANCEUR, cast.content.contactFinanceur!)
        } else {
            //console.log()
            this.logout()
        }
    }

    logout() {
        const applicationService = EspaceFinanceurApplicationService.getInstance()
        applicationService.logout("homepage")
        new EspaceFinanceurRouter(applicationService).search(Constant.DOM.ESPACE_FINANCEUR.LOGIN_PAGE, undefined)
    }

    getListAvisTodo(list: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>[]): ReactElement[] | null {
        if (this.state && list) {
            const avisView: ReactElement[] = []
            list.forEach((fullAvis, index) => {
                //console.log("loop : "+index)
                avisView.push(<hr key={"avf-separator-" + index} />)
                avisView.push(
                    <div key={"avf-" + index}>
                        <RowComponent>
                            <ColumnComponent size={3}>{this.getBadge(fullAvis.finessJuridique?.raisonSociale)}</ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>{this.getEtablissementsConcernes(fullAvis)}</ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={3}>Coût total : {fullAvis.demande?.financementTotal} €</ColumnComponent>
                            <ColumnComponent size={2}>
                                <Button small onClick={() => this.clickDemande(fullAvis, "avisTodo")}>
                                    Donner un avis
                                </Button>
                            </ColumnComponent>
                        </RowComponent>
                    </div>
                )
                avisView.push(<br key={"avf-empty-" + index} />)
            })
            if (list.length > 0) {
                avisView.push(<hr key={"avf-separator-last"} />)
            } else {
                avisView.push(<p key={"avf-list-avis-todo-nothing"}>Aucune demande</p>)
            }
            return avisView
        }
        return null
    }

    getToutesLesDemandes() {
        return <EspaceFinanceurToutesLesDemandesComponent />
    }

    getListAvisDone(list: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>[]): ReactElement[] | null {
        if (this.state && list) {
            const avisView: ReactElement[] = []
            list.forEach((fullAvis, index) => {
                //console.log("loop : "+index)
                avisView.push(<hr key={"avf-separator-" + index} />)
                avisView.push(
                    <div key={"avf-" + index}>
                        <RowComponent key={"avf-row-" + index}>
                            <ColumnComponent size={3}>{this.getBadge(fullAvis.finessJuridique?.raisonSociale)}</ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>{this.getEtablissementsConcernes(fullAvis)}</ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={3}>Coût total : {fullAvis.demande?.financementTotal} €</ColumnComponent>
                            <ColumnComponent size={2}>
                                <Button small onClick={() => this.clickDemande(fullAvis, "avisDone")}>
                                    Consulter
                                </Button>
                            </ColumnComponent>
                        </RowComponent>
                    </div>
                )
                avisView.push(<br key={"avf-empty-" + index} />)
            })
            if (list.length > 0) {
                avisView.push(<hr key={"avf-separator-last"} />)
            } else {
                avisView.push(<p key={"zero-demande"}>Aucune demande</p>)
            }
            return avisView
        }
        return null
    }

    clickDemande(avis: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>, mode: "avisTodo" | "avisDone") {
        this.setState({
            mode: mode,
            current: avis
        })
    }

    getBadge(raisonSociale: string | undefined | null) {
        return <BadgeInfoComponent noIcon label={raisonSociale!} />
    }

    getEtablissementsConcernes(avis: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>) {
        if (avis.demande?.etablissements?.length == 1) {
            return "1 établissement"
        } else {
            return avis.demande?.etablissements?.length + " établissements"
        }
    }

    getOrganismeName() {
        if (this.props.contactFinanceur?.organisme?.nom != undefined) {
            return this.props.contactFinanceur?.organisme.nom ?? this.state.contactFinanceur?.organisme.nom
        } else if (this.state.contactFinanceur?.organisme?.nom != undefined) {
            return this.state.contactFinanceur.organisme.nom
        }
    }

    getPrenomNom() {
        if (this.props.contactFinanceur != null && this.props.contactFinanceur.nom != null) {
            return this.props.contactFinanceur.prenom + " " + this.props.contactFinanceur.nom
        } else {
            return this.state.contactFinanceur?.prenom + " " + this.state.contactFinanceur?.nom
        }
    }

    getLabelCurrentStatus() {
        if (this.state && this.state.avisListTodo) {
            if (this.state.avisListTodo?.length == 0) {
                return "Nous n'avons actuellement aucune demande en attente concernant votre autorité de tarification."
            } else if (this.state.avisListTodo?.length == 1) {
                return "Nous souhaiterions avoir votre avis sur une demande d'agrément en cours d'instruction."
            } else if (this.state.avisListTodo?.length > 1) {
                return "Nous souhaiterions avoir votre avis sur " + this.state.avisListTodo.length + " demandes d'agrément en cours d'instruction."
            }
        } else {
            return "Nous n'avons actuellement aucune demande en attente concernant votre autorité de tarification."
        }
    }

    onClickAccepter() {
        const textArea = document.querySelector("#avis-commentaire") as HTMLTextAreaElement
        //console.log("pass textarea")
        this.setState({
            mode: "confirmAccepte",
            commentaire: textArea.value
        })
    }

    onClickRefuser() {
        const textArea = document.querySelector("#avis-commentaire") as HTMLTextAreaElement
        //console.log("pass textarea")
        this.setState({
            mode: "confirmRefuse",
            commentaire: textArea.value
        })
    }

    onClickConfirmAccepter() {
        new HttpEspaceFinanceurService()
            .sendAccept(
                this.state?.current?.avis?.id ?? null,
                this.state?.contactFinanceur?.id ?? null,
                this.state?.contactFinanceur?.organisme?.id ?? null,
                this.state.current?.demande?.id ?? null,
                this.state.commentaire!
            )
            .then((response) => {
                const cast = new Response<ContactFinanceurInformation>(response)
                if (cast.success) {
                    this.setState({
                        mode: "list",
                        avisListTodo: this.state.avisListTodo?.filter((fullAvis: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>) => fullAvis.avis?.id != this.state.current?.avis?.id),
                        current: undefined
                    })
                } else if (cast.hasError()) {
                    this.setState({ errorConfirmation: cast.errors![0]?.errorMessage })
                } else {
                    this.setState({ errorConfirmation: "Une erreur s'est produite" })
                }
            })
    }

    onClickConfirmRefuser() {
        new HttpEspaceFinanceurService()
            .sendRefuse(
                this.state?.current?.avis?.id ?? null,
                this.state?.contactFinanceur?.id ?? null,
                this.state?.contactFinanceur?.organisme?.id ?? null,
                this.state.current?.demande?.id ?? null,
                this.state.commentaire!
            )
            .then((response) => {
                const cast = new Response<ContactFinanceurInformation>(response)
                if (cast.success) {
                    this.setState({
                        mode: "list",
                        avisListTodo: this.state.avisListTodo?.filter((fullAvis: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>) => fullAvis.avis?.id != this.state.current?.avis?.id),
                        current: undefined
                    })
                } else if (cast.hasError()) {
                    this.setState({ errorConfirmation: cast.errors![0]?.errorMessage })
                } else {
                    this.setState({ errorConfirmation: "Une erreur s'est produite" })
                }
            })
    }

    getError() {
        if (this.state?.errorConfirmation) {
            return (
                <Fragment key={"fragment-error"}>
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent>
                            <AlertErrorComponent description={this.state.errorConfirmation} />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                </Fragment>
            )
        }
    }

    getCommentaireOnConfirmation() {
        if (this.state.commentaire) {
            return (
                <RowComponent key={"row-2"}>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>
                        <FieldsetComponent legend="Commentaire" content={this.state.commentaire} />
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        }
    }

    getAvisFavorableOnConfirmation() {
        if (this.state.commentaire) {
            return (
                <RowComponent key={"row-1"}>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>
                        <span>
                            Votre avis est <BadgeGreenMintComponent label={"favorable"} />.
                        </span>
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        } else {
            return (
                <RowComponent key={"row-1"}>
                    <ColumnComponent size={4} />
                    <ColumnComponent size={5}>
                        <span>
                            Votre avis est <BadgeGreenMintComponent label={"favorable"} />.
                        </span>
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        }
    }

    getContent() {
        if (this.state && this.state.current && this.state.mode === "avisTodo") {
            return (
                <Fragment key={"mode-avis-todo"}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <RowComponent key="row-avis-0">
                                <ColumnComponent size={9} />
                                <ColumnComponent size={2}>
                                    <Button iconLeft={Constant.ICON.ARROW_LEFT_LINE} small onClick={() => this.setState({ mode: "list" })}>
                                        Retour liste
                                    </Button>
                                </ColumnComponent>
                                <ColumnComponent size={1} />
                            </RowComponent>
                            <VerticalSpacing height={"75px"} />
                            <RowComponent key={"row-avis-1"}>
                                <ColumnComponent size={2} />
                                <ColumnComponent size={8}>
                                    Cette demande d'agrément pour l'association {this.state.current.finessJuridique?.raisonSociale} vous a été transmise pour avis le{" "}
                                    {UiService.formatServerDateToHumanDate(this.state.current.demande?.dateCompletude)}.<br />
                                </ColumnComponent>
                                <ColumnComponent size={2} />
                            </RowComponent>
                            <VerticalSpacing height={"60px"} />
                            <RowComponent>
                                <ColumnComponent size={1} />
                                <ColumnComponent size={10}>
                                    <EspaceFinanceurConsultationDemandeAgrementComponent
                                        errorCommentaire={this.state && this.state.errorCommentaire}
                                        clickAccept={() => this.onClickAccepter()}
                                        clickRefuse={() => this.onClickRefuser()}
                                        fullAvis={this.state.current}
                                    />
                                </ColumnComponent>
                                <ColumnComponent size={1} />
                            </RowComponent>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        } else if (this.state && this.state.current && this.state.mode === "avisDone") {
            return (
                <Fragment key={"mode-avis-done"}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <RowComponent key="row-avis-0">
                                <ColumnComponent size={9} />
                                <ColumnComponent size={2}>
                                    <Button iconLeft={Constant.ICON.ARROW_LEFT_LINE} small onClick={() => this.setState({ mode: "list" })}>
                                        Retour liste
                                    </Button>
                                </ColumnComponent>
                                <ColumnComponent size={1} />
                            </RowComponent>
                            <VerticalSpacing height={"75px"} />
                            <RowComponent key={"row-avis-1"}>
                                <ColumnComponent size={2} />
                                <ColumnComponent size={8}>
                                    Cette demande d'agrément pour l'association {this.state.current.finessJuridique?.raisonSociale} vous a été transmise pour avis le{" "}
                                    {UiService.formatServerDateToHumanDate(this.state.current.demande?.dateCompletude)}.<br />
                                </ColumnComponent>
                                <ColumnComponent size={2} />
                            </RowComponent>
                            <VerticalSpacing height={"60px"} />
                            <RowComponent>
                                <ColumnComponent size={1} />
                                <ColumnComponent size={10}>
                                    <EspaceFinanceurConsultationDemandeAgrementComponent fullAvis={this.state.current} />
                                </ColumnComponent>
                                <ColumnComponent size={1} />
                            </RowComponent>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        } else if (this.state && this.state.mode && this.state.mode === "confirmAccepte") {
            return (
                <Fragment key={"mode-confirm-accepte"}>
                    <VerticalSpacing height="25px" />
                    {this.getError()}
                    {this.getAvisFavorableOnConfirmation()}
                    <VerticalSpacing height="25px" />
                    {this.getCommentaireOnConfirmation()}
                    <VerticalSpacing height="50px" />
                    <RowComponent key={"row-3"}>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={3} style={{ textAlign: "center" }}>
                            <Button iconLeft={Constant.ICON.ADD_CIRCLE_FILL} onClick={() => this.onClickConfirmAccepter()}>
                                Je confirme
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={3} style={{ textAlign: "center" }}>
                            <Button secondary iconLeft={Constant.ICON.ERROR_FILL} onClick={() => this.setState({ mode: "list" })} style={{ marginLeft: "5px" }}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                    <VerticalSpacing height="30px" />
                </Fragment>
            )
        } else if (this.state && this.state.mode && this.state.mode === "confirmRefuse") {
            return (
                <Fragment key={"mode-confirm-refuse"}>
                    <RowComponent key={"row-1"}>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={6}>
                            <span>
                                Vous donnez un avis <BadgeWarningComponent label={"défavorable"} /> sur cette demande.
                            </span>
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                    <VerticalSpacing height="25px" />
                    {this.getCommentaireOnConfirmation()}
                    <VerticalSpacing height="50px" />
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={3}>
                            <Button iconLeft={Constant.ICON.ADD_CIRCLE_FILL} onClick={() => this.onClickConfirmRefuser()}>
                                Je confirme mon refus
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={3}>
                            <Button secondary iconLeft={Constant.ICON.ERROR_FILL} onClick={() => this.setState({ mode: "list" })} style={{ marginLeft: "5px" }}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                </Fragment>
            )
        } else {
            const data: TabData[] = []

            //console.log("ELSE "+this.state.avisListTodo?.length)
            const tabDataTodo = new TabData()
            //tabDataTodo.key = "f-avis-todo"
            //tabDataTodo.key = "tab-avis-0"
            tabDataTodo.label = "En attente de votre avis"
            tabDataTodo.content = <Fragment key={"f-demande-en-cours"}>{this.getListAvisTodo(this.state.avisListTodo!)}</Fragment>
            data.push(tabDataTodo)

            const tabDataEnAttenteCna = new TabData()
            //tabDataEnAttenteCna.key = "f-avis-attente-cna"
            //tabDataEnAttenteCna.key = "tab-avis-1"
            tabDataEnAttenteCna.label = "Toutes les demandes"
            tabDataEnAttenteCna.content = <Fragment key={"f-toutes-les-demandes"}>{this.getToutesLesDemandes()}</Fragment>
            data.push(tabDataEnAttenteCna)

            /**
            const tabDataCnaDone = new TabData()
            //tabDataCnaDone.key = "f-avis-done"
            //tabDataCnaDone.key = "tab-avis-2"
            tabDataCnaDone.label = "Traité en CNA"
            tabDataCnaDone.content = <Fragment key={"f-avis-done"}>{this.getListAvisDone(this.state.avisListCnaDone!)}</Fragment>
            data.push(tabDataCnaDone)
             */

            return (
                <Fragment key={"mode-default"}>
                    Bienvenue {this.getPrenomNom()}
                    <br />
                    <br />
                    {this.getLabelCurrentStatus()}
                    <br />
                    <br />
                    <br />
                    <TabComponent data={data} key="tab-status" />
                </Fragment>
            )
        }
    }

    render() {
        return (
            <Fragment>
                <VerticalSpacing key="vs-1" height={"50px"} />
                <ContainerComponent key="esfhp-content">
                    <SecondaryContentBoxComponent title={"Espace " + this.getOrganismeName()}>{this.getContent()}</SecondaryContentBoxComponent>
                </ContainerComponent>
                <VerticalSpacing key="vs-2" height={"50px"} />
            </Fragment>
        )
    }
}
