import AccoladeComponent from "../../accolade/AccoladeComponent"

import FrontApplicationService from "../service/application/FrontApplicationService"
import {DnumProps} from "../../generic/component/DnumComponent";

export default class FrontComponent<P extends DnumProps, S> extends AccoladeComponent<P, S> {
    protected static applicationService = new FrontApplicationService()

    cleanString(value: string | null | undefined): string {
        if (value != null) {
            return value
        }
        return ""
    }
}
