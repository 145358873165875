import React from "react"

import DnumComponent from "../../../../generic/component/DnumComponent"
import CardVerticalComponent from "../../../../generic/component/card/CardVerticalComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

export default class FrontChooseAccountTypeComponent extends DnumComponent<any, any> {
    public static KEY = "choose-account"

    render() {
        return (
            <RowComponent style={{ marginTop: "50px", marginBottom: "50px" }} center>
                <ColumnComponent size={1} />
                <ColumnComponent size={3}>
                    <CardVerticalComponent title="Association" description="Créer mon compte association" />
                </ColumnComponent>
                <ColumnComponent size={2} />
                <ColumnComponent size={3}>
                    <CardVerticalComponent title="Convention collective nationale" description="Créer un compte CCN" />
                </ColumnComponent>
                <ColumnComponent size={1} />
            </RowComponent>
        )
    }
}
