import { Fragment } from "react"

import BackAccount from "../../model/BackAccount"

import Button from "../../../generic/component/button/Button"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import HeadBand6Component from "../../../generic/component/headband/HeadBand6Component"

import { Constant } from "../../constant/Constant"
import BackOfficeService from "../../service/BackOfficeService"
import HttpBackAccountService from "../../service/http/HttpBackAccountService"
import Response from "../../service/http/response/Response"
import BackOfficeComponent from "../BackOfficeComponent"
import BackOfficeLoginForm from "./BackOfficeLoginForm"

interface BackOfficeLoginFormComponentState {
    errorMessage?: string | undefined
    successMessage?: string | undefined
    errors?: Map<string, string> | undefined
}

export default class BackOfficeLoginFormComponent extends BackOfficeComponent<any, BackOfficeLoginFormComponentState> {
    constructor(props: any) {
        super(props)
    }

    doLogin() {
        const loginForm = this.getForm()
        new HttpBackAccountService().login(loginForm).then((response) => {
            const cast = new Response<BackAccount>(response)
            if (cast.hasError()) {
                this.setState({ errors: cast.getErrorMap() })
            } else {
                this.onLoginSuccess(cast.content!)
            }
        })
    }

    getForm(): BackOfficeLoginForm {
        const loginForm = new BackOfficeLoginForm()
        const login = document.querySelector("#back-identifiant") as HTMLInputElement
        const password = document.querySelector("#back-password") as HTMLInputElement
        loginForm.login = login.value
        loginForm.password = password.value
        return loginForm
    }

    onLoginSuccess(account: BackAccount) {
        //const map = new Map<string, any>()
        BackOfficeService.getInstance().setBackAccount = account
        //map.set(Constant.KEY.BACK.CURRENT_ACCOUNT, account)
        BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.ADMIN_HOME_PAGE)
    }

    render() {
        return (
            <Fragment>
                <HeadBand6Component label={"CONNEXION"} style={{ background: Constant.COLOR.BLUE_GREY_COLOR, color: "white" }} center />
                <div style={{ padding: "10px" }}>
                    <TextInputComponent label={"Identifiant"} id={"back-identifiant"} error={this.state?.errors?.has("login")} errorMessage={this.state?.errors?.get("login")} />
                    <TextInputComponent label={"Mot de passe"} error={this.state?.errors?.has("password")} errorMessage={this.state?.errors?.get("password")} id={"back-password"} password />
                    <Button style={{ width: "100%", color: "white" }} onClick={() => this.doLogin()}>
                        SE CONNECTER
                    </Button>
                </div>
            </Fragment>
        )
    }
}
