import React, { Fragment, ReactElement } from "react"

import Cna from "../../model/Cna"

import Button from "../../../generic/component/button/Button"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import RoleBackOffice from "../../constant/role/RoleBackOffice"
import BackOfficeService from "../../service/BackOfficeService"
import HttpBackCnaService from "../../service/http/HttpBackCnaService"
import UiService from "../../service/ui/UiService"
import BackOfficeComponent from "../BackOfficeComponent"
import BackOfficeListDemandeCnaPage from "./BackOfficeListDemandeCnaPage"
import {DnumProps} from "../../../generic/component/DnumComponent";

interface BackOfficeListCnaComponentProps extends DnumProps {
    editCnaView: (cna: Cna) => void
    createCnaView: () => void
    changeTitle: (title: string) => void
}

interface BackOfficeListCnaComponentState {
    cnaList: Cna[] | null
    current: Cna | null
}

export default class BackOfficeListCnaComponent extends BackOfficeComponent<BackOfficeListCnaComponentProps, BackOfficeListCnaComponentState> {
    constructor(props: BackOfficeListCnaComponentProps) {
        super(props)
        this.state = {
            cnaList: null,
            current: null
        }
        this.props.changeTitle("Liste des CNA")
    }

    componentDidMount() {
        new HttpBackCnaService().list().then((response) => {
            if (response.success) {
                if (response.content) {
                    this.setState({ cnaList: response.content })
                }
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<BackOfficeListCnaComponentProps>, prevState: Readonly<BackOfficeListCnaComponentState>, snapshot?: any) {
        if (prevState?.current != null && this.state?.current == null) {
            this.componentDidMount()
        }
    }

    getHead() {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th>ID</th>
                    <th>Date de la CNA</th>
                    <th>Date effective</th>
                    <th>Date de validation</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    getCnaList() {
        const list: ReactElement[] = []
        this.state.cnaList?.forEach((cna) => {
            list.push(
                <tr key={"cna-" + cna.id}>
                    <td>{cna.id}</td>
                    <td>{UiService.formatServerDateToHumanDate(cna.datePrevue)}</td>
                    <td>{UiService.formatServerDateToHumanDate(cna.dateEffective)}</td>
                    <td>{UiService.formatServerDateToHumanDate(cna.dateValidation)}</td>
                    <td>{this.getEditButton(cna)}</td>
                    <td>{this.getConsultationButton(cna)}</td>
                </tr>
            )
        })
        return list
    }

    showDemandeList(cna: Cna) {
        this.setState({
            current: cna
        })
        this.props.changeTitle("CNA du " + UiService.formatServerDateToHumanNumericDate(cna.datePrevue))
    }

    getEditButton(cna: Cna) {
        if (cna.dateEffective == null && cna.dateValidation == null && !this.isMembreCna()) {
            return <Button onClick={() => this.props.editCnaView(cna)}>Modifier</Button>
        }
    }

    getConsultationButton(cna: Cna) {
        return <Button onClick={() => this.showDemandeList(cna)}>{cna.dateValidation != null || this.isMembreCna() ? "Consultation" : "Demandes"}</Button>
    }

    returnToListCnaFunction() {
        this.setState({
            current: null
        })
        this.props.changeTitle("Liste des CNA")
    }

    getCnaCreationButton() {
        if (!this.isMembreCna()) {
            return (
                <Button iconLeft={Constant.ICON.ADD_CIRCLE_FILL} onClick={() => this.props.createCnaView()}>
                    Créer une CNA
                </Button>
            )
        }
    }

    isMembreCna() {
        const account = BackOfficeService.getInstance().getBackAccount
        return account?.type === RoleBackOffice.MEMBRE_CNA
    }

    render() {
        if (this.state && this.state.current) {
            const account = BackOfficeService.getInstance().getBackAccount
            //console.log(account?.type)
            //console.log(RoleBackOffice.MEMBRE_CNA)
            //console.log(account?.type!=RoleBackOffice.MEMBRE_CNA)
            return (
                <BackOfficeListDemandeCnaPage
                    goBack={() => this.returnToListCnaFunction()}
                    showValidationForUser={account?.cnaValidator == true && !this.isMembreCna()}
                    cna={this.state.current}
                    etat={Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA}
                />
            )
        } else if (this.state && this.state.cnaList && this.state.cnaList.length > 0) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={9} />
                            <ColumnComponent size={3} style={{ textAlign: "right" }}>
                                {this.getCnaCreationButton()}
                            </ColumnComponent>
                        </RowComponent>
                        <div className="fr-table fr-table--layout-fixed">
                            <table id="#table-cna">
                                {this.getHead()}
                                <tbody>{this.getCnaList()}</tbody>
                            </table>
                        </div>
                    </ContainerComponent>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={8} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.props.createCnaView()}>Créer une CNA</Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginTop: "15px", marginBottom: "15px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <div>Aucune CNA n'a été créée</div>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        }
    }
}
