import React, { ReactElement } from "react"

import { DnumProps } from "../../../../generic/component/DnumComponent"
import ContainerComponent from "../../../../generic/component/grid/container/ContainerComponent"
import HeadBand6Component from "../../../../generic/component/headband/HeadBand6Component"

import AccoladeComponent from "../../../../accolade/AccoladeComponent"

interface ContentBoxProps extends DnumProps {
    title: ReactElement | string
    rightComponent?: ReactElement
}

export default abstract class PrimaryContentBoxComponent<P extends ContentBoxProps, S> extends AccoladeComponent<P, S> {
    render() {
        const mainContainer = {
            marginTop: "25px",
            //border: `1px dotted ${Constant.COLOR.BLUE_GREY_COLOR}`,
            //background: Constant.COLOR.VERY_LIGHT_BLUE,
            marginBottom: "30px"
        }

        const headBand = {
            padding: 0,
            margin: 0,
            background: "white",
            color: "#000091",
            paddingLeft: "30px !important"
        }

        const border = {
            borderColor: "#000091",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px"
        }

        const contentContainer = {
            background: "white",
            marginTop: "25px",
            ...border
        }

        return (
            <ContainerComponent style={mainContainer}>
                <div style={{ paddingLeft: "25px", paddingRight: "25px", background: headBand.background, ...border }}>
                    <HeadBand6Component label={this.props.title} style={headBand} rightComponent={this.props.rightComponent} />
                </div>
                <ContainerComponent style={contentContainer}>{this.props.children}</ContainerComponent>
            </ContainerComponent>
        )
    }
}
