import PersistentObject from "./PersistentObject"

export default class HtmlTemplate implements PersistentObject {
    id: number | null = null
    code: string | null = null
    titre: string | null = null
    contenu: string | null = null
    keys: string[] | null = null
    type: string | null = null

    constructor(id: number | null | any) {
        this.id = id
    }
}
