import FieldsetComponent from "../../../generic/component/FieldsetComponent"

import BackOfficeComponent from "../BackOfficeComponent"
import {DnumProps} from "../../../generic/component/DnumComponent";

interface GetDemandeCountByStatutsProps extends DnumProps {
    title: string
    count: number
}

export default class BackOfficeGetDemandeCountByStatuts extends BackOfficeComponent<GetDemandeCountByStatutsProps, any> {
    render() {
        const content = <h1>{this.props.count}</h1>
        return <FieldsetComponent centerContent legend={this.props.title} content={content} />
    }
}
