import PersistentObject from "./PersistentObject"
import TypeFinanceur from "./TypeFinanceur"

export default class Esms implements PersistentObject {
    id: number | null = null
    label: string | null = null
    code: string | null = null
    active: boolean | null = null

    financeurs: TypeFinanceur[] | null = null

    constructor(id: number | null | any) {
        this.id = id
    }
}
