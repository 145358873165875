import { Fragment } from "react"

import DnumComponent from "../../../../generic/component/DnumComponent"
import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import ErrorMessageComponent from "../../../../generic/component/message/ErrorMessageComponent"
import SuccessMessageComponent from "../../../../generic/component/message/SuccessMessageComponent"

import HttpAccountService from "../../../service/http/HttpAccountService"
import SecondaryContentBoxComponent from "../../../shared/component/box/SecondaryContentBoxComponent"

interface FrontConfirmAccountComponentState {
    success: boolean | null
    errorMessage: string | null
}

export default class FrontConfirmAccountComponent2 extends DnumComponent<any, FrontConfirmAccountComponentState> {
    public static KEY = "confirm-account-password"

    httpService: HttpAccountService = new HttpAccountService()

    validAccount() {
        const input = document.querySelector("#account-activation-code") as HTMLInputElement
        this.httpService.validAccount(input.value).then((response) => {
            if (response.success) {
                this.setState({ success: true })
            } else {
                this.setState({ errorMessage: response?.errors![0].errorMessage })
            }
        })
    }

    getErrorMessage() {
        if (this.state && this.state.errorMessage) {
            return (
                <Fragment>
                    <ErrorMessageComponent message={this.state.errorMessage} />
                    <br />
                </Fragment>
            )
        }
    }

    getContent() {
        if (this.state && this.state.success) {
            return (
                <RowComponent>
                    <ColumnComponent size={5} />
                    <ColumnComponent size={2}>
                        <SuccessMessageComponent message="Vous pouvez dès à présent vous connecter pour faire votre demande d'agrément" />
                    </ColumnComponent>
                    <ColumnComponent size={5} />
                </RowComponent>
            )
        } else {
            return (
                <RowComponent>
                    <ColumnComponent size={5} />
                    <ColumnComponent size={2}>
                        <TextInputComponent label="Code d'activation" id="account-activation-code" />
                        {this.getErrorMessage()}
                        <Button onClick={() => this.validAccount()}>Valider</Button>
                    </ColumnComponent>
                    <ColumnComponent size={5} />
                </RowComponent>
            )
        }
    }

    render() {
        return <SecondaryContentBoxComponent title="Un email vous a été envoyé">{this.getContent()}</SecondaryContentBoxComponent>
    }
}
