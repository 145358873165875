import React, { ReactElement } from "react"

import IdentifiantConventionCollective from "../../../model/IdentifiantConventionCollective"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeIdentifiantConventionCollectiveFormComponent extends BackOfficeAbstractReferentielFormComponent<IdentifiantConventionCollective, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useIdentifiantConventionCollectiveService()
    }

    formToObject(id: number): IdentifiantConventionCollective {
        const idcc = new IdentifiantConventionCollective(this.props.objectId)
        idcc.label = (document.querySelector("#idcc-label") as HTMLInputElement).value
        idcc.code = (document.querySelector("#idcc-code") as HTMLInputElement).value
        idcc.active = (document.querySelector("input[name='idcc-active']") as HTMLInputElement).checked
        return idcc
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("idcc-active", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent label="Code" id="idcc-code" value={this.getProperty("code")} error={this.state?.errors?.has("code")} errorMessage={this.state?.errors?.get("code")} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="idcc-label"
                                readonly={edit && this.state.item?.label}
                                value={this.getProperty("label")}
                                error={this.state?.errors?.has("label")}
                                errorMessage={this.state?.errors?.get("label")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
