import React from "react"

import UtilService from "../../service/UtilService"
import DnumComponent, { DnumProps } from "../DnumComponent"

interface TagProps extends DnumProps {
    label: string
    onClickHandler: (tag: string) => void
    icon?: string
}

export default class TagComponent extends DnumComponent<TagProps, any> {
    render() {
        let className = {
            className: "fr-tag fr-tag--icon-left"
        }

        if (this.props.icon) {
            className = {
                className: "fr-tag " + this.props.icon + " fr-tag--icon-left"
            }
        }

        //const keyId = UtilService.getInstance().generateId("tag-")
        return (
            <a href="#" key={`tag-${this.props.label}`} onClick={() => this.props.onClickHandler(this.props.label)} {...className}>
                {this.props.label}
            </a>
        )
    }
}
