import React, { ReactElement } from "react"

import ContactFinanceur from "../../../model/ContactFinanceur"
import OrganismeFinanceur from "../../../model/OrganismeFinanceur"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../generic/component/select/SelectOptionData"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"
import BackOfficeContactFinanceurExtraDataState from "./BackOfficeContactFinanceurExtraDataState"

export default class BackOfficeContactFinanceurFormComponent extends BackOfficeAbstractReferentielFormComponent<ContactFinanceur, BackOfficeContactFinanceurExtraDataState> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useContactFinanceurService()
    }

    onComponentDidMount(object: ContactFinanceur | null) {
        new HttpReferentielService()
            .useOrganismeFinanceurService()
            .getAll<OrganismeFinanceur>()
            .then((response) => {
                this.updatePartialState(response.content!)
            })
    }

    updatePartialState(organismeFinanceurList: OrganismeFinanceur[]) {
        const newState: { [k: string]: any } = { extra: {} }
        if (organismeFinanceurList != null) {
            newState.extra.organismeFinanceurList = organismeFinanceurList
        }
        this.setState(Object.assign(this.state != null ? this.state : {}, newState))
    }

    formToObject(id: number): ContactFinanceur {
        const contactFinanceur = new ContactFinanceur(this.props.objectId)
        contactFinanceur.organisme.id = Number.parseInt((document.querySelector("#organisme-financeur-select") as HTMLSelectElement).value)
        contactFinanceur.prenom = (document.querySelector("#contact-financeur-prenom") as HTMLInputElement).value
        contactFinanceur.nom = (document.querySelector("#contact-financeur-nom") as HTMLInputElement).value
        contactFinanceur.telephone = (document.querySelector("#contact-financeur-telephone") as HTMLInputElement).value
        contactFinanceur.email = (document.querySelector("#contact-financeur-email") as HTMLInputElement).value
        contactFinanceur.active = (document.querySelector("input[name='contact-financeur-actif']") as HTMLInputElement).checked
        return contactFinanceur
    }

    getOrganismes() {
        const options: SelectOptionData[] = [new SelectOptionData("", "", false)]
        if (this.state && this.state.extra && this.state.extra.organismeFinanceurList) {
            this.state.extra.organismeFinanceurList.forEach((organisme: OrganismeFinanceur) => {
                options.push(new SelectOptionData(organisme.id!.toString(), organisme.nom!, organisme.id == this.state.item?.organisme?.id))
            })
        }
        return options
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("contact-financeur-actif", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <SelectComponent
                                label="Organisme financeur"
                                data={this.getOrganismes()}
                                value={this.state?.item?.organisme.id!.toString()}
                                id="organisme-financeur-select"
                                errorMessage={this.state?.errors?.get("organisme")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Nom"
                                id="contact-financeur-nom"
                                value={this.getProperty("nom")}
                                error={this.state?.errors?.has("nom")}
                                errorMessage={this.state?.errors?.get("nom")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Prénom"
                                id="contact-financeur-prenom"
                                value={this.getProperty("prenom")}
                                error={this.state?.errors?.has("prenom")}
                                errorMessage={this.state?.errors?.get("prenom")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Courriel"
                                id="contact-financeur-email"
                                value={this.getProperty("email")}
                                error={this.state?.errors?.has("email")}
                                errorMessage={this.state?.errors?.get("email")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Téléphone"
                                id="contact-financeur-telephone"
                                value={this.getProperty("telephone")}
                                error={this.state?.errors?.has("telephone")}
                                errorMessage={this.state?.errors?.get("telephone")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
