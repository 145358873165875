import React, { Fragment, ReactElement } from "react"

import FinessEtablissement from "../../../../model/FinessEtablissement"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import AccordionGroupComponent from "../../../../../generic/component/accordion/group/AccordionGroupComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import FrontComponent from "../../../FrontComponent"
import FrontAccountFinessUpdateMessageComponent from "../FrontAccountFinessUpdateMessageComponent"
import FrontAccountEtablissementComponent from "./FrontAccountEtablissementComponent"

interface FrontAccountEtablissementsProps extends DnumProps {
    etablissements: FinessEtablissement[]
    refreshFunction: () => void
}

export default class FrontAccountEtablissementsComponent extends FrontComponent<FrontAccountEtablissementsProps, any> {
    constructor(props: FrontAccountEtablissementsProps) {
        super(props)
    }

    render() {
        const etablissements: ReactElement[] = []
        this.props.etablissements
            ?.sort((etablissementA, etablissementB) => etablissementA.raisonSociale!.localeCompare(etablissementB.raisonSociale!))
            .filter((etablissement, index) => etablissement.etat == "ACTUEL")
            .forEach((etablissement, index) => {
                etablissements.push(
                    <FrontAccountEtablissementComponent
                        index={index}
                        refreshFunction={this.props.refreshFunction}
                        etablissement={etablissement}
                        id={"account-etablissement-" + index}
                        key={"account-etablissement-" + index}
                    />
                )
            })
        return (
            <Fragment>
                <FrontAccountFinessUpdateMessageComponent />
                <RowComponent>
                    <ColumnComponent size={1}></ColumnComponent>
                    <ColumnComponent size={10}>
                        <AccordionGroupComponent>{etablissements}</AccordionGroupComponent>
                    </ColumnComponent>
                    <ColumnComponent size={1}></ColumnComponent>
                </RowComponent>
            </Fragment>
        )
    }
}
