import styled from "styled-components"

import DnumComponent, { DnumProps } from "../../../../generic/component/DnumComponent"

interface VerticalProps extends DnumProps {
    height: string
}
export default class VerticalSpacing extends DnumComponent<VerticalProps, any> {
    constructor(props: VerticalProps) {
        super(props)
    }

    render() {
        const StyledDiv = styled.div`
            height: ${this.props.height};
        `

        return <StyledDiv />
    }
}
