import React, {useRef, useState} from "react";
import { CaptchEtat } from "captchetat-react";

interface TestCaptchaComponentProps {
    setCaptchaId: (captchaId: string) => void;
}

const FrontCaptchaComponent: React.FC<TestCaptchaComponentProps> = ({ setCaptchaId }) => {

    const urlBackend = '/accolade/simple-captcha-endpoint';
    const captchaStyleName = "captchaFR";
    const alternativeImage = "";
    const [values, setValues] = useState<{
        captchaId?: string;
        captchaCode?: string;
        [key: string]: string | undefined;
    }>({});
    const captchetatRef = useRef(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onCaptchaChange = (captcha: { uuid: string }) => {
        setCaptchaId(captcha.uuid);
        setValues({ ...values, captchaId: captcha.uuid });
    };

    return (
        <CaptchEtat
            urlBackend={urlBackend}
            captchaStyleName={captchaStyleName}
            altImage={alternativeImage}
            onChange={onCaptchaChange}
            ref={captchetatRef}
        />
    );
};

export default FrontCaptchaComponent;