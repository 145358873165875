import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface DotStatusProps extends DnumProps {
    size: number
    color: string
}
export default class DotStatus extends DnumComponent<DotStatusProps, any> {
    constructor(props: DotStatusProps) {
        super(props)
    }

    render() {
        const size = this.props.size + "px"
        const radius = this.props.size / 2 + "px"
        return <div style={{ width: size, height: size, borderRadius: radius, background: this.props.color }}></div>
    }
}
