import IPageCoordinator from "./contract/IPageCoordinator"

export default class EspaceFinanceurService {
    private static instance: EspaceFinanceurService

    public switchPage: any
    public isUserConnected: any

    public static getInstance(): EspaceFinanceurService {
        if (!EspaceFinanceurService.instance) {
            EspaceFinanceurService.instance = new EspaceFinanceurService()
        }
        return EspaceFinanceurService.instance
    }

    public setPageCoordinator(page: IPageCoordinator) {
        this.switchPage = page.getPageSwitcher()
        this.isUserConnected = page.isUserConnected()
    }
}
