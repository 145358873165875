import { ReactElement } from "react"

import Esms from "../../../model/Esms"
import TypeFinanceur from "../../../model/TypeFinanceur"

import Button from "../../../../generic/component/button/Button"
import CheckboxComponent from "../../../../generic/component/checkbox/CheckboxComponent"
import CheckboxData from "../../../../generic/component/checkbox/CheckboxData"
import CheckboxGroupComponent from "../../../../generic/component/checkbox/CheckboxGroupComponent"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

interface EsmsFormComponentExtraState {
    typeFinanceurs: TypeFinanceur[] | null
}

export default class BackOfficeEsmsFormComponent extends BackOfficeAbstractReferentielFormComponent<Esms, EsmsFormComponentExtraState> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useEsmsTemplateService()
    }

    formToObject(id: number): Esms {
        const esms = new Esms(this.props.objectId)
        esms.code = (document.querySelector("#esms-code") as HTMLInputElement).value
        esms.label = (document.querySelector("#esms-label") as HTMLInputElement).value
        esms.active = (document.querySelector('[type="checkbox"][name="esms-active"]') as HTMLInputElement).checked
        esms.financeurs = this.getFinanceursChecked()
        return esms
    }

    getFinanceursChecked(): TypeFinanceur[] {
        const list: NodeListOf<HTMLInputElement> = document.querySelectorAll('[type="checkbox"][form="esms-financeurs"]')
        const filtered: TypeFinanceur[] = []
        this.state.extra?.typeFinanceurs?.forEach((type) => {
            list.forEach((input) => {
                if (input.checked && type.id!.toString() === input.name) {
                    filtered.push(type)
                }
            })
        })
        return filtered
    }

    updatePartialState(typeFinanceurs: TypeFinanceur[]) {
        const newState: { [k: string]: any } = { extra: {} }
        if (typeFinanceurs != null) {
            newState.extra.typeFinanceurs = typeFinanceurs
        }
        this.setState(Object.assign(this.state != null ? this.state : {}, newState))
    }

    onComponentDidMount(object: Esms | null) {
        super.onComponentDidMount(object)
        new HttpReferentielService()
            .useTypeFinanceurService()
            .getAll<TypeFinanceur>()
            .then((response) => {
                this.updatePartialState(response.content!)
            })
    }

    getTypeFinanceursData(): CheckboxData[] {
        const data: CheckboxData[] = []
        if (this.state) {
            this.state.extra?.typeFinanceurs?.forEach((type, index) => {
                const checked =
                    this.state.item?.financeurs?.find((it) => {
                        return it.id === type.id
                    }) != null
                data.push(new CheckboxData(type.id!.toString(), type.nom!.toString(), checked))
            })
        }
        return data
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null

        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent label="Code" id="esms-code" value={this.getProperty("code")} error={this.state?.errors?.has("code")} errorMessage={this.state?.errors?.get("code")} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="esms-label"
                                value={this.getProperty("label").toString()}
                                error={this.state?.errors?.has("label")}
                                errorMessage={this.state?.errors?.get("label")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <CheckboxComponent label="Actif" id="esms-active" checked={Boolean(this.getProperty("active"))} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>

                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <form action="#">
                                <CheckboxGroupComponent data={this.getTypeFinanceursData()} formId={"esms-financeurs"} legend="Financeurs" />
                            </form>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>

                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
