export default class DomFront {
    static HOME_PAGE = "frontHomePage"
    static HOME_PAGE_SESSION_EXPIRED = "frontHomePageSessionExpired"
    static DEMANDE_AGREMENT_ETABLISSEMENTS_PAGE = "frontDemandeAgrementEtablissementsPage"
    static DEMANDE_AGREMENT_FORM_PAGE = "frontDemandeAgrementFormPage"
    static SHOW_DEMANDE_AGREMENT_PAGE = "frontViewDemandeAgrementPage"
    static CONTACT_PAGE = "frontContactPage"
    static LEGAL_NOTICE_PAGE = "frontLegalNoticePage"
    static FAQ_PAGE = "frontFaqPage"
    static CREATE_ACCOUNT = "frontCreateAccount"
    static SET_DETAILS_ACCOUNT = "frontDefineDetailsAccount"
    static SHOW_ACCOUNT = "frontShowAccount"
    static SIGNATURE_VALIDATION_PAGE = "frontSignatureValidationPage"
    static CONFIRMATION_PAGE = "frontConfirmationPage"
}
