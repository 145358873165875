import React, { ChangeEvent } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface RadioButtonProps extends DnumProps {
    id: string
    checked?: boolean
    label: string
    name: string
    value: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

interface RadioButtonState extends DnumProps {
    checked?: boolean
}
export default class RadioButtonComponent extends DnumComponent<RadioButtonProps, RadioButtonState> {
    constructor(props: RadioButtonProps) {
        super(props)
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    getInput(id: string) {
        if (this.state && this.state.checked) {
            return <input type="radio" id={id} checked={true} name={this.props.name} value={this.props.value} onChange={(e) => this.handleChange(e)} />
        } else if (this.props.checked) {
            return <input type="radio" id={id} checked={true} name={this.props.name} value={this.props.value} onChange={(e) => this.handleChange(e)} />
        } else {
            return <input type="radio" id={id} checked={false} name={this.props.name} value={this.props.value} onChange={(e) => this.handleChange(e)} />
        }
    }

    render() {
        const id = this.generateId(`rdbtn-${this.props.id}`)
        return (
            <div className="fr-radio-group">
                {this.getInput(id)}
                <label className="fr-label" htmlFor={id}>
                    {this.props.label}
                </label>
            </div>
        )
    }
}
