import React from "react"

import AccoladeComponent from "../../../../accolade/AccoladeComponent"

// composant créé pour surcharger facilement la couleur shadow de la barre verticale (dans highlight)
export default class CustomHighlightComponent extends AccoladeComponent<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <div className="fr-highlight fr-highlight--custom">
                <span className="fr-text--sm">{this.props.children}</span>
            </div>
        )
    }
}
