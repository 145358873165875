import React from "react"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import FrontPage from "../FrontPage"
import FrontSignatureValidationComponent from "./FrontSignatureValidationComponent"

export default class FrontSignaturePage extends FrontPage<any, any> {
    constructor(props: any) {
        super(props)
    }

    switchPage(key: string) {
        // TODO
    }

    getContent() {
        return <></>
    }

    render() {
        return (
            <PrimaryContentBoxComponent title={"Validation de la signature"}>
                <VerticalSpacing height={"15px"} />
                <FrontSignatureValidationComponent />
                <VerticalSpacing height={"15px"} />
            </PrimaryContentBoxComponent>
        )
    }
}
