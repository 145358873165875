import React from "react"

import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import FrontPage from "../FrontPage"

export default class FrontConfirmationPage extends FrontPage<any, any> {
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        //console.log(window.location.search)
        const query = new URLSearchParams(window.location.search)
        //console.log(query.keys.length)
        const dic = query.get("dic")
        //console.log(dic)
    }

    render() {
        return (
            <PrimaryContentBoxComponent title="Accusé de réception">
                <AlertSuccessComponent description={"Merci pour votre confirmation"} />
            </PrimaryContentBoxComponent>
        )
    }
}
