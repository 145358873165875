import React from "react"

import DnumComponent, {DnumProps} from "../../DnumComponent"

interface ContainerProps extends DnumProps {
    fluid?: any
    id?: string
}

export default class ContainerComponent extends DnumComponent<ContainerProps, any> {
    private className = "fr-container"

    getStyle() {
        // TODO gérer les 3 lignes de code ci-dessous et la prop Style

        const styled = this.props.style

        if (styled && !styled.padding) {
            styled.padding = "15px"
        }

        return styled
    }

    render() {
        if (this.props.fluid) {
            this.className += "--fluid"
        }

        if (this.props.id) {
            return (
                <div className={this.className} style={this.getStyle()} id={this.props.id}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div className={this.className} style={this.getStyle()}>
                {this.props.children}
            </div>
        )
    }
}
