import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface AlertProps extends DnumProps {
    title?: string
    description?: string
    small?: any
}

export default abstract class AbstractAlertComponent extends DnumComponent<AlertProps, any> {
    protected className: string

    constructor(props: AlertProps) {
        super(props)
        this.className = "fr-alert fr-alert--" + this.getType()
        if (props.small) {
            this.className += " fr-alert--sm"
        }
    }

    abstract getType(): string

    getTitle() {
        if (this.props.title) {
            return <p className="fr-alert__title">{this.props.title}</p>
        }
    }

    getDescription() {
        if (this.props.description) {
            return <p>{this.props.description}</p>
        }
    }

    render() {
        return (
            <div className={this.className} style={this.props.style}>
                {this.getTitle()}
                {this.getDescription()}
            </div>
        )
    }
}
