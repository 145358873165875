import { ReactElement } from "react"

import PieceExigible from "../../../model/PieceExigible"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficePieceExigibleFormComponent extends BackOfficeAbstractReferentielFormComponent<PieceExigible, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().usePieceExigibleService()
    }

    formToObject(id: number): PieceExigible {
        const pieceExigible = new PieceExigible(this.props.objectId)
        pieceExigible.code = (document.querySelector("#piece-exigible-code") as HTMLInputElement).value
        pieceExigible.libelle = (document.querySelector("#piece-exigible-libelle") as HTMLInputElement).value
        pieceExigible.active = (document.querySelector("input[name='piece-exigible-actif']") as HTMLInputElement).checked
        return pieceExigible
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("piece-exigible-actif", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Code"
                                id="piece-exigible-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="piece-exigible-libelle"
                                value={this.getProperty("libelle")}
                                error={this.state?.errors?.has("libelle")}
                                errorMessage={this.state?.errors?.get("libelle")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
