import React from "react";
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent";
import { Constant } from "../../constant/Constant";
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent";
import BackOfficeComponent from "../BackOfficeComponent";
import BackOfficeMaintenanceEmailLogin from "./BackOfficeMaintenanceEmailLogin";
import BackOfficeMaintenanceWithEmailComponent from "./BackOfficeMaintenanceWithEmailComponent";
import BackOfficeMaintenanceWithFinessEJComponent from "./BackOfficeMaintenanceWithFinessEJComponent";

export default class BackOfficeTestMaintenancePage extends BackOfficeComponent<any ,any>{

    getId(): string {
        return Constant.DOM.BACK_OFFICE.MAINTENANCE_MENU;
    }

    getObjectTitle(): string {
        return "Opérations de maintenance";
    }

    render(): React.ReactNode {
        return (
            <ContainerComponent id={this.getId()}>
                <PrimaryContentBoxComponent title={this.getObjectTitle()}>
                    <BackOfficeMaintenanceWithFinessEJComponent/><br/>
                    <BackOfficeMaintenanceWithEmailComponent/><br/>
                    <BackOfficeMaintenanceEmailLogin/>
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
