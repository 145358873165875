import React, { Fragment, ReactElement } from "react"

import AccountData from "../../model/AccountData"
import Cout from "../../model/Cout"
import File from "../../model/File"
import FileType from "../../model/FileType"
import FinessEtablissement from "../../model/FinessEtablissement"
import FullAvisFinanceur from "../../model/FullAvisFinanceur"
import OrganismeFinanceur from "../../model/OrganismeFinanceur"
import ShowAvisFinanceur from "../../model/ShowAvisFinanceur"

import { DnumProps } from "../../../generic/component/DnumComponent"
import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import BadgeClassicGreyComponent from "../../../generic/component/badge/BadgeClassicGreyComponent"
import BadgeGreenMintComponent from "../../../generic/component/badge/BadgeGreenMintComponent"
import BadgeOrangeClayComponent from "../../../generic/component/badge/BadgeOrangeClayComponent"
import BadgeSuccessComponent from "../../../generic/component/badge/BadgeSuccessComponent"
import BadgeWarningComponent from "../../../generic/component/badge/BadgeWarningComponent"
import Button from "../../../generic/component/button/Button"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import ErrorMessageComponent from "../../../generic/component/message/ErrorMessageComponent"
import TextAreaComponent from "../../../generic/component/text/TextAreaComponent"

import { Constant } from "../../constant/Constant"
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService"
import UiService from "../../service/ui/UiService"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import EspaceFinanceurComponent from "../EspaceFinanceurComponent"
import EspaceFinanceurDemandeAgrementForm from "./EspaceFinanceurDemandeAgrementForm"

/**
 * Si les fichiers ne sont pas passés en props, on les charge
 */
interface EspaceFinanceurConsultationDemandeAgrementComponentProps extends DnumProps {
    fullAvis: FullAvisFinanceur<EspaceFinanceurDemandeAgrementForm>
    clickAccept?: () => void | null
    clickRefuse?: () => void | null
    errorCommentaire?: boolean
}

interface EspaceFinanceurConsultationDemandeAgrementComponentState {
    files?: File[] | undefined
    informations: AccountData | undefined
}

export default class EspaceFinanceurConsultationDemandeAgrementComponent extends EspaceFinanceurComponent<
    EspaceFinanceurConsultationDemandeAgrementComponentProps,
    EspaceFinanceurConsultationDemandeAgrementComponentState
> {
    currencyFormatter: Intl.NumberFormat | null = null
    numberFormatter: Intl.NumberFormat | null = null

    constructor(props: EspaceFinanceurConsultationDemandeAgrementComponentProps) {
        super(props)
        this.currencyFormatter = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })
        this.numberFormatter = new Intl.NumberFormat("de-DE", { style: "decimal" })
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        new HttpEspaceFinanceurService().getDemandesFiles(this.props.fullAvis.demande!.id!).then((response) => {
            if (response.content != null && response.content.length > 0) {
                this.setState({ files: response.content })
            }
        })

        new HttpEspaceFinanceurService().getDemandeAccount(this.props.fullAvis.demande!.etablissements![0].numeroFinessEntiteJuridique!).then((response) => {
            this.setState({ informations: response.content })
        })
    }

    getTitleComponent(title: string) {
        return <BadgeGreenMintComponent key={title} label={title} />
    }

    getTitleData() {
        return this.getTitleComponent("1 - Données de l'accord")
    }

    getTitleCouts() {
        return this.getTitleComponent("2 - Coût de la mesure")
    }

    getTitlePiecesJointes() {
        return this.getTitleComponent("3 - Pièces jointes")
    }

    getTitleAutreAvis() {
        return this.getTitleComponent("4 - Autre avis")
    }

    getTextArea() {
        return (
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <td>{this.getErrorCommentaire()}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TextAreaComponent id={"avis-commentaire"} rows={10} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    getErrorCommentaire() {
        if (this.props.errorCommentaire) {
            return <ErrorMessageComponent message="Veuillez renseigner le commentaire pour motiver votre décision" />
        } else {
            return <span>&nbsp;</span>
        }
    }

    getDueDateSignature() {
        if (this.props.fullAvis.demande!.dateSignature != null) {
            return <div key={"due-date-signature"}>DUE signée le : {UiService.formatServerDateToHumanDate(this.props.fullAvis.demande!.dateSignature)}</div>
        }
    }

    getAccordDateSignature() {
        if (this.props.fullAvis.demande!.dateSignature != null) {
            return <div key={"accord-date-signature"}>Accord signé le : {UiService.formatServerDateToHumanDate(this.props.fullAvis.demande!.dateSignature)}</div>
        }
    }

    getNumeroDossier() {
        if (this.props.fullAvis.demande!.numeroDossier != null) {
            return <div key={"numero-dossier-accord"}>Numéro de dossier : {this.props.fullAvis.demande!.numeroDossier}</div>
        }
    }

    getAccordDateDepot() {
        if (this.props.fullAvis.demande!.dateDepot != null) {
            return <div key={"date-depot"}>Date de dépôt : {UiService.formatServerDateToHumanDate(this.props.fullAvis.demande!.dateDepot)}</div>
        }
    }

    getEtablissementsConcernes() {
        const concernes: ReactElement[] = []

        this.props.fullAvis.demande!.etablissements?.forEach((etablissement: FinessEtablissement) => {
            concernes.push(
                <li key={"concerne-" + etablissement.id}>
                    <span style={{ paddingTop: "15px" }}>{etablissement.raisonSociale}</span>
                </li>
            )
        })

        return concernes
    }

    getTitleEtablissementsConcernes() {
        const count: number = this.props.fullAvis.demande!.etablissements?.length ?? 0
        if (count > 1) {
            return <span>Les {count} établissements concernés</span>
        } else if (count == 1) {
            return <span>Un établissement concerné</span>
        }
    }

    getConventionCollective() {
        return "Convention collective appliquée : " + this.state.informations?.idcc?.label
    }

    getEffectifsEtSalaires() {
        const masseSalariale: number =
            this.props.fullAvis.demande!.etablissements?.reduce((accumulator, obj) => {
                return accumulator + obj.masseSalarialeChargee!
            }, 0) ?? 0

        const etpTotal: number =
            this.props.fullAvis.demande!.etablissements?.reduce((accumulator, obj) => {
                return accumulator + obj.etp!
            }, 0) ?? 0

        const etpConcernes: number = Number.parseFloat(this.props.fullAvis.demande!.etpConcernes!)

        return (
            <>
                Masse salariale chargée des ESMS concernés : {this.currencyFormatter?.format(masseSalariale)}
                <br />
                Nombre d'ETP au total : {this.numberFormatter?.format(etpTotal)}
                <br />
                ETP concernés par la mesure : {this.numberFormatter?.format(etpConcernes)}
            </>
        )
    }

    getThematique() {
        if (this.props.fullAvis.demande && this.props.fullAvis.demande!.typologieAccord) {
            return this.props.fullAvis.demande?.typologieAccord?.libelle
        } else {
            return "Non renseignée"
        }
    }

    hasDataForAnneeN(annee: 0 | 1 | 2 | 3): boolean {
        let flag = false
        this.props.fullAvis.demande!.financement?.forEach((financement) => {
            if (financement.a == annee) {
                if ((financement.picf ?? 0) > 0 || (financement.picv ?? 0) > 0 || (financement.ds ?? 0) > 0 || (financement.mn ?? 0) > 0 || (financement.rp ?? 0) > 0) {
                    flag = true
                }
            }
        })

        return flag
    }

    getPiecesJointes(styleFieldset: object) {
        let localFiles = this.state.files
        if ((localFiles == null || localFiles.length == 0) && this.state && this.state.files && this.state.files.length > 0) {
            localFiles = this.state.files
        }
        const elements: ReactElement[] = []
        if (localFiles?.length ?? 0 > 0) {
            let indexPJ = 1
            let documentAccord = true
            localFiles?.forEach((file) => {
                //console.log("FileType : "+file.type)
                if (documentAccord && file && file.type == FileType.ACCORD_DUE) {
                    documentAccord = false
                    if (this.props.fullAvis.demande?.typeDemande == "DUE") {
                        elements.push(
                            <fieldset key={"fs-pieces-jointes-due"} style={styleFieldset}>
                                <legend style={{ fontWeight: "bold" }}>DUE</legend>
                                {EspaceFinanceurConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                            </fieldset>
                        )
                        elements.push(<br key={"fs-pieces-jointes-due-br"} />)
                    } else {
                        elements.push(
                            <fieldset style={styleFieldset} key={"fs-pieces-jointes-accord"}>
                                <legend style={{ fontWeight: "bold" }}>Accord</legend>
                                {EspaceFinanceurConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                            </fieldset>
                        )
                        elements.push(<br key={"fs-pieces-jointes-accord-br"} />)
                    }
                }
            })
            localFiles?.forEach((file, index) => {
                if (file && file.type?.indexOf("PIECE") != -1) {
                    elements.push(
                        <fieldset key={"fs-pieces-jointes-" + file.type + "-" + index} style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>Pièce complémentaire {indexPJ++}</legend>
                            {EspaceFinanceurConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                        </fieldset>
                    )
                    elements.push(<br key={"fs-pieces-jointes-" + file.type + "-" + index + "br"} />)
                }
            })
        }
        return elements
    }

    getAnneeOptionnelle(anneeOptionnelle: 2 | 3, styleFieldset: object): ReactElement {
        if (this.hasDataForAnneeN(anneeOptionnelle)) {
            return (
                <>
                    <VerticalSpacing key={"separator-annee-" + anneeOptionnelle} height="40px" />
                    <fieldset key={"fs-annee-n-" + anneeOptionnelle} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N+{anneeOptionnelle}</legend>
                        {this.getAnneeN(anneeOptionnelle)}
                    </fieldset>
                </>
            )
        }
        return <></>
    }

    getDueRender() {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        return (
            <Fragment key="due-details">
                <br key={"br0"} />
                {this.getTitleData()}
                <br key={"br1"} />
                <br key={"br2"} />
                <fieldset key={"fs-general"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>&nbsp;Informations administratives&nbsp;</legend>
                    {this.getDueDateSignature()}
                    <br key={"br3"} />
                    {this.getConventionCollective()}
                    <br key={"br4"} />
                    <br />
                    Courriel de contact : {this.props.fullAvis.demande?.emailContact}
                    <br key={"br4-1"} />
                </fieldset>
                <VerticalSpacing key={"separator-1"} height="40px" />
                <fieldset key={"fs-etablissements"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                    <RowComponent>
                        <div style={{ paddingLeft: "12px" }}>
                            {this.getEtablissementsConcernes()}
                            <br key={"br5"} />
                        </div>
                    </RowComponent>
                </fieldset>
                <VerticalSpacing key={"separator-2"} height="40px" />
                <fieldset key={"fs-effectifs-et-salaires"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                    {this.getEffectifsEtSalaires()}
                    <br key={"br6"} />
                </fieldset>
                <VerticalSpacing key={"separator-3"} height="40px" />
                <fieldset key={"fs-thematique"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                    {this.getThematique()}
                </fieldset>
                <VerticalSpacing key={"separator-4"} height="80px" />
                {this.getTitleCouts()}
                <br key={"br7"} />
                <br key={"br8"} />
                {this.getCouts(styleFieldset)}
                <br key="br7-1" />
                <br key={"br8-1"} />
                {this.getExplications(styleFieldset)}
                <VerticalSpacing key={"separator-7"} height="80px" />
                {this.getTitlePiecesJointes()}
                <br key={"br9"} />
                <br key={"br10"} />
                <div key={"pj-wrapper"}>{this.getPiecesJointes(styleFieldset)}</div>
                <br key={"br11"} />
                {this.getAutresAvis()}
                <br key={"br12"} />
            </Fragment>
        )
    }

    getCouts(styleFieldset: object) {
        if (this.props.fullAvis.demande?.sansImpactFinancier) {
            return <span key={"sans-impact"}>Sans impact financier</span>
        } else {
            return (
                <Fragment key={"couts"}>
                    <fieldset key={"fs-annee-n"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N</legend>
                        {this.getAnneeN(0)}
                    </fieldset>
                    <VerticalSpacing key={"separator-5"} height="40px" />
                    <fieldset key={"fs-annee-n-1"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N+1</legend>
                        {this.getAnneeN(1)}
                    </fieldset>
                    {this.getAnneeOptionnelle(2, styleFieldset)}
                    {this.getAnneeOptionnelle(3, styleFieldset)}
                </Fragment>
            )
        }
    }

    getAnneeN(annee: number): ReactElement {
        const ligneFinancement: ReactElement[] = []
        ligneFinancement.push(
            <RowComponent key={"row-get-" + annee + "-header"}>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Autorité de tarification
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par des ressources propres
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par les dotations actuelles
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Financement de la mesure par des crédits nouveaux
                </ColumnComponent>
            </RowComponent>
        )
        ligneFinancement.push(<VerticalSpacing height="20px" key={"row-get-" + annee + "-separator1"} />)
        ligneFinancement.push(<hr key={"row-get-" + annee + "-holi1"} />)
        const financeurs = new Map<number, OrganismeFinanceur>()
        this.props.fullAvis.demande?.etablissements?.forEach((etablissement) => {
            etablissement.financeurs?.forEach((financeur) => financeurs.set(financeur.id!, financeur))
        })
        let totalAnneeN = 0
        // Financeurs
        this.props.fullAvis.demande?.financement?.forEach((financement, index) => {
            if (financement.id != null && financement.a == annee) {
                const nom: string | null | undefined = financeurs.get(financement.id)?.nom
                ligneFinancement.push(this.getCleanSomme(index, nom!, financement))
                totalAnneeN += (financement.ds ?? 0) + (financement.rp ?? 0) + (financement.mn ?? 0)
            }
        })
        ligneFinancement.push(<hr key={"separator-tableau-0"} style={{ marginTop: "20px", marginRight: "12px" }} />)
        // CAF
        this.props.fullAvis.demande?.financement?.forEach((financement) => {
            if (financement.picf != null && financement.a == annee) {
                ligneFinancement.push(
                    <RowComponent key={"row-get-" + annee + "-caf"}>
                        <ColumnComponent size={2}>CAF</ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {this.currencyFormatter?.format(financement.picf ?? 0)}
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                )
                totalAnneeN += financement.picf ?? 0
            }
        })
        // CNAV
        this.props.fullAvis.demande?.financement?.forEach((financement) => {
            if (financement.picf != null && financement.a == annee) {
                ligneFinancement.push(
                    <RowComponent key={"row-get-" + annee + "-cnav"}>
                        <ColumnComponent size={2}>CNAV</ColumnComponent>
                        <ColumnComponent size={2}>
                            <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>
                                {this.currencyFormatter?.format(financement.picv ?? 0)}
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                )
                totalAnneeN += financement.picv ?? 0
            }
        })
        ligneFinancement.push(<hr key={"separator-tableau-1"} style={{ marginTop: "20px", marginRight: "12px" }} />)
        return (
            <Fragment key={"financement-annee-" + annee}>
                {ligneFinancement}
                <RowComponent key={"row-total-" + annee}>
                    <ColumnComponent size={2}>Coût total année </ColumnComponent>
                    <ColumnComponent size={2}>
                        <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{this.currencyFormatter?.format(totalAnneeN)}</div>
                    </ColumnComponent>
                </RowComponent>
            </Fragment>
        )
    }

    getCleanSomme(index: number, nom: string, cout: Cout): ReactElement {
        const a = this.currencyFormatter?.format(cout.rp ?? 0)
        const b = this.currencyFormatter?.format(cout.ds ?? 0)
        const c = this.currencyFormatter?.format(cout.mn ?? 0)
        return (
            <RowComponent key={"row-get-" + index + "-cs"}>
                <ColumnComponent size={3}>{nom}</ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{a}</div>
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{b}</div>
                </ColumnComponent>
                <ColumnComponent size={3} style={{ textAlign: "center", display: "flex", alignContent: "center" }}>
                    <div style={{ width: "120px", border: "1px dotted black", margin: "auto", paddingRight: "10px", textAlign: "right" }}>{c}</div>
                </ColumnComponent>
            </RowComponent>
        )
    }

    getExplications(styleFieldset: object) {
        if (this.props.fullAvis.demande?.explication && this.props.fullAvis.demande?.explication !== "") {
            return (
                <Fragment key={"explication-set"}>
                    <fieldset key={"fs-explication-set"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Explications complémentaires</legend>
                        {this.props.fullAvis.demande.explication}
                    </fieldset>
                </Fragment>
            )
        } else {
            return (
                <Fragment key={"explication-empty"}>
                    <fieldset key={"fs-explication-empty"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Explications complémentaires</legend>
                        Non renseigné
                    </fieldset>
                </Fragment>
            )
        }
    }

    getAccordRender() {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        return (
            <Fragment key="accord-details">
                <br />
                {this.getTitleData()}
                <br />
                <br />
                <fieldset key={"fs-general"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>&nbsp;Informations administratives&nbsp;</legend>
                    {this.getNumeroDossier()}
                    <br />
                    {this.getAccordDateSignature()}
                    <br />
                    {this.getAccordDateDepot()}
                    <br />
                    {this.getConventionCollective()}
                    <br />
                    <br />
                    Courriel de contact : {this.props.fullAvis.demande?.emailContact}
                    <br key={"br4-1"} />
                </fieldset>
                <VerticalSpacing key={"separator-1"} height="40px" />
                <fieldset key={"fs-etablissements"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                    <div style={{ paddingLeft: "12px" }}>
                        {this.getEtablissementsConcernes()}
                        <br />
                    </div>
                </fieldset>
                <VerticalSpacing key={"separator-2"} height="40px" />
                <fieldset key={"fs-effectifs-et-salaires"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                    {this.getEffectifsEtSalaires()}
                </fieldset>
                <VerticalSpacing key={"separator-3"} height="40px" />
                <fieldset key={"fs-thematique"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                    {this.getThematique()}
                </fieldset>
                <VerticalSpacing key={"separator-4"} height="80px" />
                {this.getTitleCouts()}
                <br key="br7" />
                <br key="br8" />
                {this.getCouts(styleFieldset)}
                <br key="br7-1" />
                <br key={"br8-1"} />
                {this.getExplications(styleFieldset)}
                <br key="br7-2" />
                <br key={"br8-3"} />
                <VerticalSpacing key={"separator-6"} height="40px" />
                {this.getTitlePiecesJointes()}
                <br key="br9" />
                <br key="br10" />
                {this.getPiecesJointes(styleFieldset)}
                <br key="br11" />
                {this.getAutresAvis()}
                <br key={"br12"} />
            </Fragment>
        )
    }

    getTitle(): string {
        if (this.props.fullAvis.demande?.typeDemande === "DUE") {
            return "Demande d'agrément sur DUE"
        } else {
            return "Demande d'agrément sur l'accord " + this.props.fullAvis.demande?.numeroDossier
        }
    }

    getAutresAvis() {
        if (this.props.fullAvis.autresAvis && this.props.fullAvis.autresAvis.length > 0) {
            const avis: ReactElement[] = []
            this.props.fullAvis.autresAvis.forEach((showAvis) => {
                avis.push(this.getShowAvis(showAvis)!)
                avis.push(<br key={"sep-avis"} />)
            })
            if (avis.length > 0) {
                return (
                    <Fragment key="fragment-aa">
                        <br key={"init-autre"} />
                        {this.getTitleAutreAvis()}
                        <br key={"inter1-autre"} />
                        <br key="inter2-autre" />
                        <div key={"div-avis"}>{avis}</div>
                        <br key={"final-autre"} />
                    </Fragment>
                )
            }
        }
    }

    getShowAvis(showAvis: ShowAvisFinanceur) {
        if (showAvis.result == undefined) {
            return (
                <FieldsetComponent
                    legend={showAvis.organismeFinanceur!}
                    key={"fsc-show-avis-financeur"}
                    content={
                        <Fragment>
                            <BadgeClassicGreyComponent label="Pas de données" />
                            <br />
                        </Fragment>
                    }
                />
            )
        } else if (showAvis.result) {
            return (
                <FieldsetComponent
                    legend={showAvis.organismeFinanceur!}
                    key={"fsc-show-avis-financeur"}
                    content={
                        <Fragment>
                            <div style={{ display: "block" }}>
                                <BadgeSuccessComponent label="Financement accepté" />
                                <br key={"jump1"} />
                                <br key={"jump2"} />
                                <div>{showAvis.commentaire}</div>
                            </div>
                        </Fragment>
                    }
                />
            )
        } else if (!showAvis.result) {
            return (
                <FieldsetComponent
                    legend={showAvis.organismeFinanceur!}
                    key={"fsc-show-avis-financeur"}
                    content={
                        <Fragment>
                            <div style={{ display: "block" }}>
                                <BadgeWarningComponent label="Financement refusé" />
                                <br key={"jump1"} />
                                <br key={"jump2"} />
                                <div>{showAvis.commentaire}</div>
                            </div>
                        </Fragment>
                    }
                />
            )
        }
    }

    getEditAvisLine() {
        if (this.props.clickAccept && this.props.clickRefuse) {
            return (
                <RowComponent key={"row-component-avis-financeur-2"}>
                    <ColumnComponent key={"column-left"} size={1} />
                    <ColumnComponent key={"column-content"} size={10}>
                        <BadgeOrangeClayComponent key={"votre-avis-badge"} label={"Votre avis"} />
                        <VerticalSpacing key={"separator-info-complement"} height="25px" />
                        <FieldsetComponent legend={"Commentaire"} content={<Fragment>{this.getTextArea()}</Fragment>} />
                        <VerticalSpacing height="25px" />
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={3}>
                                <Button iconLeft={Constant.ICON.ADD_CIRCLE_FILL} onClick={() => this.props.clickAccept!()}>
                                    Accepter
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={3}>
                                <Button secondary iconLeft={Constant.ICON.ERROR_FILL} onClick={() => this.props.clickRefuse!()} style={{ marginLeft: "5px" }}>
                                    Refuser
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                    </ColumnComponent>
                </RowComponent>
            )
        } else {
            return (
                <RowComponent key={"row-component-avis-financeur-3"}>
                    <ColumnComponent key={"column-left"} size={1} />
                    <ColumnComponent key={"column-content"} size={10}>
                        <BadgeOrangeClayComponent key={"votre-avis-badge"} label={"Votre avis"} />
                        <VerticalSpacing key={"separator-info-complement"} height="25px" />
                        <FieldsetComponent legend={"Commentaire"} content={<Fragment>{this.props.fullAvis.avis?.commentaire}</Fragment>} />
                        <VerticalSpacing height="25px" />
                    </ColumnComponent>
                    <ColumnComponent key={"column-right"} size={1} />
                </RowComponent>
            )
        }
    }

    render() {
        if (this.state) {
            return (
                <Fragment>
                    <RowComponent key={"row-component-avis-financeur-1"}>
                        <ColumnComponent key={"column-left"} size={1} />
                        <ColumnComponent key={"column-content"} size={10}>
                            {this.props.fullAvis.demande?.typeDemande === "ACCORD" ? this.getAccordRender() : this.getDueRender()}
                        </ColumnComponent>
                        <ColumnComponent key={"column-right"} size={1} />
                    </RowComponent>
                    {this.getEditAvisLine()}
                </Fragment>
            )
        } else {
            return <>{null}</>
        }
    }
}
