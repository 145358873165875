import File from "../../model/File"
import FileType from "../../model/FileType"

import { Constant } from "../../constant/Constant"
import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpFrontFileService extends HttpService {
    FILE_URI = "/front/file"

    public sendFile(id: number, inputFile: HTMLInputElement | undefined, type: FileType, fieldName: string): Promise<Response<File>> | undefined {
        if (inputFile != null && inputFile.files && inputFile.files.length > 0 && inputFile.files[0].size < Constant.CONFIGURATION.MAX_FILE_SIZE) {
            const formData = new FormData()
            formData.append("file", inputFile.files[0])
            const url = this.FILE_URI + "/upload/" + type + "?id=" + id + "&field=" + fieldName
            return this.request<Response<File>>(url, {
                method: "POST",
                body: formData
            }).then((response) => {
                if (response.success || response.errorMessage !== "Virus Detected") {
                    return new Response(response)
                } else {
                    return new Response({
                        ...response,
                        errors: [
                            {
                                errorKey: type === FileType.ACCORD_DUE ? "file-accord-due" : fieldName,
                                errorMessage: "Votre fichier est corrompu"
                            }
                        ]
                    })
                }
            })
        }
    }

    public getFile(type: string, parentId: number) {
        return this.request<Response<File>>(this.FILE_URI + "/by_parent/" + type + "?id=" + parentId, {
            method: "GET"
        })
    }

    public getDemandesFiles(parentId: number) {
        return this.request<Response<File[]>>(this.FILE_URI + "/by_parent/demande/" + parentId, {
            method: "GET"
        })
    }

    public deleteFile(key: string) {
        return this.request<Response<boolean>>(this.FILE_URI + "/delete/" + key, {
            method: "DELETE"
        })
    }
}
