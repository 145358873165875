import React from "react"

import DnumComponent, { DnumProps } from "../../DnumComponent"

interface RowProps extends DnumProps {
    gutters?: null
    right?: null
    left?: null
    center?: any
    top?: null
    bottom?: null
    middle?: any
}

export default class RowComponent extends DnumComponent<RowProps, any> {
    private className = "fr-grid-row"

    constructor(props: RowProps) {
        super(props)

        this.className = "fr-grid-row"
        if (this.props.gutters) {
            this.className += " fr-grid-row--gutters"
        }

        if (this.props.left) {
            this.className += " fr-grid-row--left"
        } else if (this.props.right) {
            this.className += " fr-grid-row--right"
        } else if (this.props.center) {
            this.className += " fr-grid-row--center"
        }

        if (this.props.top) {
            this.className += " fr-grid-row--top"
        } else if (this.props.bottom) {
            this.className += " fr-grid-row--bottom"
        } else if (this.props.middle) {
            this.className += " fr-grid-row--middle"
        }
    }

    render() {
        if (this.props.style) {
            return (
                <div className={this.className} style={this.props.style}>
                    {this.props.children}
                </div>
            )
        } else {
            return <div className={this.className}>{this.props.children}</div>
        }
    }
}
