import PersistentObject from "./PersistentObject"
import TypeFinanceur from "./TypeFinanceur"

export default class OrganismeFinanceur implements PersistentObject {
    id: number | null = null
    nom: string | null = null
    type: TypeFinanceur = new TypeFinanceur(null)
    system: boolean | null = null

    constructor(id: number | null | any) {
        this.id = id
    }
}
