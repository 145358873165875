import { ReactElement } from "react"

import ContainerComponent from "../../../../generic/component/grid/container/ContainerComponent"

import AccoladeComponent from "../../../../accolade/AccoladeComponent"
import {DnumProps} from "../../../../generic/component/DnumComponent";

interface StepperBoxComponentProps extends DnumProps {
    data: ReactElement[]
    step: number
}

export default class StepperBoxComponent extends AccoladeComponent<StepperBoxComponentProps, any> {
    getData() {
        const currentStep: number = this.props && this.props.step ? this.props.step : 0
        const dataView: ReactElement[] = []
        this.props.data.forEach((item, index) => {
            if (index > 0) {
                dataView.push(
                    <div key={"step-space-" + index} style={{ paddingLeft: "15px", paddingRight: "15px", display: "inline-block", verticalAlign: "baseline" }}>
                        <span style={{ fontSize: "xx-large", color: this.getColor(currentStep, index) }}>&gt;</span>
                    </div>
                )
            }
            dataView.push(this.getStep(index, item, this.getColor(currentStep, index)))
        })
        return dataView
    }

    getColor(currentStep: number, index: number) {
        if (currentStep > index) {
            return "#1f8d49"
        } else if (currentStep == index) {
            return "#0078f3"
        } else {
            return "#171d2e"
        }
    }

    getStep(index: number, text: ReactElement, color: string) {
        return (
            <div key={"step-" + index} style={{ display: "inline-block" }}>
                <div
                    style={{
                        backgroundColor: color,
                        color: "white",
                        display: "inline-block",
                        padding: "15px",
                        borderWidth: "3px",
                        borderStyle: "solid",
                        borderColor: color,
                        borderTopLeftRadius: "10px"
                    }}>
                    {index + 1}
                </div>
                <div
                    style={{
                        backgroundColor: "white",
                        color: color,
                        display: "inline-block",
                        padding: "15px",
                        borderWidth: "3px",
                        borderStyle: "solid",
                        borderColor: color,
                        borderBottomRightRadius: "10px"
                    }}>
                    {text}
                </div>
            </div>
        )
    }

    render() {
        const mainContainer = {
            marginTop: "5px",
            marginBottom: "35px"
        }

        return (
            <ContainerComponent style={{ textAlign: "center", ...mainContainer }}>
                <div style={{ width: "100%" }}>{this.getData()}</div>
            </ContainerComponent>
        )
    }
}
