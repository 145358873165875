import { Fragment, ReactElement } from "react"

import { decode } from "html-entities"

import Contact from "../../../../model/Contact"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import Button from "../../../../../generic/component/button/Button"
import TextInputComponent from "../../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import HttpAccountService from "../../../../service/http/HttpAccountService"
import ErrorResponse from "../../../../service/http/response/ErrorResponse"
import Response from "../../../../service/http/response/Response"
import FrontComponent from "../../../FrontComponent"

interface FrontAccountContactProps extends DnumProps {
    contact: Contact
}

interface FrontAccountContacStatus {
    mode: "view" | "edit"
    contact: Contact
    errors: ErrorResponse[] | null
    saved: boolean
}

export default class FrontAccountContactComponent extends FrontComponent<FrontAccountContactProps, FrontAccountContacStatus> {
    constructor(props: FrontAccountContactProps) {
        super(props)
        this.state = {
            mode: "view",
            contact: this.props.contact,
            errors: null,
            saved: false
        }
    }

    componentDidUpdate(prevProps: Readonly<FrontAccountContactProps>, prevState: Readonly<FrontAccountContacStatus>, snapshot?: any) {
        if (this.state.contact == null) {
            this.setState({ contact: this.props.contact })
        }
    }

    clickEdit() {
        this.setState({ mode: "edit" })
    }

    clickSave() {
        const prenom = document.querySelector("#edit-contact-prenom") as HTMLInputElement
        const nom = document.querySelector("#edit-contact-nom") as HTMLInputElement
        const telephone = document.querySelector("#edit-contact-telephone") as HTMLInputElement
        const email = document.querySelector("#edit-contact-email") as HTMLInputElement
        const response = new HttpAccountService().updateContact(nom.value, prenom.value, email.value, telephone.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errors == null || cast.errors.length == 0) {
                const contact = new Contact()
                contact.nom = nom.value
                contact.prenom = prenom.value
                contact.email = email.value
                contact.telephone = telephone.value

                this.setState({
                    contact: contact,
                    saved: true,
                    mode: "view"
                })
            } else {
                this.setState({ errors: cast.errors })
            }
        })
    }

    render() {
        if (this.state && this.state.mode == "view") {
            // TODO
            return (
                <Fragment key={"account-view-contact"}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>
                            Référent : {decode(this.state.contact?.prenom) + " " + decode(this.state.contact?.nom)}
                            <br />
                            <br />
                            Courriel : {this.state.contact?.email}
                            <br />
                            <br />
                            Téléphone : {this.state.contact?.telephone}
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.clickEdit()} style={{ width: "130px" }}>
                                Editer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        } else {
            return (
                <Fragment key={"account-edit-contact"}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>
                            <TextInputComponent label={"Prénom"} id={"edit-contact-prenom"} value={this.state?.contact?.prenom} />
                            <TextInputComponent label={"Nom"} id={"edit-contact-nom"} value={this.state?.contact?.nom} />
                            <TextInputComponent label={"Courriel"} id={"edit-contact-email"} value={this.state?.contact?.email} />
                            <TextInputComponent label={"Téléphone"} id={"edit-contact-telephone"} value={this.state?.contact?.telephone} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.clickSave()} style={{ width: "130px" }}>
                                Enregistrer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        }
    }
}
