import React, { Fragment, ReactElement } from "react"

import FinancementJuridique from "../../../../model/FinancementJuridique"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import Button from "../../../../../generic/component/button/Button"
import TextInputComponent from "../../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import Highlight from "../../../../../generic/component/highlight/Highlight"

import HttpAccountService from "../../../../service/http/HttpAccountService"
import ErrorResponse from "../../../../service/http/response/ErrorResponse"
import Response from "../../../../service/http/response/Response"
import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import ErrorService from "../../../../shared/service/ErrorService"
import FrontComponent from "../../../FrontComponent"

interface FrontShowAccountFinancementJuridiqueProps extends DnumProps {
    financement: FinancementJuridique
}

interface FrontAccountFinancementComponentState {
    financement: FinancementJuridique
    financeErrors?: ErrorResponse[] | null
    saved: boolean
    mode: "view" | "edit"
}

export default class FrontAccountFinancementComponent extends FrontComponent<FrontShowAccountFinancementJuridiqueProps, FrontAccountFinancementComponentState> {
    static infos: ReactElement = (<span>Ces informations seront utiles pendant l'instruction de votre demande d'agrément.</span>)

    constructor(props: FrontShowAccountFinancementJuridiqueProps) {
        super(props)
    }

    componentDidUpdate(prevProps: Readonly<FrontShowAccountFinancementJuridiqueProps>, prevState: Readonly<FrontAccountFinancementComponentState>, snapshot?: any) {
        if (this.state == null || this.state.financement == null) {
            this.setState({ financement: this.props.financement })
        }
    }

    updateFinancement() {
        const etp = document.querySelector("#etp") as HTMLInputElement
        const masseSalarialeBrute = document.querySelector("#masse-salariale-brute") as HTMLInputElement
        const masseSalarialeChargee = document.querySelector("#masse-salariale-chargee") as HTMLInputElement

        const response = new HttpAccountService().saveFinance(etp.value, masseSalarialeBrute.value, masseSalarialeChargee.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errors == null || cast.errors.length == 0) {
                const financement = new FinancementJuridique()
                financement.etp = Number.parseFloat(etp.value)
                financement.masseSalarialeBrute = Number.parseFloat(masseSalarialeBrute.value)
                financement.masseSalarialeChargee = Number.parseFloat(masseSalarialeChargee.value)

                if (financement.etp != 9999 && financement.masseSalarialeBrute != 999999 && financement.masseSalarialeChargee != 999999) {
                    const element = document.querySelector("#manage-financement-undefined") as HTMLElement
                    if (element != null) {
                        element.style.display = "none"
                    }
                }

                this.setState({
                    financement: financement,
                    saved: true,
                    mode: "view"
                })
            } else {
                this.setState({ financeErrors: cast.errors })
            }
        })
    }

    clickEdit() {
        this.setState({
            mode: "edit",
            saved: false
        })
    }

    clickCancel() {
        this.setState({
            mode: "view",
            saved: false
        })
    }

    render() {
        if (this.state?.mode === "edit") {
            const errorService = new ErrorService()
            return (
                <Fragment key={"fragment-ej-finance"}>
                    <RowComponent key={"row-edit-finance-1"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <Highlight html={FrontAccountFinancementComponent.infos} />
                            <br />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <hr />
                    <br />
                    <RowComponent key={"row-edit-finance-2"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>
                            <TextInputComponent
                                label={"ETP relevant des ESSMS portés (tous ETP)"}
                                id={"etp"}
                                value={this.state?.financement.etp?.toString() ?? ""}
                                number
                                error={errorService.isErrorKeyPresent("etp", this.state?.financeErrors)}
                                errorMessage={errorService.getErrorMessage("etp", this.state?.financeErrors)}
                            />
                            <TextInputComponent
                                label={"Masse salariale brute (en milliers d'euros)"}
                                id={"masse-salariale-brute"}
                                value={this.state?.financement.masseSalarialeBrute?.toString()}
                                number
                                error={errorService.isErrorKeyPresent("masse-salariale-brute", this.state?.financeErrors)}
                                errorMessage={errorService.getErrorMessage("masse-salariale-brute", this.state?.financeErrors)}
                            />
                            <TextInputComponent
                                label={"Masse salariale chargée (en milliers d'euros)"}
                                id={"masse-salariale-chargee"}
                                value={this.state?.financement.masseSalarialeChargee?.toString()}
                                number
                                error={errorService.isErrorKeyPresent("masse-salariale-chargee", this.state?.financeErrors)}
                                errorMessage={errorService.getErrorMessage("masse-salariale-chargee", this.state?.financeErrors)}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button key="click-update-financement" style={{ width: "140px" }} onClick={() => this.updateFinancement()}>
                                Enregistrer
                            </Button>
                            <br />
                            <Button key="click-cancel-financement" style={{ width: "140px" }} onClick={() => this.clickCancel()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height={"25px"} />
                </Fragment>
            )
        } else {
            return (
                <Fragment key={"fragment-ej-finess"}>
                    <RowComponent key={"row-view-finance-1"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <Highlight html={FrontAccountFinancementComponent.infos} />
                            <br />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <hr />
                    <br />
                    <RowComponent key={"row-view-finance-2"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>
                            <div>ETP relevant des ESSMS portés (tous ETP) : {this.state?.financement?.etp?.toString() ?? ""}</div>
                            <br />
                            <div>Masse salariale brute (en milliers d'euros) : {this.state?.financement?.masseSalarialeBrute?.toString()}</div>
                            <br />
                            <div>Masse salariale chargée (en milliers d'euros) : {this.state?.financement?.masseSalarialeChargee?.toString()}</div>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button key="click-edit-financement" style={{ width: "140px" }} onClick={() => this.clickEdit()}>
                                Editer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <br />
                </Fragment>
            )
        }
    }
}
