import styled from "styled-components"

import DnumComponent, { DnumProps } from "../../../../generic/component/DnumComponent"

interface HorizontalProps extends DnumProps {
    width: string
}
export default class HorizontalSpacing extends DnumComponent<HorizontalProps, any> {
    constructor(props: HorizontalProps) {
        super(props)
    }

    render() {
        const StyledDiv = styled.div`
            width: ${this.props.width};
            display: inline;
        `

        return <StyledDiv>&nbsp;</StyledDiv>
    }
}
