import React from "react"

import File from "../../../../model/File"
import FileType from "../../../../model/FileType"

import AlertWarningComponent from "../../../../../generic/component/alert/AlertWarningComponent"
import CheckboxComponent from "../../../../../generic/component/checkbox/CheckboxComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"
import FrontDemandeAgrementFormStep3AComponent from "./FrontDemandeAgrementFormStep3AComponent"

interface FrontDemandeAgrementFormStep3ComponentProps extends FrontDemandeAgrementFormStepProps {
    fileAccordDue: File | undefined
    pieceComplementaire1: File | undefined
    pieceComplementaire2: File | undefined
    pieceComplementaire3: File | undefined
    pieceComplementaire4: File | undefined
    pieceComplementaire5: File | undefined
    manageValidationCheckbox: () => void
}

export default class FrontDemandeAgrementFormStep3Component extends FrontComponent<FrontDemandeAgrementFormStep3ComponentProps, any> {
    constructor(props: FrontDemandeAgrementFormStep3ComponentProps) {
        super(props)
    }

    getPiecesComplementaires(): File[] {
        const pieceComplementaires: File[] = []
        if (this.props.pieceComplementaire1 != undefined) {
            pieceComplementaires.push(this.props.pieceComplementaire1)
        }
        if (this.props.pieceComplementaire2 != undefined) {
            pieceComplementaires.push(this.props.pieceComplementaire2)
        }
        if (this.props.pieceComplementaire3 != undefined) {
            pieceComplementaires.push(this.props.pieceComplementaire3)
        }
        if (this.props.pieceComplementaire4 != undefined) {
            pieceComplementaires.push(this.props.pieceComplementaire4)
        }
        if (this.props.pieceComplementaire5 != undefined) {
            pieceComplementaires.push(this.props.pieceComplementaire5)
        }
        return pieceComplementaires
    }

    containsErreurBloquante() {
        const errorCount = this.props.errors?.size ?? 0
        let errorPJ = 0
        if (this.props.errors?.has(FileType.PIECE_COMPLEMENTAIRE_1 + "-error")) {
            errorPJ++
        }
        if (this.props.errors?.has(FileType.PIECE_COMPLEMENTAIRE_2 + "-error")) {
            errorPJ++
        }
        if (this.props.errors?.has(FileType.PIECE_COMPLEMENTAIRE_3 + "-error")) {
            errorPJ++
        }
        if (this.props.errors?.has(FileType.PIECE_COMPLEMENTAIRE_4 + "-error")) {
            errorPJ++
        }
        if (this.props.errors?.has(FileType.PIECE_COMPLEMENTAIRE_5 + "-error")) {
            errorPJ++
        }
        return errorCount > errorPJ
    }

    render() {
        if (this.props.errors && this.props.errors?.size > 0 && this.containsErreurBloquante()) {
            return (
                <>
                    <VerticalSpacing height="20px" />
                    <AlertWarningComponent title={"Demande incomplète"} description={"Votre demande comporte des champs non renseignés"} />
                    <VerticalSpacing height="20px" />
                </>
            )
        } else {
            return (
                <>
                    <VerticalSpacing height="20px" />
                    <RowComponent key={"row-form-step3A"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <FrontDemandeAgrementFormStep3AComponent
                                demandeInProgress={this.props.demandeInProgress}
                                informations={this.props.informations}
                                fileAccordDue={this.props.fileAccordDue}
                                otherFiles={this.getPiecesComplementaires()}
                            />
                            <VerticalSpacing height="40px" />
                            <span>Une fois transmise je ne pourrai plus modifier ma demande d'agrément.</span>
                            <br />
                            <br />
                            <CheckboxComponent label={"Je valide ces informations."} id="valid-informations" onChange={() => this.props.manageValidationCheckbox()} checked={false} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height="20px" />
                </>
            )
        }
    }
}
