import IPageCoordinator from "./contract/IPageCoordinator"

export default class FrontService {
    private static instance: FrontService

    public switchPage: any
    public isUserConnected: any

    public static getInstance(): FrontService {
        if (!FrontService.instance) {
            FrontService.instance = new FrontService()
        }
        return FrontService.instance
    }

    public setPageCoordinator(page: IPageCoordinator) {
        this.switchPage = page.getPageSwitcher()
        this.isUserConnected = page.isUserConnected()
    }
}
