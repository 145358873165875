import { ReactElement } from "react"

export default class HorizontalMenuItemData {
    public label: string | null = null
    public id: string | null = null
    public items: HorizontalMenuItemData[] | null = null

    constructor(label: string, id: string, items: HorizontalMenuItemData[] | null) {
        this.label = label
        this.id = id
        this.items = items
    }
}
